import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { constants as c, hooks } from '@lifesize/clients.sdk';
import { CallLayout, CompositedLayout } from '@lifesize/clients.sdk/dist/components';
import { featureFlagVbb } from 'constants/constants';
import { Box, makeStyles } from '@material-ui/core';
import AudioVideoSettings from 'components/AudioVideoSettings/AudioVideoSettings';
import ButtonBar from 'components/CallControls/ButtonBar';
import ButtonBarRetry from 'components/CallControls/ButtonBarRetry';
import CallStatus from 'components/CallControls/CallStatus';
import InCallSnackbars from 'components/Snackbars/InCallSnackbars';
import CatalinaModal from 'components/Modals/Catalina';
import ScreenShareBlockedModal from 'components/Modals/ScreenShareBlocked';
import PinCodeModal from 'components/PinCode/PinCodeModal';
import VbbSettings from 'components/VbbSettings/VbbSettings';
import usePrevious from 'hooks/usePrevious';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useViewport from 'hooks/useViewport';
import useVideoDeviceChanged from 'hooks/useVideoDeviceChanged';
import { RootState } from 'redux/rootReducer';
import { setAudioOutput } from 'utils/devicesUtils';
import { navigateToTrialPage } from 'utils/redirectUtils';
import { callReasons } from 'constants/constants';
import FeatureFlags from 'components/FeatureFlags/FeatureFlags';
import { isMobile, isChromeLikeBrowser, isWindows8 } from 'utils/browserDetection';
import { vbbManager } from 'utils/vbbManager';

export default function InCallLayout(): React.ComponentElement<any, any> {
  const call = hooks.useCallState();
  const callState = call.state;
  const callReason = call.reason;
  const callConnected = callState === c.CALL_STATE_CONNECTED;
  const classes = useStyles();
  const inCallContainer = useRef(null);
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);
  const previousCallState = usePrevious(call.state);
  const selfView = useSelector((state: RootState) => state.selfView);
  const hasVbbFeature = useFeatureFlag(featureFlagVbb);
  const videoDeviceChanged = useVideoDeviceChanged();
  const callFullyConnected = hooks.useConferenceId();
  const showVbbOption = hasVbbFeature && callFullyConnected && isChromeLikeBrowser() && !isWindows8() && !isMobile();
  const isSfuMultiStream = false; //!!hooks.useAccountSettings()?.sfuSettings?.sfuMultiStream && getReceiveStreams() > 1;
  const LayoutComponent = isSfuMultiStream && !isMobile() ? CallLayout : CompositedLayout;
  const dispatch = useDispatch();
  const { localVideoMuted } = hooks.useMedia();
  const { enabled: vbbEnabled, selection: bgSelection } = useSelector((state: RootState) => state.vbbSettings);
  const { isFooterCompact, isMobileView } = useViewport();
  const showMobileView = isMobileView || isMobile(); // for parity with when we hide the layout switch in the footer
  const showPipInLayout = selfView.on && isFooterCompact;

  // navigate to trial page after successful call and the other end hangs up
  useEffect(() => {
    if (
      previousCallState === c.CALL_STATE_CONNECTED &&
      callState === c.CALL_STATE_DISCONNECTED &&
      callReasons.successful.includes(callReason)
    ) {
      navigateToTrialPage();
    }
  }, [call, callState, previousCallState, callReason]);

  const handleNewCameraVBB = useCallback(() => {
    vbbManager.handleCameraChange(dispatch, localVideoMuted, vbbEnabled, bgSelection, mediaSettings);
  }, [dispatch, localVideoMuted, vbbEnabled, bgSelection, mediaSettings]);

  useEffect(() => {
    if (videoDeviceChanged) {
      handleNewCameraVBB();
    }
  }, [videoDeviceChanged, handleNewCameraVBB]);

  useEffect(() => {
    setAudioOutput(mediaSettings.speakers);
  }, [mediaSettings.speakers]);

  return (
    <Box className={classes.container}>
      {!callConnected && <CallStatus />}
      <div className={classes.videoContainer} ref={inCallContainer}>
        {callState === c.CALL_STATE_DISCONNECTED ? (
          <ButtonBarRetry />
        ) : (
          <>
            <LayoutComponent
              intl={{ popoutLabel: intl.get('popoutButton') }}
              isMobileView={showMobileView}
              muteRemotes={mediaSettings.audio === 'phone'}
              showMetadata={isSfuMultiStream}
              showPip={showPipInLayout}
            />
            <ButtonBar />
          </>
        )}
        <FeatureFlags />
        <PinCodeModal />
        <AudioVideoSettings inCall={true} />
        {showVbbOption ? <VbbSettings inCall={true} /> : null}
        <CatalinaModal />
        <ScreenShareBlockedModal />
      </div>
      <InCallSnackbars />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: 'calc(10px + 2vmin)',
    height: 'calc(var(--vh, 1vh) * 100)',
    justifyContent: 'center',
    overflow: 'hidden',
    textAlign: 'center'
  },
  videoContainer: {
    backgroundColor: 'black',
    top: 0,
    bottom: 0,
    height: 'calc(var(--vh, 1vh) * 100)',
    width: '100vw',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    position: 'absolute'
  }
}));
