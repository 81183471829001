import { useEffect, useState } from 'react';
import { hooks } from '@lifesize/clients.sdk';
import { getParticipantObjectByUserUUID } from 'utils/audioButtonUtils';

export default function useCurrentUserMuteState(): boolean {
  const [serverMuteState, setServerMuteState] = useState(false);
  const currentUser = hooks.useUserInfo();
  const participants = hooks.useParticipants();
  const currentParticipant = getParticipantObjectByUserUUID(participants, currentUser.uuid);

  useEffect(() => {
    setServerMuteState(currentParticipant?.serverMuteState || false);
  }, [currentParticipant]);

  return serverMuteState;
}
