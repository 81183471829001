import { createSlice } from '@reduxjs/toolkit';

interface CallState {
  attempted: boolean;
}

const initialState: CallState = {
  attempted: false
};

const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setAttempted(state, action) {
      state.attempted = action.payload;
    }
  }
});

export const { setAttempted } = callSlice.actions;
export default callSlice.reducer;
