import { Logger, BugReporter, BugReporterEnvironment } from '@lifesize/web-clients-core';
import analytics from 'utils/analytics';

const version = process.env.REACT_APP_VERSION || '0.0.1';
const stage = getStage();
let bugReporter: BugReporter;

function getStage(): BugReporterEnvironment {
  if (process.env.NODE_ENV === 'development') {
    return 'development';
  }
  return process.env.REACT_APP_STAGE as BugReporterEnvironment;
}

async function init() {
  analytics.init();
  Logger.listenForKeyboardShortcut();
  bugReporter = new BugReporter('a8aefa17609dc87d961de2cab052d532', version, stage);
  if (Logger.isEnabled()) {
    await Logger.initialize('yw0xj3/new-guest-flow', version, true);
  }
}

function getReactErrorBoundary(react: any) {
  if (!bugReporter) {
    throw new Error('Module has not been initialized. Run init() first');
  }
  return bugReporter.getReactErrorBoundary(react);
}

function identify(name?: string, email?: string) {
  const id = analytics.identify(name, email);
  Logger.identify(id, { name: name || '', email: email || '' });
}

export default { init, identify, version, getReactErrorBoundary, stage };
