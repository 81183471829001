import React, { useCallback, FunctionComponent, SVGProps } from 'react';
import intl from 'react-intl-universal';
import clsx from 'clsx';
import { constants as c, signaling } from '@lifesize/clients.sdk';
import { colors } from '@lifesize/ux-assets';
import { makeStyles, Tooltip } from '@material-ui/core';
import { ReactComponent as PanelViewIcon } from '@lifesize/ux-assets/vectors/svgIcons/view-active-speaker.svg';
import { ReactComponent as GalleryViewIcon } from '@lifesize/ux-assets/vectors/svgIcons/view-equal.svg';
import { ReactComponent as ActiveSpeakerViewIcon } from '@lifesize/ux-assets/vectors/svgIcons/view-lecturer.svg';
import { ReactComponent as ClassicViewIcon } from '@lifesize/ux-assets/vectors/svgIcons/view-legacy.svg';

interface OptionType {
  dataTestId: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  label: string;
  value: string;
}

interface StyleProps {
  valueIndex: number;
}

interface Props {
  className?: string;
  disabled?: boolean;
  enableLegacy?: boolean;
  value: string;
}

const LayoutSwitch = (props: Props) => {
  const { className, disabled, enableLegacy = false, value } = props;

  const options = useCallback(() => {
    const options = [
      {
        dataTestId: 'gallery-view-toggle',
        icon: GalleryViewIcon,
        label: intl.get('layoutSwitch.galleryView'),
        value: c.SFU_LAYOUT_GALLERY
      },
      {
        dataTestId: 'speaker-view-toggle',
        icon: ActiveSpeakerViewIcon,
        label: intl.get('layoutSwitch.activeSpeaker'),
        value: c.SFU_LAYOUT_TALKER // SFU_LAYOUT_TALKER is the current default (it should always be an option)
      },
      {
        // now always visible (previously required > 1 remote streams)
        dataTestId: 'panel-view-toggle',
        icon: PanelViewIcon,
        label: intl.get('layoutSwitch.panelView'),
        value: c.SFU_LAYOUT_PANEL
      }
    ];

    if (enableLegacy) {
      options.push({
        dataTestId: 'classic-view-toggle',
        icon: ClassicViewIcon,
        label: intl.get('layoutSwitch.classicView'),
        value: c.SFU_LAYOUT_LEGACY
      });
    }
    return options;
  }, [enableLegacy])();

  // Admin Panel uses numeric values export const SFU_LAYOUT_TYPES = { LEGACY: 1, GALLERY: 2, SPEAKER: 3, PANEL: 4 };

  const currentIndex = options.findIndex((opt: any) => opt.value === value);
  // SFU_LAYOUT_TALKER is the current default (and also fallback when SFU_LAYOUT_MOBILE or SFU_LAYOUT_PANEL no longer apply)
  const defaultIndex = options.findIndex((opt: any) => opt.value === c.SFU_LAYOUT_DEFAULT);
  const valueIndex = currentIndex === -1 ? defaultIndex : currentIndex;
  const classes = useStyles({ valueIndex });

  const handleClick = useCallback(
    (payload: string) => {
      if (disabled) return;
      signaling.setVideoLayout(payload);
    },
    [disabled]
  );

  return (
    <div className={clsx(classes.container, className)} data-testid="layout-switch">
      <div className={clsx(classes.switch, disabled && 'isDisabled')}>
        {options.map((opt: OptionType, index) => {
          const Icon = opt.icon;
          return (
            <Tooltip title={opt.label} placement="left" disableHoverListener={disabled} key={`option-${index}`}>
              <Icon
                className={clsx(classes.icon, opt.value === value && 'isSelected')}
                onClick={() => handleClick(opt.value)}
                data-testid={opt.dataTestId}
              />
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    height: 60,
    position: 'relative',
    width: 60
  },
  switch: {
    backgroundColor: colors.black,
    border: `2px solid ${colors.white}`, // static border width to match FABs
    borderRadius: 30,
    bottom: 0,
    boxSizing: 'border-box',
    color: colors.white,
    cursor: 'pointer',
    display: 'inline-flex',
    flexDirection: 'column-reverse',
    height: 'auto',
    justifyContent: 'center',
    left: 0,
    minHeight: '100%',
    opacity: 1,
    padding: 0,
    position: 'absolute',
    right: 0,
    transform: 'none',
    width: '100%',
    zIndex: 11,

    '&:before': {
      // selected indicator
      backgroundColor: colors.white,
      borderRadius: '50%',
      display: 'block',
      content: "''",
      height: 60,
      bottom: -2,
      margin: 0,
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
      transition: 'bottom 0.2s ease-in-out',
      width: 60
    },

    '&:hover:not(.isDisabled)': {
      opacity: 1,
      '&:before': {
        bottom: (opts: StyleProps) => `calc(${opts.valueIndex} * (60px - 4px) - 2px)`
      },
      '& svg': {
        height: 'calc(60px - 4px)',
        width: 'auto'
      }
    },

    '&.isDisabled': {
      backgroundColor: colors.squidInk,
      borderColor: colors.sentinel,
      color: colors.sentinel,
      cursor: 'default',
      opacity: 1
    }
  },
  icon: {
    flex: '0 0 auto',
    height: 0,
    transition: 'color 0.2s ease-in-out, height 0.2s ease-in-out',
    width: 'auto',
    zIndex: 12,

    '&.isSelected': {
      color: colors.black,
      height: 'calc(60px - 4px)' // 2 x border
    }
  }
}));

export default LayoutSwitch;
