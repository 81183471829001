import { useEffect, useState, useMemo, useCallback } from 'react';
import throttle from 'lodash/throttle';
import { hooks } from '@lifesize/clients.sdk';
import { singletonHook } from 'react-singleton-hook';
import { audioContextOptions, analyserOptions } from 'utils/audioMonitorUtils';
import Logger from 'js-logger';

const useAudioMonitor = () => {
  const { localPrimaryStream } = hooks.useMedia();
  const isStreamActive = localPrimaryStream && localPrimaryStream.active;
  const [servicesInitialized, setServicesInitialized] = useState(false);

  const audioContext = useMemo(() => {
    if (isStreamActive) {
      setServicesInitialized(false);
      return new AudioContext(audioContextOptions);
    }
    return undefined;
  }, [isStreamActive]);

  const [microphone, setMicrophone] = useState<any>();
  const [analyser, setAnalyser] = useState<any>();
  const [script, setScript] = useState<any>();
  const [sampleDataArray, setSampleDataArray] = useState<number[]>([]);
  const onAudioProcess = throttle(() => processAudio(), 100, { leading: true, trailing: false });

  const initializeServices = useCallback(
    (audioContext: AudioContext) => {
      const audioMonitorStream = new MediaStream();
      const tracks = localPrimaryStream.getAudioTracks();
      if (tracks && tracks.length) {
        const track = tracks[0].clone();
        audioMonitorStream.addTrack(track);
        setScript(audioContext.createScriptProcessor(256, 1, 1));
        setMicrophone(audioContext.createMediaStreamSource(audioMonitorStream));
        setAnalyser(audioContext.createAnalyser());
        setServicesInitialized(true);
      } else {
        Logger.error('Failed to get audio tracks ');
      }
    },
    [setScript, setMicrophone, setAnalyser, localPrimaryStream]
  );

  const connectServices = useCallback(
    (audioContext: AudioContext) => {
      script.connect(audioContext.destination);
      analyser.smoothingTimeConstant = analyserOptions.smoothingTimeConstant;
      analyser.fftSize = analyserOptions.fftSize;
      analyser.minDecibels = analyserOptions.minDecibels;
      analyser.maxDecibels = analyserOptions.maxDecibels;
      analyser.connect(script);
      microphone.connect(analyser);

      script.addEventListener('audioprocess', onAudioProcess);
    },
    [analyser, microphone, script, onAudioProcess]
  );

  const disconnectServices = useCallback(() => {
    if (microphone && analyser && script) {
      script.removeEventListener('audioprocess', onAudioProcess);
      microphone.disconnect();
      analyser.disconnect();
      script.disconnect();
    }
  }, [microphone, analyser, script, onAudioProcess]);

  useEffect(() => {
    if (servicesInitialized && audioContext && audioContext.state === 'running') {
      connectServices(audioContext);
    }
    return () => {
      disconnectServices();
    };
  }, [connectServices, disconnectServices, audioContext, servicesInitialized]);

  useEffect(() => {
    if (audioContext && audioContext.state === 'running') {
      initializeServices(audioContext);
    }
  }, [audioContext, initializeServices]);

  const processAudio = () => {
    const array = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(array);
    const regularArray = Array.from(array);
    setSampleDataArray(regularArray);
  };

  return sampleDataArray;
};

export default singletonHook([0], useAudioMonitor);
