import gql from 'graphql-tag';

export default gql`
  query searchClientFeatureFlags($clientInfo: ClientInfoInput!, $names: [String!], $guid: String) {
    searchClientFeatureFlags(clientInfo: $clientInfo, names: $names, guid: $guid) {
      name
      value
    }
  }
`;
