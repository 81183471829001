import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { makeStyles, Box, Typography, useTheme, SvgIcon, Theme, Dialog, DialogContent } from '@material-ui/core';
import { hooks, signaling } from '@lifesize/clients.sdk';
import { Button, Input as TextInput, colors } from '@lifesize/clients.mui-components';
import { ReactComponent as EndCall } from '@lifesize/ux-assets/vectors/svgIcons/end-call.svg';
import { isWebkit } from 'utils/browserDetection';

const PinCodeModal = () => {
  const theme = useTheme();
  const classes = pinCodeStyles(theme);
  const { pinRequested } = hooks.useCallState();
  const [pinProtected, setPinProtected] = useState(false); // identifies a pin-protected meeting
  const [pinCode, setPinCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isMeetingPinSent, setIsMeetingPinSent] = useState(false); // indicates whether the pin code has been sent at least once

  useEffect(() => {
    if (!pinProtected && pinRequested) setPinProtected(true);
    if (pinProtected && !isMeetingPinSent && !pinRequested) setIsMeetingPinSent(true);
    if (pinProtected && isMeetingPinSent && pinRequested) setErrorMsg(intl.get('invalidPasscode'));
  }, [isMeetingPinSent, pinRequested, pinProtected]);

  const verifyPinCode = () => {
    if (!pinCode) {
      setErrorMsg(intl.get('mandatoryPasscode'));
      return;
    }
    signaling.sendMeetingPin(pinCode);
  };

  const handleKeyDown = (evt: any) => {
    if (
      evt.key.toLowerCase() === 'enter' || // Capture Enter key
      evt.key === '#' // This should capture all pound signs in theory
    ) {
      evt.preventDefault();
      verifyPinCode();
    }
  };

  return (
    <Dialog
      aria-labelledby="pincode-modal-title"
      aria-describedby="pincode-modal-description"
      open={pinRequested}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={false}
      PaperProps={{
        square: true
      }}
    >
      <DialogContent data-testid="passcodeModal" className={classes.dialogContent}>
        <Box className={classes.dialogBody}>
          <Box data-testid="leaveMeeting" mb={2} className={classes.header} onClick={() => signaling.endCall()}>
            <SvgIcon component={EndCall} />
            <Typography variant={'button'} className="headerLabel">
              {intl.get('leave')}
            </Typography>
          </Box>
          <Box flex={'3'} component={'div'} onKeyDown={handleKeyDown}>
            <Box className={classes.detail} my={2}>
              <Typography variant={'h4'} data-testid="passcodeGreeting">
                {intl.get('passCodeProtectedMeeting')}
              </Typography>
            </Box>
            <Box my={2}>
              <TextInput
                data-testid="passcodeInputDiv"
                autoComplete={'one-time-code'}
                autoFocus
                className={classes.pinInput}
                error={!!errorMsg}
                helperText={errorMsg}
                /* Chrome: inputMode, Firefox: x-inputmode, iOS Safari: pattern ('\d*' or '[0-9]*')
                 * https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/
                 * note: Chrome will show the text keyboard if the type is 'password', but since it is webkit it
                 * supports '-webkit-text-security': 'disc', so let it be 'text' and hide the input with css */
                inputProps={{
                  inputMode: 'decimal',
                  'x-inputmode': 'decimal',
                  pattern: '[0-9]*'
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const numericValue = event.target.value.replace(/\D/g, '');
                  setPinCode(numericValue);
                  setErrorMsg('');
                }}
                placeholder={intl.get('passCode')}
                type={isWebkit() ? 'text' : 'password'}
                value={pinCode}
                variant="outlined"
              />
            </Box>
            <Box className={classes.dialogActions} width="auto">
              <Button
                color={'primary'}
                data-testid="enterMeeting"
                disabled={!pinCode}
                onClick={verifyPinCode}
                variant={'contained'}
              >
                <Typography variant={'button'}>{intl.get('enterMeeting')}</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
const pinCodeStyles = makeStyles((theme: Theme) => ({
  header: {
    height: '20px',
    width: '100%',
    color: colors.lifesize,
    display: 'flex',
    flex: '1 1 0',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  detail: {
    textAlign: 'center'
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .headerLabel': {
      lineHeight: '1.7'
    }
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    maxWidth: '500px',
    padding: theme.spacing(2),
    '& .MuiTextField-root': {
      width: '100%',
      maxWidth: '500px'
    },
    '& input::-webkit-inner-spin-button': {
      display: 'none',
      margin: 0
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield'
    }
  },
  dialogActions: {
    width: '100%',
    '& button': {
      width: '100%',
      maxWidth: '500px'
    }
  },
  pinInput: {
    '& input': {
      '-webkit-text-security': 'disc'
    }
  }
}));
export default PinCodeModal;
