import React, { useEffect, useState } from 'react';
import { hooks } from '@lifesize/clients.sdk';
import { colors } from '@lifesize/clients.mui-components';
import { makeStyles, Theme } from '@material-ui/core';
import { getDuration } from 'utils/timeUtils';

const CallDuration = () => {
  const { startTime } = hooks.useCallState();
  const [callDuration, setCallDuration] = useState('');
  const classes = useStyles();
  let durationInterval: number;

  useEffect(() => {
    startTicking();
    return function cleanup() {
      if (durationInterval != null) clearInterval(durationInterval);
    };
  });

  const startTicking = () => {
    // using window.setInterval to keep eslint from complaining about return type of SetInterval.
    durationInterval = window.setInterval(() => {
      setCallDuration(getDuration(startTime));
    }, 1000);
  };

  return (
    <div data-testid="callDuration" className={classes.container}>
      {callDuration || '00:00'}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  container: {
    animationDelay: '0s',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationName: '$fadeIn',
    color: colors.white,
    fontSize: '1.4rem',
    marginBottom: theme.spacing(1),
    opacity: 0,
    textShadow: `1px 1px 2px ${colors.black}`
  }
}));

export default CallDuration;
