import React from 'react';
import intl from 'react-intl-universal';
import { Button, makeStyles, Theme } from '@material-ui/core';

interface Props {
  onDismissTooltip: (event: any) => void;
  onClick: (event: any) => void;
}

const SpeakingWhileMutedTooltip = ({ onClick, onDismissTooltip }: Props) => {
  const classes = useStyles();
  return (
    <>
      <h3 className={classes.title}>{intl.get('speakingWhileMutedHeader')}</h3>
      <div>{intl.get('speakingWhileMutedMessage')}</div>
      <div className={classes.tipButtonBar}>
        <Button color="primary" onClick={onDismissTooltip} size="small">
          {intl.get('dismiss')}
        </Button>
        <Button color="primary" onClick={onClick} size="small" variant="contained">
          {intl.get('unmute')}
        </Button>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: 0
  },
  tipButtonBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),

    '& > button': {
      textTransform: 'uppercase',

      '&:first-child': {
        flex: '0 0 auto',
        marginRight: theme.spacing(2)
      }
    }
  }
}));

export default SpeakingWhileMutedTooltip;
