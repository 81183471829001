import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { hooks } from '@lifesize/clients.sdk';
import { Button, colors } from '@lifesize/clients.mui-components';
import { useMutation } from '@apollo/react-hooks';
import { Box, makeStyles, TextareaAutosize as TextInput } from '@material-ui/core';
import GET_MESSAGES from '../../gql/queries/getMessages';
import POST_MESSAGE from '../../gql/queries/postMessage';

const ChatInput = (props: any) => {
  const classes = useStyles();
  const { guestToken, name, uuid } = hooks.useUserInfo();
  const callFullyConnected = hooks.useConferenceId();
  const { chatRoomUUID, onSubmit } = props;
  const [value, setValue] = useState('');
  const inputRef = useRef<any>(null);

  const update = (cache: any, result: any) => {
    const variables = { uuid: chatRoomUUID };
    const oldData = cache.readQuery({ query: GET_MESSAGES, variables });
    const data = {
      chatMessages: {
        ...oldData.chatMessages,
        messages: [result.data.postMessage, ...oldData.chatMessages.messages]
      }
    };
    cache.writeQuery({ query: GET_MESSAGES, variables, data });
  };

  const [postMessage] = useMutation(POST_MESSAGE, {
    context: {
      headers: {
        authorization: guestToken
      }
    },
    update
  });

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      onSend(value);
    }
  };

  const onSend = (rawMessage: string) => {
    if (!chatRoomUUID) return;
    const message = rawMessage.trim();
    if (!!message) {
      setValue('');
      inputRef.current.focus();
      onSubmit();
      const optimisticResponse = {
        __typename: 'Mutation',
        postMessage: {
          activityType: 'MESSAGE',
          createdAt: Date.now(),
          message,
          member: {
            node: {
              displayName: name,
              uuid: uuid,
              __typename: 'Guest'
            },
            __typename: 'ChattableEdge'
          },
          uuid: `optimistic-uuid.${Math.random()}`,
          __typename: 'ChatMessage'
        }
      };

      postMessage({ variables: { uuid: chatRoomUUID, message }, optimisticResponse });
    }
  };

  return (
    <Box className={classes.container} mt={1} mb={3}>
      <TextInput
        autoFocus
        className={classes.input}
        disabled={!callFullyConnected}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => onKeyPress(e)}
        placeholder={intl.get('writeMessage')}
        ref={inputRef}
        rowsMax={4}
        value={value}
      />
      <Button
        className={classes.button}
        color="primary"
        data-testid="send"
        disabled={!value.trim() || !callFullyConnected}
        onClick={() => onSend(value)}
        size="small"
        variant="contained"
      >
        {intl.get('send')}
      </Button>
    </Box>
  );
};

const useStyles = makeStyles<any, any>((theme) => ({
  container: {
    alignItems: 'flex-end',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column'
  },
  input: {
    border: `2px solid ${colors.stone}`,
    borderRadius: 2,
    fontFamily: 'inherit',
    fontSize: '1.4rem',
    outline: 'none',
    padding: '1rem 1rem 1.2rem',
    resize: 'none',
    width: '100%',

    '&:hover': {
      borderColor: colors.lifesize
    },

    '&:focus': {
      borderColor: colors.lifesize
    }
  },
  button: {
    borderRadius: 2,
    marginTop: theme.spacing(2),
    maxWidth: '100%',
    minHeight: 0,
    minWidth: 0,
    padding: '0.4rem 1rem'
  }
}));

export default ChatInput;
