import { createSlice } from '@reduxjs/toolkit';

interface SettingsDrawerState {
  open: boolean;
}

const initialState: SettingsDrawerState = {
  open: false
};

const settingsDrawerSlice = createSlice({
  name: 'settingsDrawer',
  initialState,
  reducers: {
    toggleOpen(state) {
      state.open = !state.open;
    }
  }
});

export const { toggleOpen } = settingsDrawerSlice.actions;
export default settingsDrawerSlice.reducer;
