import gql from 'graphql-tag';

export default gql`
  mutation postMessage($uuid: String!, $message: String!) {
    postMessage(uuid: $uuid, message: $message) {
      uuid
      message
      createdAt
      activityType
      member {
        node {
          uuid
          displayName
        }
      }
    }
  }
`;
