import { useState, useCallback } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import { connect, hooks, ObservableEvent } from '@lifesize/clients.sdk';
import GET_MESSAGES from '../gql/queries/getMessages';
import JOIN_ROOM from '../gql/queries/joinRoom';
import Logger from 'js-logger';

const topic = 'ls.gqlChat';

const useChat = (): any => {
  const [chatRoomUUID, setChatRoomUUID] = useState(null);
  const [chatError, setChatError] = useState(false); // TODO: more detail
  const { guestToken } = hooks.useUserInfo();
  const client = useApolloClient();

  const addChatToStore = useCallback(
    (chatEvent) => {
      try {
        const rawChat = JSON.parse(chatEvent?.args?.[0]);
        const queryObj = { query: GET_MESSAGES, variables: { uuid: rawChat.roomUUID } };
        const data = client.readQuery(queryObj);

        if (rawChat.event !== 'chatMessage') return;

        // format even to gql types
        const chatTypeName = rawChat.activityType === 'MESSAGE' ? 'ChatMessage' : 'ChatEvent';

        // see if chat already exists:
        if (data?.chatMessages?.messages?.find((m: any) => m.uuid === rawChat.uuid)) return;

        // polyfill data from old gql service
        if (!rawChat.member) {
          rawChat.member = {
            uuid: rawChat.userUUID,
            userUUID: rawChat.userUUID,
            displayName: rawChat.displayName
          };
        }

        const chat = {
          ...rawChat,
          createdAt: typeof rawChat.createdAt !== 'number' ? Date.parse(rawChat.createdAt) : rawChat.createdAt,
          member: {
            node: {
              ...rawChat?.member,
              uuid: rawChat?.member?.userUUID,
              __typename: rawChat?.member?.userUUID?.startsWith('guest') ? 'Guest' : 'User'
            },
            __typename: 'ChattableEdge'
          },
          __typename: chatTypeName
        };

        client.writeQuery({
          ...queryObj,
          data: {
            ...data,
            chatMessages: {
              ...data.chatMessages,
              messages: [chat, ...data.chatMessages.messages]
            }
          }
        });
      } catch (ex) {
        Logger.info('could not process chat event', ex);
      }
    },
    [client]
  );

  const [joinRoom] = useMutation(JOIN_ROOM, {
    context: {
      headers: {
        authorization: guestToken
      }
    }
  });

  const { data: getMessages } = useQuery(GET_MESSAGES, {
    context: {
      headers: {
        authorization: guestToken
      }
    },
    variables: { uuid: chatRoomUUID },
    skip: !chatRoomUUID
  });

  // first filter out any 'events' from the chat messages (example: 'XYZ has joined the chat', etc.)
  const chats = (getMessages?.chatMessages?.messages || []).filter((chat: any) => chat.__typename !== 'ChatEvent');

  const onConferenceJoined = useCallback(
    async ({ conferenceId, type, uuid }: any) => {
      if (chatRoomUUID || !conferenceId) return;

      const variables = {
        uuid: conferenceId
      };

      if (type === 'VMR') {
        // @ts-ignore
        variables['vmrUUID'] = uuid;
      }

      try {
        const result = await joinRoom({ variables });
        const roomUUID = result?.data?.joinRoom?.node?.uuid;
        setChatRoomUUID(roomUUID);
        // subscribe to chat
        connect.subscription.add(topic, (chatEvent: any) => addChatToStore(chatEvent));
        // TODOL unsubscribe
        setChatError(false);
      } catch (ex) {
        Logger.info('could not join room:', ex);
        setChatError(true);
      }
    },
    [chatRoomUUID, joinRoom, setChatError, addChatToStore]
  );

  hooks.useObservableEvent(ObservableEvent.ConferenceJoined, onConferenceJoined);
  return { joinRoom, chats, chatRoomUUID, chatError };
};

export default useChat;
