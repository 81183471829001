import { useEffect, useState } from 'react';
import { hooks, signaling, constants as c } from '@lifesize/clients.sdk';

const usePresentationRestriction = (): any => {
  const presentationRestriction = hooks.usePresentationRestriction();
  const [showPresentationRestrictionTooltip, setShowPresentationRestrictionTooltip] = useState(false);
  const mediaState = hooks.useMedia();
  const isConnected = hooks.useCallConnected();
  const isRemotePresentationExists = mediaState.remotePresentationStream && isConnected;
  const conferenceInfo = signaling.selectors.conferenceInfo();
  const currentUser = hooks.useCurrentUserParticipant() || {};

  useEffect(() => {
    if (
      conferenceInfo.type === 'VMR' &&
      presentationRestriction === c.PresentationRestriction.restrictedShare &&
      !currentUser.canPresent &&
      !isRemotePresentationExists
    ) {
      setShowPresentationRestrictionTooltip(true);
    } else if (showPresentationRestrictionTooltip) {
      setShowPresentationRestrictionTooltip(false);
    }
  }, [
    presentationRestriction,
    conferenceInfo.type,
    currentUser.canPresent,
    isRemotePresentationExists,
    showPresentationRestrictionTooltip
  ]);
  return {
    isPresentationRestricted: presentationRestriction === c.PresentationRestriction.restrictedShare,
    showPresentationRestrictionTooltip
  };
};

export default usePresentationRestriction;
