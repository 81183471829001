import intl from 'react-intl-universal';

export const validateName = (newValue: string): string => {
  return newValue.length === 0 || (!!newValue && newValue.length < 33) ? '' : intl.get('nameLengthError');
};

export const validateEmail = (newValue: string): string => {
  if (newValue.length === 0 || (!!newValue && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(newValue))) {
    return '';
  }
  return intl.get('invalidEmailError');
};
