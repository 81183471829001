import Logger from 'js-logger';
/**
 * Available keys for Guest app
 */
export enum LsKey {
  CookiesAccepted = 'cookiesAccepted',
  Email = 'email',
  EnableLogging = 'enableLogging',
  Locale = 'locale',
  Media = 'media',
  Name = 'name',
  ReceiveStreams = 'receiveStreams',
  SkipTrialNavigationForTest = 'skipTrialNavigationForTest',
  TosAccepted = 'tosAccepted',
  VbbImages = 'VbbImages',
  simulateNewVersion = 'simulateNewVersion',
  testUnsupported = 'testUnsupported'
}

/**
 * Set a guest app key value from local storage
 * @param key - one of lsKey string enum
 * @param value - new value to set
 */
export const lsSet = (key: LsKey, value: string): void => {
  Logger.info(`Setting ${key} to ${value}`);
  return window.localStorage.setItem(key, value);
};

/**
 * Get a guest app key value from local storage
 * @param key - one of lsKey string enum
 */
export const lsGet = (key: LsKey): string | null => {
  Logger.debug(`Getting ${key}`);
  return window.localStorage.getItem(key);
};

/**
 * Remove a guest app key from local storage
 * @param key - one of lsKey string enum
 */
export const lsRemove = (key: LsKey): void => {
  Logger.debug(`Removing ${key}`);
  return window.localStorage.removeItem(key);
};
