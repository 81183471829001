import { createSlice } from '@reduxjs/toolkit';

export interface bgSelectionType {
  dataUrl?: string; // existence indicates that the image was uploaded (user-provided, can be deleted)
  id: string; // 'none', 'blur', or some other id
  path?: string; // existance indicates that the image is default (cannot be deleted)
}
interface VbbSettingsSlice {
  drawerIsOpen: boolean;
  enabled: boolean;
  enabling: boolean;
  error: string;
  selection: bgSelectionType;
}

const initialState: VbbSettingsSlice = {
  drawerIsOpen: false,
  enabled: false,
  enabling: false,
  error: '',
  selection: { id: 'none' } // TODO: move to constants
};

const vbbSettingsSlice = createSlice({
  name: 'vbbSettings',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },
    setBackground(state, action) {
      state.selection = action.payload;
    },
    setEnabled(state, action) {
      state.enabled = action.payload;
    },
    setEnabling(state, action) {
      state.enabling = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    toggleDrawerOpen(state) {
      state.drawerIsOpen = !state.drawerIsOpen;
    }
  }
});

export const {
  clearError,
  setBackground,
  setEnabled,
  setEnabling,
  setError,
  toggleDrawerOpen
} = vbbSettingsSlice.actions;
export default vbbSettingsSlice.reducer;
