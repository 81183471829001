import throttle from 'lodash/throttle';

export const audioContextOptions: AudioContextOptions = {
  latencyHint: 'playback',
  sampleRate: 8000
};

export const analyserOptions = {
  smoothingTimeConstant: 0.3,
  fftSize: 256,
  minDecibels: -75,
  maxDecibels: -18
};

const speechDetectionQueueLength: number = 10;
const speechDetectionQueue: number[] = new Array(speechDetectionQueueLength).fill(0);

const speechDetectionThreshold = 2;
const hz70Index = 1;
const hz270Index = 30;

export const isSpeaking = throttle((sampleData: number[], callback: Function) => {
  if (!(sampleData && sampleData.length)) {
    return false;
  }
  sampleData.splice(0, hz70Index);
  sampleData.splice(hz270Index - hz70Index);
  const sum = sampleData.reduce((a, b) => {
    return a + b;
  }, 0);
  const value = Math.sqrt(sum / analyserOptions.fftSize);
  speechDetectionQueue.shift();
  speechDetectionQueue.push(value);
  const speechDetectionAverage =
    speechDetectionQueue.reduce((a, b) => {
      return a + b;
    }, 0) / speechDetectionQueueLength;
  if (speechDetectionAverage >= speechDetectionThreshold) {
    speechDetectionQueue.fill(0);
    callback(true);
  } else {
    callback(false);
  }
}, 100);

export const getMaxOutputVolume = (sampleData: number[]) => {
  const max = sampleData && sampleData.length ? (Math.max(...sampleData) / 256).toFixed(2) : '0.00';
  return parseFloat(max) * 100;
};
