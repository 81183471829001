import { lsGet, LsKey, lsSet } from 'utils/localStorageUtil';
import { bgOptType } from 'components/ImagePicker/ImagePicker';

export function readFromLocalStorage() {
  const bgArray = lsGet(LsKey.VbbImages) || '[]';
  return JSON.parse(bgArray);
}

export function writeToLocalStorage(bgArray: bgOptType[]) {
  lsSet(LsKey.VbbImages, JSON.stringify(bgArray));
}
