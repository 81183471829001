import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';

import { RootState } from 'redux/rootReducer';
import { notificationDisplayType, NotificationObj, tooltipNotificationIds } from 'redux/tooltipNotificationSlice';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';

const RaiseHandsSnackbar = () => {
  const classes = useStyles();
  const generalNotificationObject: NotificationObj | undefined = useSelector(
    (state: RootState) => state.tooltipNotification[tooltipNotificationIds.generalMessage]
  );
  const personalNotificationObject: NotificationObj | undefined = useSelector(
    (state: RootState) => state.tooltipNotification[tooltipNotificationIds.personalMessage]
  );
  const generalNotification =
    !!generalNotificationObject && generalNotificationObject.displayType === notificationDisplayType.snackbar
      ? generalNotificationObject
      : undefined;
  const personalNotification =
    !!personalNotificationObject && personalNotificationObject.displayType === notificationDisplayType.snackbar
      ? personalNotificationObject
      : undefined;
  const notification = personalNotification || generalNotification; // favor personal notifications
  const message = !!notification ? intl.get(notification.id, notification.values) : '';

  return (
    <Snackbar {...defaultProps} open={!!notification}>
      <SnackbarContent className={classes.message} aria-describedby="client-snackbar" message={message} />
    </Snackbar>
  );
};

export default RaiseHandsSnackbar;
