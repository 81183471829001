import gql from 'graphql-tag';

export default gql`
  mutation joinRoom($uuid: String!, $vmrUUID: String) {
    joinRoom(uuid: $uuid, vmrUUID: $vmrUUID) {
      node {
        uuid
        lastUpdated
        meeting {
          displayName
          uuid
        }
      }
    }
  }
`;
