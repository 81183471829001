import React, { useEffect } from 'react';
import { Button } from '@lifesize/clients.mui-components';
import Dialog from '@material-ui/core/Dialog';
import intl from 'react-intl-universal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { lsGet, LsKey } from 'utils/localStorageUtil';

export default function NewVersionDialog() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (window?.newVersionFound || lsGet(LsKey.simulateNewVersion)) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    window.location.reload(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">{intl.get('newVersionFound')}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle1">{intl.get('newVersionFoundSubtitle')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose} className={classes.button}>
          <Typography variant="button">{intl.get('close')}</Typography>
        </Button>
        <Button variant="contained" onClick={handleAccept} color="primary" autoFocus className={classes.button}>
          <Typography variant="button">{intl.get('refresh')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      width: '50%'
    }
  }
}));
