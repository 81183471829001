import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { hooks, signaling } from '@lifesize/clients.sdk';
import { colors, RaiseHandButton as RaiseHandMUI } from '@lifesize/clients.mui-components';
import { Box, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { RootState } from 'redux/rootReducer';
import { notificationDisplayType, NotificationObj, tooltipNotificationIds } from 'redux/tooltipNotificationSlice';
import { getRaiseHandToolTipText } from 'utils/tooltipUtils';

interface StyleConfig {
  isNotification: boolean;
}

const useStylesNotification = makeStyles((theme: Theme) => ({
  tooltip: (config: StyleConfig) =>
    config.isNotification
      ? {
          backgroundColor: theme.palette.background.default,
          color: colors.black
        }
      : {}
}));

interface Props {
  isHidden?: boolean;
}

export default function RaiseHandButton(props: Props) {
  const callState = hooks.useCallState();
  const callConnected = callState.state === 'connected';
  const callFullyConnected = hooks.useConferenceId(); // We can issue raise/lower hand requests once the conferenceId is available (~1 - 2 sec after call connected)
  const handIsRaised = hooks.useIsCurrentUserHandRaised();
  const handIsProcessing = hooks.useIsProcessingHandCommand();
  const isRaised = handIsProcessing ? !handIsRaised : handIsRaised;
  const notificationObject: NotificationObj | undefined = useSelector(
    (state: RootState) => state.tooltipNotification[tooltipNotificationIds.personalMessage]
  );
  const notification =
    !!notificationObject && notificationObject.displayType === notificationDisplayType.tooltip
      ? notificationObject
      : undefined;
  const tooltipClasses = useStylesNotification({ isNotification: !!notification });
  const tooltipPopperProps = !!notification
    ? { open: true } // forces the tooltip open when there is a notification
    : handIsProcessing || props.isHidden
    ? { open: false } // forces the tooltip closed while the hand state is processing or ButtonBar is idle (hidden)
    : {}; // behave normally if no exception condition is met
  const tooptipTitle = !!notification
    ? intl.get(notification.id, notification.values)
    : getRaiseHandToolTipText(handIsRaised); // don't use optimistic state for the tooltip title (it will be hidden during transition but there may be a brief overlap)

  const handleOnClick = () => {
    if (isRaised) {
      signaling.lowerHand();
    } else {
      signaling.raiseHand();
    }
  };

  return (
    <Tooltip
      classes={tooltipClasses}
      disableHoverListener={!callConnected} // Hide tooltip when call is connecting.
      title={tooptipTitle}
      PopperProps={tooltipPopperProps}
    >
      <Box display="flex" className="lsCallControl">
        <RaiseHandMUI
          data-testid={isRaised ? 'btnLowerHand' : 'btnRaiseHand'}
          disabled={!callFullyConnected || handIsProcessing}
          onClick={handleOnClick}
          selected={isRaised}
        />
      </Box>
    </Tooltip>
  );
}
