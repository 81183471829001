import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { makeStyles, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { colors, SwipeableDrawer } from '@lifesize/clients.mui-components';
import { media } from '@lifesize/clients.sdk';
import { theme } from 'theme';
import PSTNSettings from './PSTNSettings';
import ComputerAudioSettings from './ComputerAudio';
import ComputerVideoSettings from './ComputerVideo';
import { DrawerSide } from 'types/pages';
import { set as updateSettings } from 'redux/mediaSettingsSlice';
import { toggleOpen } from 'redux/settingsDrawerSlice';
import { RootState } from 'redux/rootReducer';
import { fetchPSTNData } from 'redux/extension';
import { isIosMobile } from 'utils/browserDetection';
import { audioOutputOptions, MediaConfig, getConstraints } from 'utils/devicesUtils';
import { getPstnOptions } from 'utils/pstn';
import { writeToLocalStorage } from 'utils/localStorage/mediaSettings';

interface Props {
  inCall?: boolean;
}

const AudioVideoSettings = (props: Props): React.ComponentElement<Props, any> => {
  const classes = useStyles({});
  const hasCheckedPSTN = useRef(false);
  const drawerDirection: DrawerSide = props.inCall ? 'right' : 'left';
  const dispatch = useDispatch();
  const extension = useSelector((state: RootState) => state.extension);
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);
  const open = useSelector((state: RootState) => state.settingsDrawer.open);
  const pstnNumbers = useSelector((state: RootState) => state.pstnNumbers);
  const hasPSTN = pstnNumbers?.allNumbers?.length || pstnNumbers?.customNumbers?.length;

  useEffect(() => {
    if (extension.groupUUID && open && !hasCheckedPSTN.current) {
      dispatch(fetchPSTNData(extension.groupUUID));
      hasCheckedPSTN.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleConfigChange = useCallback(
    async (key: keyof MediaConfig, value: string) => {
      const newValues: MediaConfig = { ...mediaSettings, [key]: value };

      // mute/unmute audio with phone(pstn)/computer change
      if (key === 'audio') {
        if (value === 'computer') {
          media.setAudioMute(false);
          newValues.localAudioMuted = false;
        } else {
          // 'phone'
          // RemoteStream video component will automatically mute when 'phone' is selected
          media.setAudioMute(true);
          newValues.localAudioMuted = true;
        }
      }

      // persist the values
      writeToLocalStorage(newValues);
      // set the global state
      dispatch(updateSettings(newValues));

      const oldConstraints = getConstraints(mediaSettings);
      const newConstraints = getConstraints(newValues);
      if (oldConstraints !== newConstraints) {
        const newMediaSettings = { defaultConstraints: newConstraints };
        await media.configure(newMediaSettings);
      }
    },
    [mediaSettings, dispatch]
  );

  const handleAudioTabChange = (event: any, newValue: number) => {
    handleConfigChange('audio', audioOutputOptions[newValue]);
  };

  return (
    <SwipeableDrawer
      anchor={drawerDirection}
      closeButtonProps={{
        dataTestId: 'avSettingsClose',
        closeDrawer: () => dispatch(toggleOpen()),
        label: intl.get('close')
      }}
      isIos={isIosMobile()}
      onClose={() => dispatch(toggleOpen())}
      onOpen={() => dispatch(toggleOpen())}
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.container}>
        <Box className={classes.scrollContainer} p={2}>
          <Box className={classes.form} data-testid="avSettingsDivision">
            <Box pb={1} className={classes.heading}>
              <Typography component="div" className={classes.headingTitle} data-testid="videoSettingsHeader">
                {intl.get('videoSettings')}
              </Typography>
            </Box>

            <ComputerVideoSettings handleConfigChange={handleConfigChange} />

            <Box pb={1} mt={2} className={classes.heading}>
              <Typography component="div" className={classes.headingTitle} data-testid="audioSettingsHeader">
                {intl.get('audioSettings')}
              </Typography>
            </Box>

            {!!hasPSTN && (
              <Tabs
                aria-label={intl.get('audioLabel')}
                indicatorColor="primary"
                onChange={handleAudioTabChange}
                textColor="primary"
                value={audioOutputOptions.findIndex((opt) => opt === mediaSettings.audio)}
                variant="fullWidth"
              >
                <Tab data-testid="computer-audio-tab" label={intl.get('deviceAudioLabel')} />
                <Tab data-testid="phone-audio-tab" label={intl.get('phoneAudioLabel')} />
              </Tabs>
            )}

            {(mediaSettings.audio === 'computer' || !hasPSTN) && (
              <ComputerAudioSettings inCall={props.inCall} handleConfigChange={handleConfigChange} />
            )}

            {mediaSettings.audio === 'phone' && hasPSTN && (
              <PSTNSettings extension={extension.value} pstnNumbers={getPstnOptions(pstnNumbers)} />
            )}
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const useStyles = makeStyles(() => ({
  drawerPaper: {
    backgroundColor: colors.nimbusCloud,
    maxWidth: '100%',
    width: 400
  },
  container: {
    color: colors.lightSlateGray,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    marginTop: theme.spacing(4)
  },
  heading: {
    alignItems: 'flex-start',
    borderBottom: `1px solid ${colors.stone}`,
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center'
  },
  headingTitle: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    fontSize: '1.8rem',
    fontWeight: 500
  },
  scrollContainer: {
    height: '100%',
    width: '100%'
  },
  form: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%'
  }
}));

export default AudioVideoSettings;
