import { combineReducers } from '@reduxjs/toolkit';
import callReducer from './callSlice';
import complianceReducer from './complianceSlice';
import extensionReducer from './extensionSlice';
import mediaSettingsReducer from './mediaSettingsSlice';
import modalsReducer from './modalsSlice';
import participantsDrawerReducer from './participantsDrawerSlice';
import pstnNumbersReducer from './pstnNumbersSlice';
import selfViewReducer from './selfViewSlice';
import settingsDrawerReducer from './settingsDrawerSlice';
import snackbarsReducer from './snackbarsSlice';
import tooltipNotificationReducer from './tooltipNotificationSlice';
import userInformationReducer from './userInformationSlice';
import whiteboardReducer from './whiteboardSlice';
import chatDrawerReducer from './chatDrawerSlice';
import featureFlagReducer from './featureFlagSlice';
import vbbReducer from './vbbSettingsSlice';

const rootReducer = combineReducers({
  call: callReducer,
  compliance: complianceReducer,
  extension: extensionReducer,
  mediaSettings: mediaSettingsReducer,
  modals: modalsReducer,
  participantsDrawer: participantsDrawerReducer,
  pstnNumbers: pstnNumbersReducer,
  selfView: selfViewReducer,
  settingsDrawer: settingsDrawerReducer,
  snackbars: snackbarsReducer,
  tooltipNotification: tooltipNotificationReducer,
  userInformation: userInformationReducer,
  whiteboard: whiteboardReducer,
  chat: chatDrawerReducer,
  features: featureFlagReducer,
  vbbSettings: vbbReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
