import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import { acceptTos } from 'redux/complianceSlice';
import { storeTosAcceptance } from 'utils/localStorage/compliance';
import { getPrivacyLink, getTosLink } from 'utils/localeUtil';
import { RootState } from 'redux/rootReducer';

const TermsOfService = () => {
  const classes = userFieldStyles();
  const dispatch = useDispatch();

  const { tos } = useSelector((state: RootState) => state.compliance);

  const handleTosAcceptance = (isAccepted: boolean) => {
    dispatch(acceptTos(storeTosAcceptance(isAccepted)));
  };

  const tosLabel = () => {
    return (
      <Typography className={classes.tosLabel} data-testid="termsCondition" component="span">
        {intl.getHTML('tosAgreement', {
          privacyLink: getPrivacyLink(),
          termsLink: getTosLink()
        })}
      </Typography>
    );
  };

  return (
    <Box mt={1} width={'100%'}>
      <FormControlLabel
        control={<Checkbox checked={!!tos} onChange={() => handleTosAcceptance(!tos)} color="primary" />}
        data-testid="tosCheckbox"
        label={tosLabel()}
      />
    </Box>
  );
};

const userFieldStyles = makeStyles(() => ({
  tosLabel: {
    fontSize: '1.1rem'
  }
}));

export default TermsOfService;
