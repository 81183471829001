import { createSlice } from '@reduxjs/toolkit';

interface whiteboardState {
  error: string | JSX.Element;
}

const initialState: whiteboardState = {
  error: ''
};

const whiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    clear(state) {
      state.error = '';
    },
    set(state, action) {
      const { error } = action.payload;
      state.error = error;
    }
  }
});

export const { clear, set } = whiteboardSlice.actions;
export default whiteboardSlice.reducer;
