import { createSlice } from '@reduxjs/toolkit';

interface ParticipantsDrawerState {
  open: boolean;
}

const initialState: ParticipantsDrawerState = {
  open: false
};

const participantsDrawerSlice = createSlice({
  name: 'participantsDrawer',
  initialState,
  reducers: {
    toggleOpen(state) {
      state.open = !state.open;
    }
  }
});

export const { toggleOpen } = participantsDrawerSlice.actions;
export default participantsDrawerSlice.reducer;
