export type PSTNObject = {
  allNumbers: Array<PstnNumber>;
  customNumbers: Array<PstnNumber>;
};
export interface PstnNumber {
  id: string;
  countryCode: string;
  phoneNumber: string;
  value?: string;
}

export const getPstnOptions = (pstnObject: PSTNObject): PSTNObject => {
  const customNumbers = pstnObject?.customNumbers?.map((pref: PstnNumber) => {
    return { ...pref, icon: `/flags/${pref?.countryCode?.toLowerCase()}.svg`, label: pref.phoneNumber, value: pref.id };
  });
  const allNumbers = pstnObject?.allNumbers?.map((pref: PstnNumber) => {
    return {
      ...pref,
      icon: `/flags/${pref?.countryCode?.toLowerCase()}.svg`,
      label: pref.phoneNumber,
      value: pref.id
    };
  });

  return { allNumbers, customNumbers };
};
