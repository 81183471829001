import React from 'react';
import { fade, Theme, makeStyles } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';

interface Props {
  show: boolean;
}

const LoadingAnimation = (props: Props) => {
  const classes = useStyles();
  if (!props.show) return null;
  return <div className={classes.animation} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes pulse-ring': {
    '0%': {
      transform: 'scale(0.33)'
    },
    '80%, 100%': {
      opacity: 0
    }
  },

  '@keyframes pulse-dot': {
    '0%': {
      transform: 'scale(0.8)'
    },
    '50%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(0.8)'
    }
  },

  animation: {
    height: 30,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: 30,

    '&:before': {
      content: '',
      position: 'relative',
      display: 'block',
      width: '300%',
      height: '300%',
      boxSizing: 'border-box',
      marginLeft: '-100%',
      marginTop: '-100%',
      borderRadius: 45,
      backgroundColor: colors.lifesize,
      animation: 'pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite'
    },

    '&:after': {
      content: '',
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: colors.lifesize,
      borderRadius: 15,
      boxShadow: `0 0 8px ${fade(colors.black, 0.3)}`,
      animation: 'pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite'
    }
  }
}));

export default LoadingAnimation;
