import { isIosMobile } from './browserDetection';
import _get from 'lodash/get';
import { highQualityHardwareConcurrencyLimit } from 'constants/constants';

interface Resolution {
  height: number;
  width: number;
  value: string;
}

export interface StyleConfig {
  containerAspect?: number | undefined;
  height: number; // height (as a percentage of container's width)
  localPipHeight?: number | undefined;
  width: number; // width (as a percentage of container's width)
}

const defaultVideoHeight = 1080; // HD
const defaultVideoAspect = 9 / 16; // HD/UHD
const isLowSpecSystemOrSafari =
  !navigator?.hardwareConcurrency || navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit;
const defaultResolutionLabel = isLowSpecSystemOrSafari ? '360p' : '720p';

const getMaximumReceiveStreams = () => {
  // local storage override
  const receiveStreams = parseInt(localStorage.getItem('receiveStreams') || '');
  if (receiveStreams) return receiveStreams;

  if (navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit) {
    return 1;
  }

  return 9;
};

const getResolutionMap = () => {
  // 'default' is the fallback setting in mediaSettingsSlice, so it should always be part of this map
  const resolutionMap: Record<string, Resolution> = {
    default: { height: 720, width: 1280, value: 'default' },
    // '2160p': { height: 2160, width: 3840, value: '2160p' }, // Ultra-High Definition (4K); 3840x2160; 16:9
    '1080p': { height: 1080, width: 1920, value: '1080p' }, // Full High Definition (1080p); 1920x1080; 16:9
    '720p': { height: 720, width: 1280, value: '720p' }, // High Definition (720p); 1280x720; 16:9
    '360p': { height: 360, width: 640, value: '360p' } // Power Saving (360p); 640x360; 16:9
  };
  if (isLowSpecSystemOrSafari) {
    resolutionMap.default = { height: 360, width: 640, value: 'default' };
  }
  return resolutionMap;
};

const resolutionMap = getResolutionMap();

// Remove all the resolutions over 720p for Safari mobile
if (isIosMobile()) {
  const keys = Object.keys(resolutionMap);
  keys.forEach((key) => {
    if (resolutionMap[key].height > 720) {
      delete resolutionMap[key];
    }
  });
}

const getContainerSize = (containerElement: HTMLElement | null) => {
  // height/width aspect in order to express the height of the VideoContainer as a ratio of its width

  if (containerElement) {
    const containerElementHeight = containerElement.clientHeight;
    const containerElementWidth = containerElement.clientWidth;

    return {
      aspect: containerElementHeight / containerElementWidth,
      width: containerElementWidth
    };
  }
  return {
    aspect: 1,
    width: 0
  };
};

const getPipHeight = (aspect: number, containerWidth = 1000) => {
  // calculate the height (inspecting may give an inaccurate result because it may be mid-transition)
  return (getStyleConfig(aspect, containerWidth).height / 100) * containerWidth;
};

const getStreamAspect = (videoElement: HTMLElement | null) => {
  if (videoElement) {
    // videoHeight & videoWidth get the actual stream dimensions unlike offsetHeight, clientHeight, scrollHeight, etc.
    const videoElementHeight = _get(videoElement, 'videoHeight') || defaultVideoHeight;
    const videoElementWidth = _get(videoElement, 'videoWidth') || defaultVideoHeight / defaultVideoAspect;

    return videoElementHeight / videoElementWidth;
  }
  return defaultVideoAspect;
};

const getStyleConfig = (streamAspect = 1, containerWidth = 1000): StyleConfig => {
  // all values are expressed as a percentage of the container's width (yes, even the height)
  const widthPercent = getWidthPercent(containerWidth);
  // landscape
  if (streamAspect <= 1) {
    return {
      height: widthPercent * streamAspect,
      width: widthPercent
    };
  }
  // portrait
  return {
    height: widthPercent,
    width: widthPercent / streamAspect
  };
};

const getWidthPercent = (width: number) => {
  if (width <= 375) return 20;
  if (width >= 1920) return 12;
  // linear interpolation for widths between 375px and 1920px
  return ((12 - 20) * (width - 375)) / (1920 - 375) + 20;
};

export {
  resolutionMap,
  defaultResolutionLabel,
  defaultVideoAspect,
  getContainerSize,
  getMaximumReceiveStreams,
  getPipHeight,
  getStreamAspect,
  getStyleConfig,
  isLowSpecSystemOrSafari
};
