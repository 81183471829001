import React from 'react';
import intl from 'react-intl-universal';
import { hooks } from '@lifesize/clients.sdk';
import { PresentButton, Snackbar } from '@lifesize/clients.mui-components';
import { Box, Tooltip } from '@material-ui/core';
import usePresentation from 'hooks/usePresentation';
import { getRemotePresenter } from 'utils/participantsUtils';
import { getPresentationTooltipText } from 'utils/tooltipUtils';
import { isPresentationEnabled } from 'utils/browserDetection';
import usePresentationRestriction from 'hooks/usePresentationRestriction';

interface Props {
  isHidden?: boolean;
}

export default function Presentation(props: Props) {
  const media = hooks.useMedia();
  const conferenceInfo = hooks.useConferenceInfo();
  const conferenceJoined = conferenceInfo.id !== null;
  const canPresent = !media.remotePresentationStream && isPresentationEnabled();
  const isPresenting = media.localPresentationStream;
  const participants = hooks.useParticipants();
  const { errorMsg, handlePresentation } = usePresentation(isPresenting);
  const { showPresentationRestrictionTooltip, isPresentationRestricted } = usePresentationRestriction();
  const tooltipPopperProps = props.isHidden
    ? { open: false } // forces the tooltip closed when the ButtonBar is idle (hidden)
    : {}; // behave normally

  return (
    <>
      {errorMsg && <Snackbar message={errorMsg} />}
      <Tooltip
        disableHoverListener={!conferenceJoined} // Hide tooltip when call is connecting.
        title={
          canPresent
            ? getPresentationTooltipText(isPresenting, showPresentationRestrictionTooltip)
            : intl.get('nameIsPresenting', { name: getRemotePresenter(participants).name })
        }
        PopperProps={tooltipPopperProps}
      >
        <Box display="flex" className="lsCallControl">
          <PresentButton
            data-testid="shareScreen"
            disabled={!canPresent || !conferenceJoined || isPresentationRestricted}
            onClick={handlePresentation}
            selected={isPresenting}
          />
        </Box>
      </Tooltip>
    </>
  );
}
