import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';
import { clear as clearWhiteboardError } from 'redux/whiteboardSlice';

export default function Whiteboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const whiteboard = useSelector((state: RootState) => state.whiteboard);

  return (
    <Snackbar {...defaultProps} open={!!whiteboard.error} onClose={() => dispatch(clearWhiteboardError())}>
      <SnackbarContent
        data-testid="whiteboardSnackbar"
        className={classes.message}
        aria-describedby="client-snackbar"
        message={whiteboard.error}
      />
    </Snackbar>
  );
}
