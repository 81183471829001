import React from 'react';
import { Typography, Box } from '@material-ui/core';
import ErrorPage from 'layouts/ErrorPage';
import intl from 'react-intl-universal';

const UnexpectedError = () => {
  return (
    <ErrorPage>
      <Box my={1}>
        <Typography variant="h3" color="error">
          {intl.get('unexpectedError')}
        </Typography>
      </Box>
      <Box my={1}>
        <Typography>{intl.get('pleaseTryAgain')}</Typography>
      </Box>
    </ErrorPage>
  );
};

export default UnexpectedError;
