import { guest, signaling } from '@lifesize/clients.sdk';
import Logger from 'js-logger';
import shortid from 'shortid';
import { getReceiveStreams } from './capabilities';

let sessionEstablished = false;

export interface StartCallConfig {
  email: string;
  isOnline?: boolean;
  username: string;
}

export const startCall = async (extension: string, config: StartCallConfig) => {
  try {
    const displayName = config.username || `Guest ${shortid.generate()}`;
    const domain = process.env.REACT_APP_LOGIN_DOMAIN || '';

    if (!config.isOnline) {
      if (!sessionEstablished) {
        await guest.establishNewSession(
          {
            displayName,
            domain,
            email: config.email || displayName,
            extension
          },
          {
            url: `wss://nucleusproxy.${domain}`,
            realm: 'lifesize'
          }
        );
        sessionEstablished = true;
      }

      const hasSFUMultiStream = false; //!!signaling.selectors.accountSettings()?.sfuSettings?.sfuMultiStream;

      // TODO: remove when SFU layouts are considered production ready
      signaling.configure({ receiveStreams: hasSFUMultiStream ? getReceiveStreams() : 1 });
    }

    await signaling.startCall(extension);
  } catch (error) {
    Logger.warn(`Couldn't start call`, error);
  }
};
