import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { hooks } from '@lifesize/clients.sdk';
import { Badge, colors, ParticipantsButton, SwipeableDrawer } from '@lifesize/clients.mui-components';
import { Box, makeStyles, Theme, Tooltip } from '@material-ui/core';
import ParticipantsList from './ParticipantsList';
import useHasRaisedHandFeature from 'hooks/useHasRaisedHandFeature';
import { RootState } from 'redux/rootReducer';
import { toggleOpen } from 'redux/participantsDrawerSlice';
import { notificationDisplayType, NotificationObj, tooltipNotificationIds } from 'redux/tooltipNotificationSlice';
import { DrawerSide } from 'types/pages';
import { isIosMobile } from 'utils/browserDetection';

interface StyleConfig {
  isNotification: boolean;
}

const drawerDirection: DrawerSide = 'left';
const iOS = isIosMobile();

const useStyles = makeStyles(() => ({
  btnWrapper: {
    position: 'relative'
  },
  badge: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const useStylesNotification = makeStyles((theme: Theme) => ({
  tooltip: (config: StyleConfig) =>
    config.isNotification
      ? {
          backgroundColor: theme.palette.background.default,
          color: colors.black
        }
      : {}
}));

interface Props {
  isHidden?: boolean;
  showButton: boolean;
}

const ParticipantsDrawer = (props: Props) => {
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.participantsDrawer.open);
  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    !!event && event.stopPropagation();
    if (
      !!event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(toggleOpen());
  };
  const hasRaisedHandFeature = useHasRaisedHandFeature();
  const notificationObject: NotificationObj | undefined = useSelector(
    (state: RootState) => state.tooltipNotification[tooltipNotificationIds.generalMessage]
  );
  const notification =
    hasRaisedHandFeature && !!notificationObject && notificationObject.displayType === notificationDisplayType.tooltip
      ? notificationObject
      : undefined;
  const raisedHandsCount = hooks.useParticipantsWithRaisedHands().length;
  const classes = useStyles();
  const tooltipClasses = useStylesNotification({ isNotification: !!notification });
  const tooltipPopperProps = !!notification
    ? { open: true } // forces the tooltip open when there is a notification
    : props.isHidden
    ? { open: false } // forces the tooltip closed when the ButtonBar is idle (hidden)
    : {}; // behave normally
  const tooptipTitle = !!notification ? intl.get(notification.id, notification.values) : intl.get('participantList');

  return (
    <React.Fragment>
      {props.showButton && (
        <Tooltip classes={tooltipClasses} title={tooptipTitle} PopperProps={tooltipPopperProps}>
          <Box display="flex" className={`${classes.btnWrapper} lsCallControl`}>
            <ParticipantsButton
              data-testid="showParticipants"
              disabled={false}
              onClick={(event: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(event)}
              selected={false}
            />
            {hasRaisedHandFeature && !!raisedHandsCount && <Badge classes={classes.badge} label={raisedHandsCount} />}
          </Box>
        </Tooltip>
      )}
      <SwipeableDrawer
        anchor={drawerDirection}
        closeButtonProps={{
          dataTestId: 'closeParticipants',
          label: intl.get('close'),
          closeDrawer: (event: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(event)
        }}
        isIos={iOS}
        onClose={(event: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(event)}
        onOpen={(event: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(event)}
        open={open}
      >
        <div
          role="presentation"
          onClick={(event: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(event)}
          onKeyDown={(event: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(event)}
        >
          <ParticipantsList />
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default ParticipantsDrawer;
