import { makeStyles, Box, Typography, Theme } from '@material-ui/core';
import React from 'react';
import intl from 'react-intl-universal';
import { getPrivacyLink, getTosLink } from 'utils/localeUtil';

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography className={classes.terms} data-testid="termsCondition">
        {intl.getHTML('terms', {
          privacyLink: getPrivacyLink(),
          termsLink: getTosLink()
        })}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    alignItems: 'center',
    display: 'flex',
    flex: '0',
    justifyContent: 'center',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  terms: {
    fontSize: '1.1rem'
  }
}));

export default Footer;
