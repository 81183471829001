import gql from 'graphql-tag';

export default gql`
  query chatMessages($uuid: String!) {
    chatMessages(uuid: $uuid) {
      cursor
      messages {
        uuid
        createdAt
        activityType
        ... on ChatMessage {
          message
          member {
            node {
              uuid
              displayName
            }
          }
        }
        ... on ChatEvent {
          member {
            node {
              displayName
              uuid
            }
          }
        }
      }
    }
  }
`;
