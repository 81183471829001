import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import clsx from 'clsx';
import { colors } from '@lifesize/clients.mui-components';
import { ReactComponent as MicMuted } from '@lifesize/ux-assets/vectors/svgIcons/mic-off.svg';
import { ReactComponent as MicEnabled } from '@lifesize/ux-assets/vectors/svgIcons/mic.svg';
import { ReactComponent as SharingScreen } from '@lifesize/ux-assets/vectors/svgIcons/share-screen.svg';
import { ReactComponent as HandRaised } from '@lifesize/ux-assets/vectors/svgIcons/hand.svg';
import { hooks } from '@lifesize/clients.sdk';
import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import useHasRaisedHandFeature from 'hooks/useHasRaisedHandFeature';
import { ParticipantInterface } from './Interfaces';

interface Props {
  participant: ParticipantInterface;
  index: number;
}

function getDisplayName(name: string, isSelf: boolean, isModerator: boolean) {
  const stringArray = [name];
  if (isSelf) stringArray.push(intl.get('participantMe'));
  if (isModerator) stringArray.push(intl.get('participantListModerator'));
  return stringArray.join(' ');
}

const ParticipantListItem = (props: Props) => {
  const classes = useStyles(props);
  const { participant, index } = props;
  const { name, isSelf } = participant;
  const isModerator = hooks.useIsModerator(participant.uuid);
  const displayName = useMemo(() => getDisplayName(name, !!isSelf, isModerator), [name, isSelf, isModerator]);
  const isMuted = participant.localAudioMuteState || participant.serverAudioMuteState;
  const hasRaisedHandFeature = useHasRaisedHandFeature();

  const listItemClasses = clsx(classes.listItem, {
    [classes.isSelf]: participant.isSelf
  });

  return (
    <ListItem
      data-testid="participantItem"
      key={`${participant.name}${index}`}
      className={listItemClasses}
      onClick={(event: React.KeyboardEvent | React.MouseEvent) => {
        !!event && event.stopPropagation();
      }}
    >
      <ListItemText data-testid="participantName" primary={displayName} />
      {hasRaisedHandFeature && !!participant.handRaised && (
        <ListItemIcon className={classes.iconOverride}>
          <HandRaised data-testid="participantHandRaised" className={clsx(classes.icon, classes.raisedHand)} />
        </ListItemIcon>
      )}
      {participant.isPresenting && (
        <ListItemIcon className={classes.iconOverride}>
          <SharingScreen data-testid="participantSharing" className={clsx(classes.icon, classes.sharing)} />
        </ListItemIcon>
      )}
      <ListItemIcon className={classes.iconOverride}>
        {isMuted ? (
          <MicMuted data-testid="participantMicMuted" className={clsx(classes.icon, classes.audio, classes.disabled)} />
        ) : (
          <MicEnabled data-testid="participantMicUnmuted" className={clsx(classes.icon, classes.audio)} />
        )}
      </ListItemIcon>
    </ListItem>
  );
};

const useStyles = makeStyles<any, any>(() => ({
  iconOverride: {
    minWidth: 27
  },
  icon: {
    height: 24,
    width: 'auto'
  },
  listItem: {
    '& span': {
      fontWeight: 'normal'
    }
  },
  isSelf: {
    '& span': {
      fontWeight: 500
    }
  },
  list: {
    minWidth: 375,
    '& span': {
      color: colors.lightSlateGray,
      fontSize: 14
    }
  },
  audio: {
    color: colors.charcoal
  },
  disabled: {
    color: colors.chiliPepper
  },
  raisedHand: {
    color: colors.mint
  },
  sharing: {
    color: colors.mint
  }
}));

export default ParticipantListItem;
