import { resolutionMap } from './videoResolution';
import { each as _each, get as _get } from 'lodash';
import Logger from 'js-logger';

export type AudioType = 'computer' | 'phone';

export const audioOutputOptions = ['computer', 'phone'];

export type MediaConfig = {
  audio: AudioType;
  camera: string;
  localAudioMuted: boolean;
  localVideoMuted: boolean;
  microphone: string;
  resolution: string;
  speakers: string;
};

export const persistentKeys = ['camera', 'microphone', 'resolution', 'speakers'];

export const getDevicesByKind = (devices: MediaDeviceInfo[], kind: MediaDeviceKind) => {
  if (devices && devices.length) {
    const filterDevices = devices.filter((device: any) => device.label && device.kind === kind);
    const deviceList = filterDevices.map((device) => {
      return {
        label: device.label,
        value: device.deviceId
      };
    });
    if (deviceList) {
      return deviceList;
    }
  }
  return [];
};

export const getConstraints = (config: MediaConfig): MediaStreamConstraints => {
  const height = resolutionMap[config.resolution]
    ? resolutionMap[config.resolution].height
    : resolutionMap.default.height;
  const width = resolutionMap[config.resolution] ? resolutionMap[config.resolution].width : resolutionMap.default.width;

  // add the audio property as a boolean if we don't have a specific deviceId
  const audio = config.microphone ? { deviceId: config.microphone } : true;
  const video = {
    height: {
      ideal: height
    },
    width: {
      ideal: width
    }
  };

  const constraints: any = {
    audio,
    video
  };

  // only include a "deviceId" property if we have a deviceId to request.
  if (config.camera) {
    constraints.video['deviceId'] = config.camera;
  }

  return constraints;
};

export const setAudioOutput = (deviceId: string) => {
  const videoElts = document.getElementsByClassName('lsVideoElt');
  // HTMLCollectionOf<HTMLMediaElement> ?
  _each(videoElts, (video) => {
    if (_get(video, 'setSinkId') && _get(video, 'sinkId') !== deviceId) {
      video
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        .setSinkId(deviceId)
        .then(() => {
          Logger.log(`Success, audio output device attached: ${deviceId}`);
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          Logger.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          // audioOutputSelect.selectedIndex = 0;
        });
    } else {
      Logger.warn('Browser does not support output device selection.');
    }
  });
};
