import { useState, useEffect } from 'react';
import { hooks } from '@lifesize/clients.sdk';

const useVideoDeviceChanged = (): boolean => {
  const [deviceChanged, setDeviceChanged] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const videoDevice = hooks.useVideoDevice();
  const videoDeviceId = videoDevice?.deviceId;
  useEffect(() => {
    if (videoDeviceId !== undefined) {
      setDeviceId(videoDeviceId);
    }
  }, [videoDeviceId]);
  useEffect(() => {
    if (deviceId !== null && videoDeviceId !== undefined) {
      setDeviceChanged(deviceId !== videoDeviceId);
    }
  }, [deviceId, videoDeviceId]);
  return deviceChanged;
};

export default useVideoDeviceChanged;
