import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { colors } from '@lifesize/clients.mui-components';
import { Link, makeStyles, Snackbar } from '@material-ui/core';
import { acceptCookies } from 'redux/complianceSlice';
import { storeCookieAcceptance } from 'utils/localStorage/compliance';
import { RootState } from 'redux/rootReducer';
import { getCookieLink } from 'utils/localeUtil';

export default function CookieBanner() {
  const classes = messageStyles();
  const dispatch = useDispatch();
  const open = !useSelector((state: RootState) => state.compliance.cookies);

  const dismissCookieMessage = () => {
    dispatch(acceptCookies(storeCookieAcceptance()));
  };

  const getCookieMessage = () => {
    return (
      <span data-testid="cookieBanner">
        {intl.get('cookieBanner')}
        &nbsp;
        <Link
          data-testid="cookieInfo"
          className={classes.link}
          color="inherit"
          underline="always"
          target="_blank"
          href={getCookieLink()}
          rel="noopener"
        >
          {intl.get('linkFindOutMore')}
        </Link>
        &nbsp;
        <Link
          data-testid="dismissCookieBanner"
          className={classes.link}
          color="inherit"
          underline="always"
          onClick={dismissCookieMessage}
        >
          {intl.get('linkDismiss')}
        </Link>
      </span>
    );
  };

  return (
    <Snackbar
      className={classes.container}
      color="info"
      ContentProps={{ className: classes.content }}
      message={getCookieMessage()}
      open={open}
    />
  );
}

export const messageStyles = makeStyles(() => ({
  container: {
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    fontSize: '1.4rem !important'
  },
  content: {
    backgroundColor: colors.lifesize,
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  link: {
    color: 'inherit',
    cursor: 'pointer',
    '&:active, &:focus, &:hover, &:visited': {
      color: 'inherit',
      textDecoration: 'underline'
    }
  }
}));
