import React, { useEffect, useState } from 'react';
import { DropDown, MenuItem } from '@lifesize/clients.mui-components';
import intl from 'react-intl-universal';

export interface Item {
  icon?: string;
  label: string;
  value: string;
}

interface Props {
  handleChange: (value: string) => void;
  items: Item[] | Array<any>;
  label: string | object;
  value?: string;
}

const SettingDropdown = (props: Props) => {
  const [error, setError] = useState('');
  const { items, value, handleChange } = props;
  const hasItems = !!items?.length;

  const options = hasItems ? items : [{ label: intl.get('mediaDeviceUnavailable'), value: value }];

  const onChange = (event: React.ChangeEvent<{ value: any }>) => {
    const { value: targetValue } = event.target;
    if (targetValue && targetValue !== value) {
      handleChange(targetValue);
    }
  };

  useEffect(() => {
    if (hasItems) {
      setError('');
    } else {
      setError(intl.get('mediaDeviceUnavailableForm'));
    }
  }, [hasItems]);

  return (
    <DropDown
      data-testid="settings-dropdown"
      errorString={error}
      fullWidth
      inputProps={{ shrink: 'false', 'data-testid': 'settings-dropdown-input' }}
      label={props.label}
      onChange={onChange}
      value={value}
    >
      {options.map((item, index) => (
        <MenuItem key={index} value={item.value} data-testid="dropdownOption">
          {item.label}
        </MenuItem>
      ))}
    </DropDown>
  );
};

export default SettingDropdown;
