import { createSlice } from '@reduxjs/toolkit';
import analytics, { GainsightEvents } from 'utils/analytics';

export interface SelfViewState {
  on: boolean;
  isClicked: boolean;
}

const initialState: SelfViewState = {
  on: true,
  isClicked: false
};

const selfViewSlice = createSlice({
  name: 'selfView',
  initialState,
  reducers: {
    toggleSelfView(state) {
      state.on = !state.on;
      state.isClicked = true;
      analytics.track(GainsightEvents.PictureInPicture, { isEnabled: state.on });
    },
    resetSelfViewClick() {
      return initialState;
    }
  }
});

export const { toggleSelfView, resetSelfViewClick } = selfViewSlice.actions;
export default selfViewSlice.reducer;
