import shortid from 'shortid';

const gainsightIdKey = 'gainsightId';

type GainsightMetadata = Record<string, string | number | boolean>;

enum GainsightEvents {
  PictureInPicture = 'pip',
  Call = 'call'
}

function internalInit(n: any, t: any, a: any, e: any) {
  const i = 'aptrinsic';
  n[i] =
    n[i] ||
    function() {
      (n[i].q = n[i].q || []).push(arguments);
    };
  n[i].p = e;
  const r = t.createElement('script');
  r.async = !0;
  r.src = a + '?a=' + e;
  const c = t.getElementsByTagName('script')[0];
  c.parentNode.insertBefore(r, c);
}

function init() {
  internalInit(
    window,
    document,
    'https://web-sdk.aptrinsic.com/api/aptrinsic.js',
    process.env.REACT_APP_GAINSIGHT_TAG_KEY
  );
}

function getIdentifier() {
  let id = localStorage.getItem(gainsightIdKey);
  if (!id) {
    id = shortid.generate();
    localStorage.setItem(gainsightIdKey, id);
  }
  return id;
}

function identify(name?: string, email?: string) {
  const id = email || getIdentifier();
  aptrinsic('identify', {
    id,
    email,
    name,
    role: 'Guest'
  });
  return id;
}

function track(event: GainsightEvents, metadata: GainsightMetadata) {
  aptrinsic('track', event, metadata);
}

export { GainsightEvents };
export default { identify, init, track };
