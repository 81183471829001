import _get from 'lodash/get';

export enum errorType {
  CANNOT_OPTIMIZE = 'CANNOT_OPTIMIZE',
  CANNOT_LOAD_IMAGE = 'CANNOT_LOAD_IMAGE',
  FILE_TYPE_NOT_SUPPORTED = 'FILE_TYPE_NOT_SUPPORTED'
}

export const getConstrainedImg = (sourceImage: string, width: number, height: number) =>
  new Promise((resolve: (value?: string) => void, reject) => {
    const image = new Image(); // used to load image into the canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    image.onload = () => {
      try {
        const targetAspect = width / height;
        const sourceAspect = image.width / image.height;
        const sourceCoords = { sx: 0, sy: 0, sWidth: image.width, sHeight: image.height };

        // set the target canvas dimensions
        canvas.height = height;
        canvas.width = width;

        // crop the sourceImage: calculate the new sourceCoords to the targetAspect ratio, expressed in the full size of the source
        if (targetAspect > sourceAspect) {
          // incoming image is too tall: crop the top & bottom
          sourceCoords.sHeight = image.width / targetAspect;
          sourceCoords.sy = (image.height - image.width / targetAspect) / 2;
        } else {
          // incoming image is too wide (or just right): crop the sides (or none)
          sourceCoords.sWidth = image.height * targetAspect;
          sourceCoords.sx = (image.width - image.height * targetAspect) / 2;
        }
        // resize the sourceImage: draw the sourceImage (which has been cropped above) onto the target
        // @ts-ignore
        context.drawImage(
          image,
          sourceCoords.sx,
          sourceCoords.sy,
          sourceCoords.sWidth,
          sourceCoords.sHeight,
          0,
          0,
          width,
          height
        );

        resolve(canvas.toDataURL('image/jpeg', 0.92)); // express as a jpeg for smaller size (0.92 is the default quality, 0: worst, 1: best)
      } catch (error) {
        // the image is likely doomed to fail further down the line, but if we cannot crop/resize then reject with the sourceImage (already a dataURL)
        reject({ type: errorType.CANNOT_OPTIMIZE, error, dataUrl: sourceImage });
      }
    };
    image.onerror = (error) => {
      // possibly a corrupt image (or one that exceeds the memory allocation)
      reject({ type: errorType.CANNOT_LOAD_IMAGE, error });
    };

    image.src = sourceImage;
  });

export const isSupportedFile = (newFile: File) => {
  return newFile && /^(image\/)(bmp)?(gif)?(jpeg)?(png)?(tiff)?$/g.test(newFile.type);
};

export const onDragOver = (event: React.DragEvent<HTMLElement>) => {
  event.stopPropagation();
  event.preventDefault();
  // style the drag-and-drop as a 'copy file' operation
  if (event.dataTransfer) {
    // for typescript compliance
    event.dataTransfer.dropEffect = 'copy';
  }
};

export const onDrop = (event: React.DragEvent<HTMLElement>, onImageLoaded: Function) => {
  // stop the browser's default behavior (navigating away to show the file), and run this code instead
  event.stopPropagation();
  event.preventDefault();
  const newFile = _get(event, 'dataTransfer.files[0]');
  if (isSupportedFile(newFile)) {
    onImageSelected(newFile, onImageLoaded);
  } else {
    onImageLoaded({ error: { type: errorType.FILE_TYPE_NOT_SUPPORTED } });
  }
};

export const onImageSelected = (imgFile: File, onImageLoaded: Function) => {
  const imgReader = new FileReader();
  imgReader.onload = (e) => {
    // resize large images (limited to 640x360 (16:9))
    // @ts-ignore
    getConstrainedImg(e.target?.result, 640, 360)
      .then((dataUrl: string) => {
        onImageLoaded({ dataUrl });
      })
      .catch((error: any) => {
        onImageLoaded({ dataUrl: error.dataUrl, error });
      });
  };
  imgReader.readAsDataURL(imgFile);
};
