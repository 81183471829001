import React, { useCallback, useMemo } from 'react';
import { hooks, media } from '@lifesize/clients.sdk';
import { VideoButton as MuiVideoButton } from '@lifesize/clients.mui-components';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { getVideoTooltipText } from 'utils/tooltipUtils';
import { getDevicesByKind } from 'utils/devicesUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setPermissionsSnackbarOpen } from 'redux/snackbarsSlice';
import { set as updateSettings } from 'redux/mediaSettingsSlice';
import { RootState } from 'redux/rootReducer';
import { vbbManager } from 'utils/vbbManager';

interface Props {
  disabled?: boolean;
  isHidden?: boolean;
}

const VideoButton = (props: Props) => {
  const dispatch = useDispatch();
  const classes = getStyles({});
  const devices = hooks.useDevices();
  const videoInputDevices = useMemo(() => getDevicesByKind(devices, 'videoinput'), [devices]);
  const { enabled: vbbEnabled, selection: bgSelection } = useSelector((state: RootState) => state.vbbSettings);
  const { localVideoMuted } = hooks.useMedia();
  const tooltipPopperProps =
    props.isHidden || props.disabled
      ? { open: false } // forces the tooltip closed when the ButtonBar is idle (hidden) or the button is disabled (call is connecting)
      : {}; // behave normally
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);

  const onClickHandler = useCallback(async () => {
    if (!videoInputDevices.length) {
      dispatch(setPermissionsSnackbarOpen({ open: true }));
    } else {
      const newMuteState = !localVideoMuted;

      // init VBB beforehand if we just unmuted and VBB enabled
      if (!newMuteState && vbbEnabled) {
        await vbbManager.initializeVbb();
      }

      // Actually unmute
      dispatch(updateSettings({ localVideoMuted: newMuteState }));
      await media.setVideoMute(newMuteState);

      // Apply VBB changes if applicable
      if (vbbEnabled) {
        await vbbManager.handleVideoMuteUnmute(dispatch, newMuteState, vbbEnabled, bgSelection, mediaSettings);
      }
    }
  }, [videoInputDevices, localVideoMuted, vbbEnabled, mediaSettings, bgSelection, dispatch]);

  return (
    <Tooltip title={getVideoTooltipText(localVideoMuted)} PopperProps={tooltipPopperProps}>
      <Box display="flex" className="lsCallControl">
        <MuiVideoButton
          className={classes.videoButton}
          disabled={!!props.disabled}
          data-testid={'video-button'}
          onClick={onClickHandler}
          selected={localVideoMuted || !videoInputDevices.length}
        />
      </Box>
    </Tooltip>
  );
};

const getStyles = makeStyles(() => ({
  videoButton: {
    '& svg': {
      height: 40,
      width: 'auto'
    }
  }
}));

export default VideoButton;
