import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { navigateToTrialPage } from 'utils/redirectUtils';
import { EndCallButton } from '@lifesize/clients.mui-components';
import { hooks, signaling, ObservableEvent } from '@lifesize/clients.sdk';
import { Box, Tooltip } from '@material-ui/core';
import {
  clearTipNotification,
  notificationDisplayType,
  notificationDuration,
  setTipNotification,
  tooltipNotificationIds
} from 'redux/tooltipNotificationSlice';

interface Props {
  isHidden?: boolean;
}

let notificationTimeout: ReturnType<typeof setTimeout>;

const EndCall = (props: Props) => {
  const dispatch = useDispatch();
  const currentUser = hooks.useUserInfo();
  const tooltipPopperProps = props.isHidden
    ? { open: false } // forces the tooltip closed when the ButtonBar is idle (hidden)
    : {}; // behave normally

  const onAllHandsLowered = useCallback(
    (e: any) => {
      // no payload
      clearTimeout(notificationTimeout);
      dispatch(
        setTipNotification({
          tooltipId: tooltipNotificationIds.personalMessage,
          messageObj: {
            id: 'notification.tooltip.allHandsLoweredByModerator', // "A moderator has lowered all hands"
            displayType: props.isHidden ? notificationDisplayType.snackbar : notificationDisplayType.tooltip
          }
        })
      );
      notificationTimeout = setTimeout(() => {
        dispatch(clearTipNotification({ tooltipId: tooltipNotificationIds.personalMessage }));
      }, notificationDuration);
    },
    [dispatch, props.isHidden]
  );

  const onHandLowered = useCallback(
    (e: any) => {
      // payload: conferenceId, initiatedBy (object), participant (object)
      if (currentUser?.uuid === e?.participant?.uuid) {
        // this applies to me
        clearTimeout(notificationTimeout);
        if (currentUser?.uuid === e?.initiatedBy?.uuid) {
          dispatch(
            setTipNotification({
              // I lowered my own hand
              tooltipId: tooltipNotificationIds.personalMessage,
              messageObj: {
                id: 'notification.tooltip.ownHandLoweredBySelf', // "Your hand has been lowered"
                displayType: props.isHidden ? notificationDisplayType.snackbar : notificationDisplayType.tooltip
              }
            })
          );
        } else {
          dispatch(
            setTipNotification({
              // my hand was lowered by the moderator
              tooltipId: tooltipNotificationIds.personalMessage,
              messageObj: {
                id: 'notification.tooltip.ownHandLoweredByModerator', // "A moderator has lowered your hand"
                displayType: props.isHidden ? notificationDisplayType.snackbar : notificationDisplayType.tooltip,
                values: { name: e.initiatedBy.displayName }
              }
            })
          );
        }
        notificationTimeout = setTimeout(() => {
          dispatch(clearTipNotification({ tooltipId: tooltipNotificationIds.personalMessage }));
        }, notificationDuration);
      }
    },
    [currentUser, dispatch, props.isHidden]
  );

  const onHandRaised = useCallback(
    (e: any) => {
      // payload: conferenceId, participant (object)
      if (currentUser.uuid === e.participant.uuid) {
        // this applies to me
        clearTimeout(notificationTimeout);
        dispatch(
          setTipNotification({
            tooltipId: tooltipNotificationIds.personalMessage,
            messageObj: {
              id: 'notification.tooltip.ownHandRaisedBySelf', // "Your hand has been raised"
              displayType: props.isHidden ? notificationDisplayType.snackbar : notificationDisplayType.tooltip
            }
          })
        );
        notificationTimeout = setTimeout(() => {
          dispatch(clearTipNotification({ tooltipId: tooltipNotificationIds.personalMessage }));
        }, notificationDuration);
      } else {
        // this applies to anyone other than myself
        clearTimeout(notificationTimeout);
        dispatch(
          setTipNotification({
            tooltipId: tooltipNotificationIds.generalMessage,
            messageObj: {
              id: 'notification.tooltip.otherHandRaised', // "{name} has raised their hand"
              displayType: props.isHidden ? notificationDisplayType.snackbar : notificationDisplayType.tooltip,
              values: { name: e?.participant?.name }
            }
          })
        );
        notificationTimeout = setTimeout(() => {
          dispatch(clearTipNotification({ tooltipId: tooltipNotificationIds.generalMessage }));
        }, notificationDuration);
      }
    },
    [currentUser, dispatch, props.isHidden]
  );

  hooks.useObservableEvent(ObservableEvent.AllHandsLowered, onAllHandsLowered);
  hooks.useObservableEvent(ObservableEvent.HandLowered, onHandLowered);
  hooks.useObservableEvent(ObservableEvent.HandRaised, onHandRaised);

  return (
    <Tooltip title={intl.get('leave')} PopperProps={tooltipPopperProps}>
      <Box display="flex" className="lsCallControl">
        <EndCallButton
          data-testid="end-call-button"
          onClick={async () => {
            await signaling.endCall();
            navigateToTrialPage();
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default EndCall;
