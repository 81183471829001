import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';

import { RootState } from 'redux/rootReducer';
import { setPermissionsSnackbarOpen } from 'redux/snackbarsSlice';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';

export default function PermissionsSnackbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.snackbars.permissionsSnackbarOpen);
  return (
    <Snackbar {...defaultProps} open={open} onClose={() => dispatch(setPermissionsSnackbarOpen({ open: false }))}>
      <SnackbarContent
        className={classes.message}
        aria-describedby="client-snackbar"
        message={intl.get('permissions')}
      />
    </Snackbar>
  );
}
