import './index.css';
import 'webrtc-adapter';
import { ApolloProvider } from '@apollo/react-hooks';
import getClient from './gql/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Logger from 'js-logger';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';
import UnexpectedError from 'components/UnexpectedError';
import AppLayout from 'layouts/AppLayout';
import store from 'redux/store';
import support from 'support';
import { isIosMobile } from 'utils/browserDetection';
import { init } from 'utils/initUtils';
import { getCurrentLanguageId, loadLocales } from 'utils/localeUtil';
import { SplashLayout } from 'layouts/SplashLayout';
import '@lifesize/ux-assets/styles/_fontFace.scss';

Logger.useDefaults();
Logger.setLevel(Logger.DEBUG);

Logger.info(`Lifesize Guest Flow Application (Build ${support.version})`);

declare global {
  interface Window {
    newVersionFound: boolean;
  }
}

window.newVersionFound = false;

serviceWorker.register({
  onUpdate: async (registration) => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      Logger.log('New service worker found: update and reload');
      await registration.unregister();
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      window.newVersionFound = true;
    }
  }
});

const layoutToRender = (error?: any) => {
  if (error) {
    return <UnexpectedError />;
  }
  if (isIosMobile() && !window.location.href.includes('bypassSplash=true')) {
    return <SplashLayout />;
  }
  return <AppLayout />;
};

const renderApp = (error?: any) => {
  const content = layoutToRender(error);
  const ErrorBoundary = support.getReactErrorBoundary(React);

  ReactDOM.render(
    <ErrorBoundary>
      <ApolloProvider client={getClient()}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {content}
          </ThemeProvider>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

const initAndRender = async () => {
  await support.init();
  try {
    init(store);
    await loadLocales(getCurrentLanguageId());
    renderApp();
  } catch (e) {
    Logger.warn(e);
    renderApp(e);
  }
};

initAndRender();
