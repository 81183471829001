import React, { Suspense, useEffect } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import SignUp from 'components/SignUp';
import UserInformationForm from './UserInformationForm';
import LoadingAnimation from 'components/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { fetchExtensionData } from 'redux/extension';

const NoExtension = React.lazy(() => import('components/NoExtension'));

const JoinForm = () => {
  const classes = desktopStyles();
  const extensionState = useSelector((state: RootState) => state.extension);
  const dispatch = useDispatch();

  useEffect(() => {
    if (extensionState.value) {
      dispatch(fetchExtensionData(extensionState.value));
    }
  }, [extensionState.value, dispatch]);

  return (
    <Box className={classes.content}>
      {(extensionState.hasChecked && !extensionState.valid) || !extensionState.value ? (
        <Suspense fallback={<LoadingAnimation show={true} />}>
          <NoExtension />
        </Suspense>
      ) : (
        <UserInformationForm />
      )}
      <SignUp />
    </Box>
  );
};

const desktopStyles = makeStyles((theme: Theme) => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    '& button': {
      maxWidth: '500px',
      width: '100%'
    }
  }
}));

export default JoinForm;
