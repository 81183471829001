import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';

import { RootState } from 'redux/rootReducer';
import { setPresentationErrorSnackbarOpen } from 'redux/snackbarsSlice';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';

export default function PresentationError() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.snackbars.presentationErrorSnackbarOpen);
  const message = intl
    .get('screenShareBlockedByBrowser')
    .d('Screen sharing is blocked by browser. Please rejoin the call and allow screen sharing.');
  return (
    <Snackbar {...defaultProps} open={open} onClose={() => dispatch(setPresentationErrorSnackbarOpen({ open: false }))}>
      <SnackbarContent className={classes.message} aria-describedby="client-snackbar" message={message} />
    </Snackbar>
  );
}
