import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { hooks } from '@lifesize/clients.sdk';
import { colors } from '@lifesize/clients.mui-components';
import { ReactComponent as HandIcon } from '@lifesize/ux-assets/vectors/svgIcons/hand.svg';
import { Box, Divider, List, ListItem, ListItemText, makeStyles, SvgIcon, Theme, Tooltip } from '@material-ui/core';
import useHasRaisedHandFeature from 'hooks/useHasRaisedHandFeature';
import ParticipantListItem from './ParticipantListItem';
import RemoteModeratorListItem from './RemoteModeratorListItem';
import { ParticipantInterface, RemoteModeratorInterface } from './Interfaces';

enum filterType {
  none = 'none',
  raisedHands = 'raisedHands'
}

const ParticipantsList = () => {
  const classes = useStyles();
  const hasRaisedHandFeature = useHasRaisedHandFeature();
  const participantsAll = hooks.useParticipants();
  const participantsWithRaisedHands = hooks.useParticipantsWithRaisedHands();
  const raisedHandsCount = participantsWithRaisedHands.length;
  const remoteModerators = hooks.useRemoteModerators();
  /* note: call-signaling populates displayName in the useConference hook for
   * - VMRs with any # of participants
   * - ADHOC conferences with only two participants (includes icons)
   * - otherwise we provide a fallback here */
  const meetingName = hooks.useConferenceDisplayName() || intl.get('conference');
  const [filter, setFilter] = useState(filterType.none);
  const participants =
    filter === filterType.none || !hasRaisedHandFeature ? participantsAll : participantsWithRaisedHands;

  return (
    <Box
      className={classes.listContainer}
      mt={2}
      onClick={(event: React.KeyboardEvent | React.MouseEvent) => {
        !!event && event.stopPropagation();
      }}
    >
      {meetingName.length > 16 ? (
        <Tooltip title={meetingName}>
          <h2 data-testid="meetingName" className={classes.meetingName}>
            {meetingName}
          </h2>
        </Tooltip>
      ) : (
        <h2 data-testid="meetingName" className={classes.meetingName}>
          {meetingName}
        </h2>
      )}
      {hasRaisedHandFeature && (
        <div className={classes.filterContainer}>
          <button
            data-testid="allParticipants"
            disabled={filter === filterType.none}
            onClick={() => setFilter(filterType.none)}
          >
            {intl.get('participantList.filter.all')}
          </button>
          <button
            data-testid="handRaisedParticipants"
            disabled={filter === filterType.raisedHands}
            onClick={() => setFilter(filterType.raisedHands)}
          >
            <SvgIcon component={HandIcon} className={!!raisedHandsCount ? 'raisedHands' : ''} />
            {intl.get('participantList.filter.raisedHands', { handsRaised: raisedHandsCount })}
          </button>
        </div>
      )}
      <List className={classes.list}>
        <ListItem key="count">
          <ListItemText
            data-testid="participantsCount"
            className={classes.countLabel}
            primary={intl.get('participantsLength', { length: participants.length })}
          />
        </ListItem>
        <div>
          <Divider variant="middle" />
        </div>
        {participants.map((participant: ParticipantInterface, index: number) => (
          <ParticipantListItem participant={participant} key={`${participant.name}${index}`} index={index} />
        ))}
        {remoteModerators.map((moderator: RemoteModeratorInterface, index: number) => (
          <RemoteModeratorListItem moderator={moderator} key={`${moderator.uuid}${index}`} index={index} />
        ))}
      </List>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    backgroundColor: colors.nimbusCloud
  },
  list: {
    width: 320, // 320 is the screen width for the smallest supported device (Android One)
    '& span': {
      color: colors.lightSlateGray,
      fontSize: 14
    }
  },
  listContainer: {
    width: 320
  },
  meetingName: {
    padding: `0 ${theme.spacing(3) + 40}px`, // leave room for the 40px close button (and its margin from the edge)
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  countLabel: {
    '& span': {
      color: colors.stormcloud,
      fontSize: 18,
      fontWeight: 500
    }
  },
  filterContainer: {
    alignContent: 'stretch',
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,

    '&>button': {
      alignItems: 'center',
      backgroundColor: colors.white,
      borderRadius: 15,
      border: `1px solid ${colors.stormcloud}`,
      color: colors.stormcloud,
      cursor: 'pointer',
      display: 'flex',
      flex: '1 0 auto',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      justifyContent: 'center',
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
      maxWidth: `calc(100% - ${theme.spacing(1)}px)`, // wraps long labels (and accounts for right margin)
      minHeight: 24,
      padding: '3px 10px 2px',

      '&>svg': {
        flex: '0 0 auto',
        height: 24,
        margin: '-3px 6px -2px 0',
        width: 'auto',

        '&.raisedHands': {
          color: colors.mint
        }
      },

      '&>span': {
        textAlign: 'left'
      },

      '&:disabled': {
        backgroundColor: colors.lifesize,
        borderColor: colors.lifesize,
        color: colors.white,
        cursor: 'default'
      }
    }
  }
}));

export default ParticipantsList;
