import Bowser from 'bowser';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';

/** Cached instance of bowser util for re-use */
const getBrowser = _memoize(() => {
  const userAgent = _get(navigator, 'userAgent');
  return Bowser.getParser(userAgent);
});

const getOsInfo = _memoize(() => {
  const osVersion = getBrowser().getOS();
  const versionParts = osVersion.version ? osVersion.version.split('.') : [];
  return {
    name: osVersion.name ? osVersion.name.toLowerCase() : '',
    versionName: osVersion.versionName || '',
    major: versionParts && versionParts.length ? parseInt(versionParts[0], 10) : 0,
    minor: versionParts && versionParts.length > 1 ? parseInt(versionParts[1], 10) : 0
  };
});

const getBrowserInfo = _memoize(() => {
  const browserVersion = getBrowser().getBrowser();
  const versionParts = browserVersion.version ? browserVersion.version.split('.') : [];
  return {
    name: browserVersion.name ? browserVersion.name.toLowerCase() : '',
    major: versionParts && versionParts.length ? parseInt(versionParts[0], 10) : 0,
    minor: versionParts && versionParts.length > 1 ? parseInt(versionParts[1], 10) : 0
  };
});

const getOS = (): string => {
  return getOsInfo().name;
};

const getEndpointBrowserString = (): string => {
  const browser = getBrowser().getBrowser();
  const os = getOsInfo();
  const ipad = isIpad() ? ' ipad' : '';
  return `${browser.name}/${browser.version} ${os.name}/${os.major}.${os.minor}${ipad}`;
};

/** Returns lower-case string - ex: 'desktop', 'tablet', 'mobile', etc. */
const getType = (): string => {
  return (getBrowser().getPlatform().type || '').toLowerCase();
};

/** Returns lower-case string - ex: 'chrome', 'firefox', etc. */
const getBrowserName = (): string => {
  return (getBrowser().getBrowserName() || '').toLowerCase();
};

/** Returns lower-case string - ex: 'blink', etc. */
const getEngineName = (): string => {
  // @ts-ignore
  return (getBrowser().getEngineName() || '').toLowerCase();
};

const isMobile = _memoize((): boolean => {
  if (getType() === 'mobile' || getType() === 'tablet') {
    return true;
  }
  if (getOS() === 'macos' && getType() === 'desktop') {
    return navigator.maxTouchPoints > 1;
  }
  return false;
});

const isDesktop = _memoize((): boolean => {
  return !isMobile();
});

const isWindows8 = _memoize((): boolean => {
  const hasWin8UserAgent = navigator.userAgent?.toLowerCase().includes('windows nt 6.3');
  return hasWin8UserAgent;
});

const isIosMobile = _memoize((): boolean => {
  if (isChrome()) {
    return false;
  }
  if (isIpadMasqueradingAsDesktop()) {
    return true;
  }
  if (isIos()) {
    return true;
  }
  if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  }

  if (isIpad()) {
    return true;
  }
  return false;
});

export const isIpadMasqueradingAsDesktop = () => {
  return !!(getType() === 'macintel' && navigator?.maxTouchPoints > 1);
};

const isIpad = () => {
  if (isWindows() || isAndroid()) {
    return false;
  }
  if (isIpadMasqueradingAsDesktop()) return true;
  return (
    navigator?.maxTouchPoints > 1 &&
    typeof window?.AuthenticatorAssertionResponsewindow?.AuthenticatorAttestationResponse === 'undefined' &&
    navigator?.plugins?.length === 0
  );
};

const isSurface = () => {
  if (isIos() || isIpadMasqueradingAsDesktop() || isMac()) {
    return false;
  }
  const maxTouchPoints = navigator?.maxTouchPoints || navigator?.msMaxTouchPoints;
  const isTouchable =
    'ontouchstart' in window ||
    maxTouchPoints > 0 ||
    (window.matchMedia && matchMedia('(any-pointer: coarse)').matches);

  return isTouchable;
};

const isMac = (): boolean => isDesktop() && getOS() === 'macos';

const isIos = (): boolean => getOS() === 'ios';

const isAndroid = (): boolean => getOS() === 'android';

const isWindows = (): boolean => getOS() === 'windows';

const isChrome = _memoize((): boolean => getBrowserName() === 'chrome');

const isFireFox = _memoize((): boolean => getBrowserName() === 'firefox');

const isWebkit = _memoize((): boolean => CSS.supports('( -webkit-text-security:disc )'));

const isSafari = _memoize(() => {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia !== null && getBrowserName() === 'safari');
});

const isPresentationEnabled = (): boolean =>
  !isMobile() && typeof _get(navigator, 'mediaDevices.getDisplayMedia', undefined) === 'function';

const isChromeLikeBrowser = _memoize((): boolean => {
  const browserName = getBrowserName();
  return isDesktop() && (browserName === 'chrome' || (browserName === 'microsoft edge' && getEngineName() === 'blink'));
});

const webRTCSafariWorks = (): boolean => {
  const osInfo = getOsInfo();
  const browserInfo = getBrowserInfo();
  return (
    isSafari() &&
    ((isIos() && osInfo.major >= 14) ||
      (isIpad() && browserInfo.major >= 14) ||
      (isIos() && osInfo.major >= 13 && osInfo.minor >= 4) ||
      (isIpad() && browserInfo.major >= 13 && browserInfo.minor >= 1))
  );
};

const MACOSX_MAJOR = 10;
const MACOSX_MINOR_CATALINA = 15;

export {
  isIosMobile,
  isDesktop,
  isWindows8,
  isMobile,
  isChrome,
  isFireFox,
  isPresentationEnabled,
  isChromeLikeBrowser,
  isSurface,
  getType,
  isWebkit,
  isMac,
  getOsInfo,
  getEndpointBrowserString,
  isSafari,
  MACOSX_MAJOR,
  MACOSX_MINOR_CATALINA,
  webRTCSafariWorks
};
