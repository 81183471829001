import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { RootState } from 'redux/rootReducer';
import { get as _get, pick as _pick } from 'lodash';
import Logger from 'js-logger';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles, Box, SvgIcon } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';
import { ReactComponent as AddIcon } from '@lifesize/ux-assets/vectors/svgIcons/add-circle.svg';
import { ReactComponent as BlockIcon } from '@lifesize/ux-assets/vectors/svgIcons/block.svg';
import { ReactComponent as BlurIcon } from '@lifesize/ux-assets/vectors/svgIcons/blur.svg';
import { ReactComponent as CancelIcon } from '@lifesize/ux-assets/vectors/svgIcons/cancel-circle.svg';
import { theme } from 'theme';
import { setError } from 'redux/vbbSettingsSlice';
import { onDragOver, onDrop, onImageSelected, errorType } from 'utils/images';
import { readFromLocalStorage, writeToLocalStorage } from 'utils/localStorage/vbbImages';

export interface bgOptType {
  content?: any;
  dataUrl?: string; // existence indicates that the image was uploaded (user-provided, can be deleted)
  id: string; // 'none', 'blur' or some other arbitrarily unique id value
  label?: string;
  path?: string; // existance indicates that the image is default (cannot be deleted)
}

const defaultBgOptions: bgOptType[] = [
  {
    id: 'vbb-1',
    label: 'Background 1',
    path: 'images/vbb-1.jpg'
  },
  {
    id: 'vbb-2',
    label: 'Background 2',
    path: 'images/vbb-2.jpg'
  },
  {
    id: 'vbb-3',
    label: 'Background 3',
    path: 'images/vbb-3.jpg'
  },
  {
    id: 'vbb-4',
    label: 'Background 3',
    path: 'images/vbb-4.jpg'
  },
  {
    id: 'vbb-5',
    label: 'Background 3',
    path: 'images/vbb-5.jpg'
  }
];

interface Props {
  onSelect: Function;
}

const ImagePicker = (props: Props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);
  const initialBgOptions: bgOptType[] = [
    {
      content: <SvgIcon component={BlockIcon} className={classes.largeIcon} />,
      id: 'none',
      label: 'None'
    },
    {
      content: <SvgIcon component={BlurIcon} className={classes.largeIcon} />,
      id: 'blur',
      label: 'Blur'
    }
  ];
  const { selection: bgSelection } = useSelector((state: RootState) => state.vbbSettings);
  const [bgOptionsLocal, setBgOptionsLocal] = useState(readFromLocalStorage());
  const [bgOptions, setBgOptions] = useState([...initialBgOptions, ...defaultBgOptions, ...bgOptionsLocal]);
  const [isDraggingOver, setIsDraggingOver] = useState<EventTarget | null>(null);

  const onImageLoaded = ({ dataUrl, error }: { dataUrl: string; error: any }) => {
    if (error) {
      Logger.warn('Error: onImageLoaded', error);
      switch (error.type) {
        case errorType.CANNOT_OPTIMIZE:
          dispatch(setError(intl.get('vbbCannotOptimizeImage')));
          break;
        case errorType.CANNOT_LOAD_IMAGE:
          dispatch(setError(intl.get('vbbCannotLoadImage')));
          break;
        case errorType.FILE_TYPE_NOT_SUPPORTED:
          dispatch(setError(intl.get('vbbFileTypeNotSupported', { supportedTypes: 'bmp, gif, jpeg, png, tiff' })));
          break;
      }
    }
    if (dataUrl) {
      const newLocalOptions = [
        ...bgOptionsLocal,
        {
          dataUrl,
          id: uuidv4(),
          label: 'custom background'
        }
      ];
      onUpdateBgOptions(newLocalOptions);
    }
  };

  const onRemoveImage = (event: React.MouseEvent, optionId: string) => {
    const newLocalOptions = bgOptionsLocal.filter((option: bgOptType) => option.id !== optionId);

    event.stopPropagation();
    event.preventDefault();
    // select 'none' if you are deleting the currently selected bg
    if (bgSelection.id === optionId) {
      props.onSelect({ id: 'none' });
    }
    onUpdateBgOptions(newLocalOptions);
  };

  // an image was selected with the form input (triggered by the '+' button)
  const onSelectImage = () => {
    const newFile = _get(inputRef, 'current.files[0]');

    if (newFile) {
      onImageSelected(newFile, onImageLoaded);
    }
  };

  const onUpdateBgOptions = (newLocalOptions: bgOptType[]) => {
    writeToLocalStorage(newLocalOptions);
    setBgOptionsLocal(newLocalOptions);
    setBgOptions([...initialBgOptions, ...defaultBgOptions, ...newLocalOptions]);
  };

  const renderDeleteButton = (optionId: string) => {
    return (
      <Box className={classes.bgOptionDelete} onClick={(event) => onRemoveImage(event, optionId)}>
        <CancelIcon />
      </Box>
    );
  };

  return (
    <Box
      className={clsx(classes.container, isDraggingOver && classes.isDraggingOver)}
      onDragOver={onDragOver}
      onDrop={(event: React.DragEvent<HTMLElement>) => {
        onDrop(event, onImageLoaded);
        setIsDraggingOver(null);
      }} // onDrop will be fired instead of onDragLeave if the file is dropped on this target
      onDragEnter={(e) => setIsDraggingOver(e.target)} // onDrageEnter will be fired for this target element as well as any of its children
      onDragLeave={(e) => {
        e.target === isDraggingOver && setIsDraggingOver(null);
      }} // onDragLeave will fire when the draggable item leaves this target (or when the draggable item is dragged over one of its children, it occurs AFTER the onDrageEnter event of the child)
    >
      {bgOptions.map((option) => {
        const imgSrc = option.path || option.dataUrl;
        const optStyle = imgSrc ? { backgroundImage: `url('${imgSrc}')` } : {};
        return (
          <Box
            key={option.id}
            className={clsx(classes.bgOption, option.id === bgSelection.id && classes.bgSelected)}
            onClick={() => props.onSelect(_pick(option, ['dataUrl', 'id', 'path']))}
            style={optStyle}
          >
            <Box className={classes.bgOptionContent}>{option.content}</Box>
            {!!option.dataUrl && renderDeleteButton(option.id)}
          </Box>
        );
      })}
      <Box key="uploadImg" className={classes.bgOption}>
        <Box className={classes.bgOptionContent}>
          <SvgIcon component={AddIcon} className={clsx(classes.largeIcon, classes.addIcon)} />
          <input
            accept="image/png, image/jpeg, image/gif, image/bmp, image/tiff"
            onChange={onSelectImage}
            id="selectLocalImage"
            multiple={false}
            name="selectLocalImage"
            ref={inputRef}
            type="file"
          />
          <label htmlFor="selectLocalImage" /> {/* using label hides 'no file chosen' tooltip on file input */}
        </Box>
      </Box>
    </Box>
  );
};

// Taken from AudioVideoSettings
const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(2)}px`,
    width: '100%'
  },
  isDraggingOver: {
    backgroundColor: colors.lifesize
  },
  largeIcon: {
    height: 80,
    width: 'auto'
  },
  addIcon: {
    color: colors.lifesize
  },
  bgOption: {
    backgroundColor: '#ebf0f4',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 4,
    boxSizing: 'border-box',
    color: '#3f5671',
    cursor: 'pointer',
    height: 0,
    margin: theme.spacing(0.5),
    paddingTop: `${(9 / 16) * 100}%`, // magic to maintain aspect ratio
    position: 'relative',
    width: '100%'
  },
  bgOptionContent: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(1),
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    '& input': {
      bottom: 0,
      height: 0,
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: 0
    },
    '& label': {
      bottom: 0,
      cursor: 'pointer',
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%'
    }
  },
  bgOptionDelete: {
    height: theme.spacing(4),
    position: 'absolute',
    right: 0,
    top: 0,
    width: theme.spacing(4),
    '& svg': {
      color: colors.darkPepper,
      height: '100%',
      width: 'auto'
    }
  },
  bgSelected: {
    boxShadow: `0 0 0 2px ${colors.lifesize}`
  }
}));

export default ImagePicker;
