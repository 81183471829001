import { createSlice } from '@reduxjs/toolkit';
import { readFromLocalStorage } from 'utils/localStorage/userInformation';

interface UserInformationState {
  name: string;
  email: string;
  returningUser: boolean;
}

const localStorageValue = readFromLocalStorage();
const initialState: UserInformationState = {
  ...localStorageValue,
  returningUser: !!localStorageValue.name
};

const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState,
  reducers: {
    clear(state) {
      state.name = '';
      state.email = '';
      state.returningUser = false;
    },
    set(state, action) {
      const { name, email, returningUser } = action.payload;
      state.name = name;
      state.email = email;
      state.returningUser = returningUser;
    }
  }
});

export const { clear, set } = userInformationSlice.actions;
export default userInformationSlice.reducer;
