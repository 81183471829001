import React, { useState, useCallback } from 'react';
import intl from 'react-intl-universal';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';
import { hooks, ObservableEvent } from '@lifesize/clients.sdk';

export default function ModeratorUnmutedParticipant() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { localAudioMuted } = hooks.useMedia();
  const currentUser = hooks.useUserInfo();

  const handleEvent = useCallback(
    (event?: any) => {
      if (currentUser?.uuid === event?.participant?.uuid && !localAudioMuted) {
        setOpen(true);
      }
    },
    [currentUser, localAudioMuted]
  );

  hooks.useObservableEvent(ObservableEvent.ModeratorUnmutedParticipant, handleEvent);

  return (
    <Snackbar {...defaultProps} open={open} onClose={() => setOpen(false)}>
      <SnackbarContent
        data-testid="moderatorUnmuted"
        className={classes.message}
        aria-describedby="client-snackbar"
        message={intl.get('moderatorUnMutedAll')}
      />
    </Snackbar>
  );
}
