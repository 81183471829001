import React from 'react';
import { makeStyles, Container, Box, Typography } from '@material-ui/core';
import Header from 'components/PreJoin/Header';
import intl from 'react-intl-universal';
import { isMobile, isDesktop } from 'utils/browserDetection';
import MobileDownloadLinks from 'components/Unsupported/MobileDownloadLinks';
import DesktopDownloadLinks from 'components/Unsupported/DesktopDownloadLinks';

const Unsupported = () => {
  const classes = desktopStyles();
  return (
    <Container className={classes.container}>
      <Header />
      <Box className={classes.content}>
        <Box mt={4} mb={10} className={classes.text}>
          <Typography variant="h1">{intl.getHTML('unsupportedDevice')}</Typography>
        </Box>
        {isMobile() && <MobileDownloadLinks />}
        {isDesktop() && <DesktopDownloadLinks />}
      </Box>
    </Container>
  );
};

const desktopStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxWidth: '100%',
    overflow: 'auto',
    padding: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center'
  },
  text: {
    maxWidth: '50%',
    textAlign: 'center'
  }
}));
export default Unsupported;
