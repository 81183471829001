import { createSlice } from '@reduxjs/toolkit';

export const notificationDuration = 2000;

export enum tooltipNotificationIds {
  audioMessage = 'audioMessage',
  generalMessage = 'generalMessage',
  personalMessage = 'personalMessage'
}

export enum notificationDisplayType {
  tooltip = 'tooltip',
  snackbar = 'snackbar'
}

export interface NotificationObj {
  id: string;
  defaultMessage?: string;
  displayType: notificationDisplayType;
  values?: object;
  messageType?: string;
}

export interface TooltipNotificationState {
  [tooltipNotificationIds.personalMessage]: NotificationObj | undefined;
  [tooltipNotificationIds.generalMessage]: NotificationObj | undefined;
}

const initialState: TooltipNotificationState = {
  [tooltipNotificationIds.personalMessage]: undefined,
  [tooltipNotificationIds.generalMessage]: undefined
};

const tooltipNotificationSlice = createSlice({
  name: 'tooltipNotification',
  initialState,
  reducers: {
    clearTipNotification(state, action) {
      return {
        ...state,
        [action.payload.tooltipId]: undefined
      };
    },
    setTipNotification(state, action) {
      return {
        ...state,
        [action.payload.tooltipId]: action.payload.messageObj
      };
    }
  }
});

export const { clearTipNotification, setTipNotification } = tooltipNotificationSlice.actions;
export default tooltipNotificationSlice.reducer;
