import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import intl from 'react-intl-universal';
import { colors } from '@lifesize/clients.mui-components';

const SignUp = () => {
  const classes = useStyles();
  return (
    <Box pt={2} pb={3}>
      <Typography component="span" data-testid="signup-link">
        {intl.get('newToLifesize')}
        <Box component={'span'} ml={1}>
          <a
            className={classes.signUpLink}
            rel="noopener noreferrer"
            href="https://signup.lifesize.com"
            target="_blank"
          >
            {intl.get('signup')}
          </a>
        </Box>
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  signUpLink: {
    color: colors.lifesize,
    cursor: 'pointer'
  }
}));

export default SignUp;
