import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';
import { hooks } from '@lifesize/clients.sdk';
import usePrevious from 'hooks/usePrevious';
import { ParticipantInterface } from 'components/ParticipantsDrawer/Interfaces';

export default function ModeratorMutedAll() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const participants = hooks.useParticipants();
  const currentUser = hooks.useUserInfo();
  const currentParticipant = participants.find(
    (participant: ParticipantInterface) => participant.uuid === currentUser.uuid
  );

  const { serverMuteState } = currentParticipant ? currentParticipant : false;
  const prevServerMuteState = usePrevious(serverMuteState);

  useEffect(() => {
    if (serverMuteState && !prevServerMuteState) {
      setOpen(true);
    }
  }, [setOpen, prevServerMuteState, serverMuteState]);

  return (
    <Snackbar {...defaultProps} open={open} onClose={() => setOpen(false)}>
      <SnackbarContent
        data-testid="moderatorMuted"
        className={classes.message}
        aria-describedby="client-snackbar"
        message={intl.get('moderatorMutedAll')}
      />
    </Snackbar>
  );
}
