import { colors } from '@lifesize/clients.mui-components';
import { ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import intl from 'react-intl-universal';
import { RemoteModeratorInterface } from './Interfaces';

interface Props {
  moderator: RemoteModeratorInterface;
  index: number;
}

const RemoteModeratorListItem = (props: Props) => {
  const classes = useStyles(props);
  const { moderator, index } = props;

  return (
    <ListItem
      data-testid="remoteModeratorItem"
      key={`${moderator.uuid}${index}`}
      className={classes.listItem}
      onClick={(event: React.KeyboardEvent | React.MouseEvent) => {
        !!event && event.stopPropagation();
      }}
    >
      <ListItemText
        data-testid="remoteModeratorName"
        primary={`${intl.get('participantModeratorRemote', { name: moderator.displayName })}`}
      />
    </ListItem>
  );
};

const useStyles = makeStyles<any, any>(() => ({
  iconOverride: {
    minWidth: 27
  },
  list: {
    minWidth: 375,
    '& span': {
      color: colors.lightSlateGray,
      fontSize: 14
    }
  },
  audio: {
    height: 24,
    width: 24,
    minWidth: 24,
    color: colors.charcoal
  },
  disabled: {
    color: colors.chiliPepper
  },
  sharing: {
    height: 24,
    width: 24,
    minWidth: 24,
    color: colors.mint
  }
}));

export default RemoteModeratorListItem;
