import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import Logger from 'js-logger';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { colors, SwipeableDrawer } from '@lifesize/clients.mui-components';
import { hooks } from '@lifesize/clients.sdk';
import { theme } from 'theme';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import { DrawerSide } from 'types/pages';
import { toggleDrawerOpen as toggleOpen, bgSelectionType } from 'redux/vbbSettingsSlice';
import { RootState } from 'redux/rootReducer';
import { isIosMobile } from 'utils/browserDetection';
import { vbbManager } from 'utils/vbbManager';

interface Props {
  inCall?: boolean;
}

const VbbSettings = (props: Props) => {
  const classes = useStyles({});
  const drawerDirection: DrawerSide = props.inCall ? 'right' : 'left';
  const dispatch = useDispatch();
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);
  const { localVideoMuted } = hooks.useMedia();
  const { enabled: vbbEnabled, selection: bgSelection, drawerIsOpen: open } = useSelector(
    (state: RootState) => state.vbbSettings
  );

  const onSelectBg = useCallback(
    (newBgSelection: bgSelectionType) => {
      if (newBgSelection.id === bgSelection.id) {
        Logger.warn('Chose same background as existing setting; not calling vbbManager.', bgSelection.id);
        return;
      }

      vbbManager.chooseBackgroundSetting(dispatch, localVideoMuted, vbbEnabled, newBgSelection, mediaSettings);
    },
    [dispatch, localVideoMuted, vbbEnabled, mediaSettings, bgSelection]
  );

  return (
    <SwipeableDrawer
      anchor={drawerDirection}
      closeButtonProps={{
        dataTestId: 'avSettingsClose',
        closeDrawer: () => dispatch(toggleOpen()),
        label: intl.get('close')
      }}
      isIos={isIosMobile()}
      onClose={() => dispatch(toggleOpen())}
      onOpen={() => dispatch(toggleOpen())}
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.container}>
        <Box className={classes.heading}>
          <Typography component="div" className={classes.headingTitle} data-testid="vbbSettingsHeader">
            {/* {intl.get('videoSettings')} JEFFTODO: use intl lib!*/}
            {'Virtual Background & Blur'}
          </Typography>
        </Box>
        <Box className={classes.scrollContainer} data-testid="vbbSelection">
          <ImagePicker onSelect={onSelectBg} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

// Taken from AudioVideoSettings
const useStyles = makeStyles(() => ({
  drawerPaper: {
    backgroundColor: colors.nimbusCloud,
    maxWidth: '100%',
    width: 400
  },
  container: {
    boxSizing: 'border-box',
    color: colors.lightSlateGray,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: `${theme.spacing(6)}px 0 ${theme.spacing(2)}px`,
    position: 'relative'
  },
  heading: {
    borderBottom: `1px solid ${colors.stone}`,
    display: 'flex',
    flex: '0 0 auto',
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px`
  },
  headingTitle: {
    fontSize: '1.8rem',
    fontWeight: 500
  },
  scrollContainer: {
    flex: '1 1 auto',
    marginTop: theme.spacing(3),
    overflowX: 'hidden',
    overflowY: 'scroll',
    width: '100%'
  }
}));

export default VbbSettings;
