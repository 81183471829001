import React from 'react';
import { useDispatch } from 'react-redux';
import { colors } from '@lifesize/clients.mui-components';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import { clear } from 'redux/userInformationSlice';
import { writeToLocalStorage } from 'utils/localStorage/userInformation';

interface Props {
  name: string;
  isReturnUser: boolean;
  clickHandler: any;
}

const JoinFormGreeting = ({ name, isReturnUser, clickHandler }: Props) => {
  const classes = userFieldStyles();
  const dispatch = useDispatch();

  const handleNotYouClicked = () => {
    writeToLocalStorage('', '');
    dispatch(clear());
    clickHandler();
  };

  return isReturnUser ? (
    <Box className={classes.returningUser}>
      <Box className="greeting">
        <Typography variant={'subtitle1'} data-testid="greeting">
          {intl.get('welcomeBack', { name: name })}
        </Typography>
      </Box>
      <Box className="notYou" onClick={handleNotYouClicked}>
        <Typography>{intl.get('notYou')}</Typography>
      </Box>
    </Box>
  ) : (
    <Box className={classes.newUser}>
      <Typography variant="subtitle1" className={classes.subtitle} data-testid="greeting">
        {intl.get('getReadyForMeeting')}
      </Typography>
    </Box>
  );
};

const userFieldStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '2rem'
  },
  newUser: {
    '@media (orientation: portrait)': {
      marginTop: theme.spacing(2)
    }
  },
  returningUser: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '236px',
    '@media (orientation: portrait)': {
      marginTop: theme.spacing(2)
    },
    '& .greeting': {
      display: 'flex',
      flexGrow: 1,
      '& p': {
        color: colors.stormcloud,
        fontSize: '1.6rem'
      }
    },
    '& .notYou': {
      display: 'flex',
      flexShrink: 0,
      '& p': {
        color: colors.lifesize,
        fontSize: '1.6rem'
      },
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
}));

export default JoinFormGreeting;
