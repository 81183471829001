import { createSlice } from '@reduxjs/toolkit';
import { PSTNObject } from 'utils/pstn';

const initialState: PSTNObject = { allNumbers: [], customNumbers: [] };

const pstnNumbersSlice = createSlice({
  name: 'pstnNumbers',
  initialState,
  reducers: {
    setPstnNumbers(state, action) {
      return { ...state, ...action.payload };
    }
  }
});

export const { setPstnNumbers } = pstnNumbersSlice.actions;
export default pstnNumbersSlice.reducer;
