import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Logger from 'js-logger';
import { hooks, constants as c } from '@lifesize/clients.sdk';
import { openCatalina, openScreenShareBlocked } from 'redux/modalsSlice';
import { setPresentationErrorSnackbarOpen } from 'redux/snackbarsSlice';
import { isMobile } from 'utils/browserDetection';
import { shouldDisplayCatalinaSettingsModal, startPresentation, stopPresentation } from 'utils/presentationUtils';
import { featureFlagPresentationAudio, permissionDeniedByUser } from 'constants/constants';
import useFeatureFlag from 'hooks/useFeatureFlag';

const usePresentation = (isPresenting: boolean) => {
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  const media = hooks.useMedia();
  const hasPresentationAudioFeature = useFeatureFlag(featureFlagPresentationAudio);
  const presentationRestriction = hooks.usePresentationRestriction();
  const conferenceState = hooks.useConferenceState();

  const handlePresentation = useCallback(async () => {
    try {
      const allowAudioCapture = hasPresentationAudioFeature && conferenceState === 'escalated';
      errorMsg && setErrorMsg('');
      isPresenting ? stopPresentation() : await startPresentation(allowAudioCapture);
    } catch (e) {
      // checking Permission for Chrome
      if (e.message && e.message === permissionDeniedByUser) {
        return;
      }
      if (e instanceof DOMException && e.name === 'NotAllowedError' && shouldDisplayCatalinaSettingsModal()) {
        dispatch(openCatalina());
      } else {
        if (isMobile()) {
          dispatch(setPresentationErrorSnackbarOpen({ open: true }));
        } else {
          dispatch(dispatch(openScreenShareBlocked()));
        }
      }
    }
  }, [conferenceState, hasPresentationAudioFeature, dispatch, errorMsg, isPresenting, setErrorMsg]);
  useEffect(() => {
    if (presentationRestriction === c.PresentationRestriction.restrictedShare && isPresenting) {
      try {
        stopPresentation();
      } catch (e) {
        Logger.error(e);
        Logger.warn(
          `Error occurred while stopping presentation after presentation is restricted: ${JSON.stringify(e)}`
        );
      }
    }
  }, [presentationRestriction, isPresenting]);

  useEffect(() => {
    if (media.presentationToken === c.PRESENTATION_TOKEN_STATE_DENIED) {
      try {
        stopPresentation();
        Logger.warn('Stopped presentation because presentation token is denied');
      } catch (e) {
        Logger.error(`Stopped presentation when presentation token is denied ${JSON.stringify(e)}`);
      }
    }
  }, [media.presentationToken]);

  return {
    errorMsg,
    handlePresentation
  };
};

export default usePresentation;
