import { createSlice } from '@reduxjs/toolkit';

interface ModalsState {
  catalinaOpen: boolean;
  screenShareBlockedOpen: boolean;
}

const initialState: ModalsState = {
  catalinaOpen: false,
  screenShareBlockedOpen: false
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openCatalina(state) {
      state.catalinaOpen = true;
    },
    closeCatalina(state) {
      state.catalinaOpen = false;
    },
    openScreenShareBlocked(state) {
      state.screenShareBlockedOpen = true;
    },
    closeScreenShareBlocked(state) {
      state.screenShareBlockedOpen = false;
    }
  }
});

export const { openCatalina, closeCatalina, openScreenShareBlocked, closeScreenShareBlocked } = modalsSlice.actions;

export default modalsSlice.reducer;
