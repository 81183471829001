import { createSlice } from '@reduxjs/toolkit';
import { getExtension } from 'utils/urlUtils';

interface ExtensionState {
  value: string;
  valid: boolean;
  loading: boolean;
  hasChecked: boolean;
  groupUUID?: string;
}

const initialState: ExtensionState = {
  value: getExtension(),
  valid: false,
  loading: false,
  hasChecked: false,
  groupUUID: undefined
};

const extensionSlice = createSlice({
  name: 'extension',
  initialState,
  reducers: {
    set(state, action) {
      state.value = action.payload.value;
    },
    setValidity(state, action) {
      state.valid = action.payload.valid;
    },
    setGroup(state, action) {
      state.groupUUID = action.payload.groupUUID;
    },
    setLoading(state, action) {
      state.loading = action.payload.loading;
    },
    setHasChecked(state, action) {
      state.hasChecked = action.payload.hasChecked;
    }
  }
});

export const { set, setValidity, setLoading, setHasChecked, setGroup } = extensionSlice.actions;
export default extensionSlice.reducer;
