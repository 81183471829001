import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@lifesize/clients.mui-components';
import { Box, Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import { RootState } from 'redux/rootReducer';
import { hooks } from '@lifesize/clients.sdk';

interface Props {
  handleJoinMeeting: () => void;
  hasError: boolean;
  isCalling: boolean;
}

const JoinButton = ({ handleJoinMeeting, hasError, isCalling }: Props): React.ComponentElement<Props, any> => {
  const { tos } = useSelector((state: RootState) => state.compliance);
  const extensionState = useSelector((state: RootState) => state.extension);
  const isValidatingExtension = extensionState?.loading;
  const extension = extensionState?.value;
  const { localPrimaryStream } = hooks.useMedia();

  return (
    <Box mt={1} mb={2} width={'100%'}>
      <Button
        color="primary"
        data-testid="join"
        disabled={hasError || !tos || isValidatingExtension || !localPrimaryStream || isCalling}
        onClick={() => handleJoinMeeting()}
        variant="contained"
      >
        <Typography variant="button">
          {isCalling ? `${intl.get('begin')} ${extension}` : intl.get('joinMeeting')}
        </Typography>
      </Button>
    </Box>
  );
};

export default JoinButton;
