import intl from 'react-intl-universal';
import _memoize from 'lodash/memoize';
import _values from 'lodash/values';

// These functions are hammered by client mouse events,
// and called from several places - memoizing...

const getAudioTooltipText = _memoize(
  (serverMuteState: boolean, localAudioMuted: boolean, usePhoneAudio: boolean) => {
    if (serverMuteState) return intl.get('moderatorMutedAll');
    if (localAudioMuted) {
      if (usePhoneAudio) return intl.get('switchToDeviceAudio');
      else return intl.get('microphoneUnmute');
    } else return intl.get('microphoneMute');
  },
  (...args) => _values(args).join('_')
);

const getPresentationTooltipText = _memoize(
  (isPresenting: boolean, isPresentationRestricted?: boolean) => {
    if (isPresentationRestricted) return intl.get('presentationRestricted');
    return isPresenting ? intl.get('shareStop') : intl.get('shareStart');
  },
  (...args) => _values(args).join('_')
);

const getVideoTooltipText = _memoize((isSelected: boolean) =>
  isSelected ? intl.get('cameraOn') : intl.get('cameraOff')
);

const getPipButtonToolTipText = _memoize((isSelected: boolean) =>
  isSelected ? intl.get('pipOn') : intl.get('pipOff')
);

const getRaiseHandToolTipText = _memoize((isSelected: boolean) =>
  isSelected ? intl.get('lowerHand') : intl.get('raiseHand')
);

export {
  getAudioTooltipText,
  getPresentationTooltipText,
  getVideoTooltipText,
  getPipButtonToolTipText,
  getRaiseHandToolTipText
};
