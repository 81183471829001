import { createSlice } from '@reduxjs/toolkit';
import { readFromLocalStorage } from 'utils/localStorage/compliance';

interface ComplianceState {
  cookies: string;
  tos: string;
}

const localStorageValue = readFromLocalStorage();
const initialState: ComplianceState = {
  ...localStorageValue
};

const complianceSlice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    acceptCookies(state, action) {
      const timeStamp = action.payload;
      state.cookies = timeStamp;
    },
    acceptTos(state, action) {
      const timeStamp = action.payload;
      state.tos = timeStamp;
    }
  }
});

export const { acceptCookies, acceptTos } = complianceSlice.actions;
export default complianceSlice.reducer;
