import { useQuery } from '@apollo/react-hooks';
import searchClientFeatureFlags from 'gql/queries/searchClientFeatureFlags';
import { featureFlagRaiseHand } from 'constants/constants';
import { hooks } from '@lifesize/clients.sdk';

export default function () {
  const { guestToken, guid } = hooks.useUserInfo();
  const { data } = useQuery(searchClientFeatureFlags, {
    context: {
      headers: {
        authorization: guestToken
      }
    },
    variables: {
      names: [featureFlagRaiseHand],
      guid,
      clientInfo: {
        // hardcoded for feature flag service for now, until that service is changed.
        browser: 'CHROME',
        platform: 'WEB',
        version: '80'
      }
    }
  });
  return data?.searchClientFeatureFlags?.[0]?.value;
}
