import React, { useState } from 'react';
import { RootState } from 'redux/rootReducer';
import { useSelector } from 'react-redux';
import Header from 'components/PreJoin/Header';
import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import intl from 'react-intl-universal';
import { Button, colors } from '@lifesize/clients.mui-components';
import { ReactComponent as CopyIcon } from '@lifesize/ux-assets/vectors/svgIcons/file-copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { theme } from 'theme';
import { mobileCustomProtocol } from 'constants/constants';
import { webRTCSafariWorks } from 'utils/browserDetection';

const downloadIOSApp = () => {
  window.location.assign('https://itunes.apple.com/us/app/lifesize-cloud/id854663434');
};

const bypassSplash = () => {
  window.location.href = `${window.location.href}?bypassSplash=true`;
};

// This component should only be shown for iOS devices.
export const SplashLayout = () => {
  const extensionState = useSelector((state: RootState) => state.extension);
  const [isCopied, setIsCopied] = useState(false);
  const classes = splashStyles();
  return (
    <Box className={classes.container}>
      <Header hideAccountLink={true} />
      <Box mx={6} className={classes.content}>
        <Box mt={4}>
          <Typography>{intl.get('splashHeader')}</Typography>
        </Box>
        <Box my={2}>
          <Typography>{intl.getHTML('splashCTA')}</Typography>
        </Box>
        {extensionState.value && (
          <Box my={2} width={'100%'} className={classes.extensionContainer}>
            <Typography className={classes.extensionLabel}>
              {`${intl.get('splashExtension')} ${extensionState.value}`}
            </Typography>
            <CopyToClipboard
              text={extensionState.value}
              onCopy={() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
              }}
            >
              <CopyIcon className={isCopied ? classes.isCopied : classes.copyIcon} />
            </CopyToClipboard>
          </Box>
        )}
        <Box my={2} className={classes.center} width={'100%'}>
          <Button
            className={classes.button}
            color="primary"
            data-testid="next"
            onClick={downloadIOSApp}
            variant="contained"
          >
            <Typography variant="button">{intl.get('splashDownloadButton')}</Typography>
          </Button>
        </Box>
        <Box my={2} className={classes.center} width={'100%'}>
          <Box mb={1}>
            <Typography>{intl.get('splashAlreadyHave')}</Typography>
          </Box>
          <Link
            href={`${mobileCustomProtocol}${extensionState.value ? extensionState.value : ''}`}
            color="inherit"
            className={classes.buttonLink}
          >
            <Typography variant="button">{intl.get('splashJoinButton')}</Typography>
          </Link>
        </Box>
        {webRTCSafariWorks() && (
          <Box mt={4} className={classes.center}>
            <Box mb={1}>
              <Typography>{intl.get('splashCantDownload')}</Typography>
            </Box>
            <Link color="primary" data-testid="next" onClick={bypassSplash}>
              <Typography className={classes.link}>{intl.get('splashBrowserJoin')}</Typography>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const splashStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    maxWidth: '100%',
    overflow: 'auto',
    padding: 0,
    margin: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '600px',
    overflow: 'hidden',
    alignItems: 'center'
  },
  extensionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: 40,
      marginBottom: 2
    }
  },
  extensionLabel: {
    marginRight: theme.spacing(1)
  },
  copyIcon: {
    fill: colors.stormcloud
  },
  isCopied: {
    fill: colors.brightGreen
  },
  button: {
    maxWidth: '300px',
    width: '100%',
    color: colors.white
  },
  center: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    color: colors.lifesize,
    cursor: 'pointer'
  },
  buttonLink: {
    background: colors.lifesize,
    color: colors.white,
    width: '100%',
    maxWidth: '300px',
    height: '56px',
    padding: '16px 32px 13px',
    boxShadow: 'none',
    minHeight: '56px',
    borderRadius: 0,
    display: 'block'
  }
}));
