import { Modal } from '@lifesize/clients.mui-components';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './ModalStyles';
import { closeCatalina } from 'redux/modalsSlice';
import { RootState } from 'redux/rootReducer';

/***
 * This modal displays specific info for MacOSX Catalina users
 * to update their settings to allow sharing
 * @constructor
 */
const CatalinaModal = () => {
  const imgSrc = '/images/catalina_instructions.gif';
  const classes = useStyles();
  const open = useSelector((state: RootState) => state.modals.catalinaOpen);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeCatalina());
  };
  return (
    <Modal
      closeLabel={intl.get('close')}
      open={open}
      title={intl.get('presentation.notAllowed.title')}
      onClose={onClose}
    >
      <>
        <div>
          <p className={classes.textContent}>{intl.get('presentation.notAllowed.error1')}</p>
          <p className={classes.textContent}>{intl.get('presentation.notAllowed.error2')}</p>
        </div>
        <img src={imgSrc} alt={imgSrc} />
      </>
    </Modal>
  );
};

export default CatalinaModal;
