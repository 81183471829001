import React from 'react';
import { PipFooter } from '@lifesize/clients.sdk/dist/components';
import { makeStyles, Theme } from '@material-ui/core';
import useViewport from 'hooks/useViewport';

interface Props {
  height: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    bottom: 0,
    position: 'absolute',
    left: theme.spacing(1.5)
  }
}));

const Pip = (props: Props) => {
  const classes = useStyles();
  const { isFooterMax } = useViewport();

  return (
    <div className={classes.container}>
      <PipFooter height={props.height} isSinglePip={!isFooterMax} />
    </div>
  );
};

export default Pip;
