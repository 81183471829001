import { Modal } from '@lifesize/clients.mui-components';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './ModalStyles';
import { closeScreenShareBlocked } from 'redux/modalsSlice';
import { RootState } from 'redux/rootReducer';

const ScreenShareBlocked = () => {
  const classes = useStyles();
  const open = useSelector((state: RootState) => state.modals.screenShareBlockedOpen);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeScreenShareBlocked());
  };
  return (
    <Modal
      closeLabel={intl.get('close')}
      open={open}
      title={intl.get('screenShareBlockedByBrowserTitle')}
      onClose={onClose}
    >
      <div>
        <p className={classes.textContent}>{intl.get('screenShareBlockedByBrowserMessage')}</p>
      </div>
    </Modal>
  );
};

export default ScreenShareBlocked;
