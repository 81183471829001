import { pick as _pick } from 'lodash';
import { lsGet, LsKey, lsSet } from 'utils/localStorageUtil';
import { MediaConfig, persistentKeys } from 'utils/devicesUtils';

export function readFromLocalStorage() {
  const media = lsGet(LsKey.Media) || '{}';
  // only return the keys that are designed to persist (useful if the object changes shape)
  return _pick(JSON.parse(media), persistentKeys);
}

export function writeToLocalStorage(settings: MediaConfig) {
  // only save keys that are designed to persist
  const persistentSettings = _pick(settings, persistentKeys);
  lsSet(LsKey.Media, JSON.stringify(persistentSettings));
}
