import _get from 'lodash/get';
import { desktopLauncherUrl, fetchExtensionsUrl, fetchPSTNUrl, otherWaysUrl, webAppUrl } from 'constants/constants';
import { isChrome, isIosMobile, isMobile, isSafari } from './browserDetection';
import { parse } from './extension';
import { getLegacyLanguageId } from './localeUtil';
import { PSTNObject } from 'utils/pstn';

export function getUrlForExtension(extension: string) {
  const parsedExtension = parse(extension);
  if (parsedExtension) return `${window.location.origin}/${parsedExtension}`;
  return '';
}

const getExtension = () => {
  //Check the url for extension I.E. guest.lifesizebeta.com/123456
  const url = new URL(window.location.href);
  const extensionPath = url.pathname.match(/\/(\d+)/i);
  let extension = extensionPath ? extensionPath[1] : '';

  if (extension) {
    return extension; // Return the extension
  }
  // If no extension found in the pathname we try looking in the searchParams for extension I.E. guest.lifesizebeta.com/?ext=123456
  extension = url.searchParams.get('ext') || '';
  return extension;
};

const getExtensionData = async (extension: string) => {
  const url = `${fetchExtensionsUrl}${extension}`;
  const response = {
    error: null,
    isValid: false,
    groupUUID: null
  };
  if (!extension) return response;
  try {
    const data = await fetch(url);
    const dataJSON = await data.json();
    response.isValid = !!_get(dataJSON, 'valid') || false;
    response.groupUUID = _get(dataJSON, 'id') || null;
    return response;
  } catch (error) {
    response.error = error;
    return response;
  }
};

const getPSTNData = async (groupUUID: string) => {
  const url = `${fetchPSTNUrl}${groupUUID}`;
  const response: { error?: string; pstnNumbers: PSTNObject } = {
    error: undefined,
    pstnNumbers: { allNumbers: [], customNumbers: [] }
  };
  if (!groupUUID) return response;
  try {
    const data = await fetch(url);
    response.pstnNumbers = await data.json();
    return response;
  } catch (error) {
    response.error = error;
    return response;
  }
};

const openNewTab = (url: string) => {
  window.open(url, '_blank');
};

const getJoinUrl = (extension: string | null): string => {
  const locale = getLegacyLanguageId();
  if (isMobile() || isIosMobile()) {
    // If mobile, route to other ways to call
    // If no extension provided, give user more options with downloads page
    return extension ? `${otherWaysUrl}otherways/${extension}` : `${otherWaysUrl}downloads`;
  }
  if (isChrome()) {
    return extension ? `${webAppUrl}join/${extension}&lang=${locale}` : `${webAppUrl}&lang=${locale}`;
  }
  if (isSafari()) {
    return extension
      ? `${desktopLauncherUrl}?ext=${extension}&forceDownloadOnFail=true`
      : `${desktopLauncherUrl}?forceDownloadOnFail=true`;
  }
  // If desktop, route to desktop launcher (for all non-chrome browser)
  return extension ? `${desktopLauncherUrl}?ext=${extension}` : desktopLauncherUrl;
};

export { isChrome, getJoinUrl, getExtension, getExtensionData, getPSTNData, openNewTab };
