import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';
import { hooks } from '@lifesize/clients.sdk';
import usePrevious from 'hooks/usePrevious';
import { ParticipantInterface } from 'components/ParticipantsDrawer/Interfaces';

export default function ModeratorUnmutedAllLocalMute() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { localAudioMuted } = hooks.useMedia();
  const participants = hooks.useParticipants();
  const currentUser = hooks.useUserInfo();
  const currentParticipant = participants.find(
    (participant: ParticipantInterface) => participant.uuid === currentUser.uuid
  );
  const { serverMuteState } = currentParticipant ? currentParticipant : false;
  const prevServerMuteState = usePrevious(serverMuteState);

  useEffect(() => {
    if (localAudioMuted && prevServerMuteState && !serverMuteState) {
      setOpen(true);
    }
  }, [setOpen, localAudioMuted, serverMuteState, prevServerMuteState]);

  return (
    <Snackbar {...defaultProps} open={open} onClose={() => setOpen(false)}>
      <SnackbarContent
        data-testid="moderatorUnmutedSelfMuted"
        className={classes.message}
        aria-describedby="client-snackbar"
        message={intl.get('moderatorUnMutedLocalMuted')}
      />
    </Snackbar>
  );
}
