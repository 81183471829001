import { media } from '@lifesize/clients.sdk';
import Logger from 'js-logger';
import { getOsInfo, isMac, MACOSX_MAJOR, MACOSX_MINOR_CATALINA } from './browserDetection';
import { isLowSpecSystemOrSafari } from './videoResolution';

const getScreenShareConstraints = (enableAudio: boolean): MediaStreamConstraints => ({
  audio: enableAudio ? true : false,
  video: {
    frameRate: {
      max: isLowSpecSystemOrSafari ? 5 : 30
    },
    height: { max: 1080 },
    width: { max: 1920 }
  }
});

const startPresentation = async (enableAudio?: boolean) => {
  try {
    const stream: MediaStream = await media.acquirePresentation(getScreenShareConstraints(!!enableAudio));
    if (!stream) {
      throw new Error('Could not acquire presentation stream');
    }
  } catch (e) {
    Logger.error(`Unable to start presentation with getDisplayMedia: ${JSON.stringify(e)}`);
    throw e;
  }
};

const stopPresentation = () => {
  media.stopPresentation();
};

const shouldDisplayCatalinaSettingsModal = () => {
  const osInfo = getOsInfo();
  return isMac() && osInfo.major === MACOSX_MAJOR && osInfo.minor === MACOSX_MINOR_CATALINA;
};

export { startPresentation, stopPresentation, shouldDisplayCatalinaSettingsModal };
