import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { makeStyles, Box } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';
import { hooks } from '@lifesize/clients.sdk';
import { getDevicesByKind, MediaConfig } from 'utils/devicesUtils';
import { ReactComponent as SpeakerIcon } from '@lifesize/ux-assets/vectors/svgIcons/speaker.svg';
import { RootState } from 'redux/rootReducer';
import RingTone from 'components/Ringer/RingTone';
import SettingDropdown from './SettingDropdown';

interface Props {
  inCall?: boolean;
  handleConfigChange: (key: keyof MediaConfig, value: string) => void;
}

let speakerTimeout: ReturnType<typeof setTimeout>;

const ComputerAudioSettings = (props: Props) => {
  const classes = useStyles();
  const [isTestingSpeakers, setIsTestingSpeakers] = useState(false);
  const devices = hooks.useDevices();
  const audioInputDevices = useMemo(() => getDevicesByKind(devices, 'audioinput'), [devices]);
  const audioOutputDevices = useMemo(() => getDevicesByKind(devices, 'audiooutput'), [devices]);
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);
  const { audioDevice } = hooks.useMedia();

  const cancelSpeakerTest = useCallback(() => {
    clearTimeout(speakerTimeout);
    setIsTestingSpeakers(false);
  }, []);

  const handleSpeakerTest = useCallback(() => {
    clearTimeout(speakerTimeout);
    setIsTestingSpeakers(!isTestingSpeakers);
    if (!isTestingSpeakers) {
      speakerTimeout = setTimeout(() => {
        setIsTestingSpeakers(false);
      }, 4600);
    }
  }, [isTestingSpeakers]);

  const getDeviceValue = () => {
    if (audioInputDevices && audioDevice?.deviceId) {
      return audioDevice.deviceId;
    }
    return 'NA';
  };

  return (
    <>
      <Box mt={3} data-testid="micSettings">
        <SettingDropdown
          handleChange={(value) => {
            cancelSpeakerTest();
            props.handleConfigChange('microphone', value);
          }}
          items={audioInputDevices}
          label={intl.get('microphone')}
          value={getDeviceValue()}
        />
      </Box>
      {!!audioOutputDevices.length && (
        <Box mt={3} className={classes.speakerOptions} data-testid="speakerSettings">
          <SettingDropdown
            handleChange={(value) => {
              cancelSpeakerTest();
              props.handleConfigChange('speakers', value);
            }}
            items={audioOutputDevices}
            label={intl.get('speakers')}
            value={
              mediaSettings.speakers ||
              (audioOutputDevices && audioOutputDevices[0] && audioOutputDevices[0].value) ||
              ''
            }
          />
          {!props.inCall && (
            <SpeakerIcon
              className={classes.testSpeakerBtn}
              onClick={handleSpeakerTest}
              data-tip={intl.get('speakerTestLink')}
              data-testid="testSpeakerIcon"
            />
          )}
        </Box>
      )}
      {!!audioOutputDevices.length && !props.inCall && isTestingSpeakers && (
        <RingTone deviceId={mediaSettings.speakers} />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  speakerOptions: {
    position: 'relative'
  },
  testSpeakerBtn: {
    color: colors.lifesize,
    cursor: 'pointer',
    height: 36,
    position: 'absolute',
    right: 0,
    top: -10,
    width: 'auto'
  }
}));

export default ComputerAudioSettings;
