import React, { useEffect, useState, ReactElement } from 'react';
import { AudioButton as MuiAudioButton } from '@lifesize/clients.mui-components';
import useAudioMonitor from 'hooks/useAudioMonitor';
import { getMaxOutputVolume } from 'utils/audioMonitorUtils';
import { Box } from '@material-ui/core';

interface Props {
  onClick: () => void;
  muted: boolean;
  disabled: boolean;
}

const AudioButton = React.forwardRef(
  (props: Props, ref): ReactElement<any, any> => {
    const { onClick, disabled, muted } = props;
    const [micLevel, setMicLevel] = useState(0);
    const sampleData = useAudioMonitor();

    // This effect sets the inside of the audio button to visualize audio levels.
    useEffect(() => {
      if (!disabled && !muted) {
        setMicLevel(getMaxOutputVolume(sampleData));
      }
    }, [sampleData, setMicLevel, muted, disabled]);

    return (
      <Box {...({ ref: ref } as any)} {...props} display="flex" className="lsCallControl">
        <MuiAudioButton
          data-testid={'audio-button'}
          disabled={disabled}
          micLevel={micLevel}
          onClick={onClick}
          selected={muted}
        />
      </Box>
    );
  }
);

export default AudioButton;
