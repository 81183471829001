import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import _get from 'lodash/get';
import { Box, ClickAwayListener, makeStyles, SvgIcon, Theme, Tooltip } from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { fade } from '@material-ui/core/styles';
import { hooks, signaling } from '@lifesize/clients.sdk';
import { colors, Badge } from '@lifesize/clients.mui-components';
import { ReactComponent as WhiteboardIcon } from '@lifesize/ux-assets/vectors/svgIcons/palette.svg';
import { ReactComponent as HelpOutlineIcon } from '@lifesize/ux-assets/vectors/svgIcons/help-outline.svg';
import { ReactComponent as MoreIcon } from '@lifesize/ux-assets/vectors/svgIcons/more.svg';
import { ReactComponent as PeopleIcon } from '@lifesize/ux-assets/vectors/svgIcons/people.svg';
import { ReactComponent as Pip } from '@lifesize/ux-assets/vectors/svgIcons/pip.svg';
import { ReactComponent as PipOff } from '@lifesize/ux-assets/vectors/svgIcons/pip-off.svg';
import { ReactComponent as PresentIcon } from '@lifesize/ux-assets/vectors/svgIcons/share-screen.svg';
import { ReactComponent as SettingsIcon } from '@lifesize/ux-assets/vectors/svgIcons/settings.svg';
import { ReactComponent as HandIcon } from '@lifesize/ux-assets/vectors/svgIcons/hand.svg';
import { ReactComponent as VbbIcon } from '@lifesize/ux-assets/vectors/svgIcons/wallpaper.svg';
import { featureFlagWhiteboard, featureFlagVbb } from 'constants/constants';
import useFeatureFlag from 'hooks/useFeatureFlag';
import usePresentation from 'hooks/usePresentation';
import useHasRaisedHandFeature from 'hooks/useHasRaisedHandFeature';
import useViewport from 'hooks/useViewport';
import { RootState } from 'redux/rootReducer';
import { SelfViewState, toggleSelfView } from 'redux/selfViewSlice';
import { notificationDisplayType, NotificationObj, tooltipNotificationIds } from 'redux/tooltipNotificationSlice';
import { toggleOpen as toggleSettings } from 'redux/settingsDrawerSlice';
import { toggleOpen as toggleParticipants } from 'redux/participantsDrawerSlice';
import { set as setWhiteboardError } from 'redux/whiteboardSlice';
import { requestWhiteboard, WHITEBOARD_ERROR_TYPES } from 'utils/kaptivo';
import { getHelpLink } from 'utils/localeUtil';
import { openNewTab } from 'utils/urlUtils';
import { isMobile, isPresentationEnabled, isChromeLikeBrowser, isWindows8 } from 'utils/browserDetection';
import { getRemotePresenter } from 'utils/participantsUtils';
import { getPipButtonToolTipText, getPresentationTooltipText, getRaiseHandToolTipText } from 'utils/tooltipUtils';
import usePresentationRestriction from '../../hooks/usePresentationRestriction';
import { SpeedDialActions } from './Interfaces';
import { toggleDrawerOpen as toggleVbbSettings } from 'redux/vbbSettingsSlice';

interface SpeedDialProps {
  isHidden?: boolean;
  isNarrowWidth: boolean;
}

interface StyleConfig {
  isNotification: boolean;
}

const useStylesNotification = makeStyles((theme: Theme) => ({
  tooltip: (config: StyleConfig) =>
    config.isNotification
      ? {
          backgroundColor: theme.palette.background.default,
          color: colors.black
        }
      : {}
}));

const SecondaryCallActions = (props: SpeedDialProps) => {
  const [open, setOpen] = useState(false);
  const callState = hooks.useCallState();
  const dispatch = useDispatch();
  const media = hooks.useMedia();
  const callConnected = callState.state === 'connected';
  const callFullyConnected = hooks.useConferenceId(); // We can issue raise/lower hand requests once the conferenceId is available (~1 - 2 sec after call connected)
  const isPresenting = media.localPresentationStream;
  const participants = hooks.useParticipants();
  const { handlePresentation } = usePresentation(isPresenting);
  const selfView: SelfViewState = useSelector((state: RootState) => state.selfView);
  const speedDialRef = useRef<HTMLButtonElement>(null);
  const hasRaisedHandFeature = useHasRaisedHandFeature();
  const raisedHandsCount = hooks.useParticipantsWithRaisedHands().length;
  const handIsRaised = hooks.useIsCurrentUserHandRaised();
  const handIsProcessing = hooks.useIsProcessingHandCommand();
  const isRaised = handIsProcessing ? !handIsRaised : handIsRaised;
  const isVMR = hooks.useConferenceInfo().type === 'VMR'; // conference type is VRM for permanent and OTM VMRs
  const whiteboardFeatureEnabled = !!_get(hooks.useUserInfo(), 'features.whiteboarding');
  const whiteboardFlagEnabled = useFeatureFlag(featureFlagWhiteboard);
  const meetingUUID = hooks.useConferenceInfo().uuid;
  const showWhiteboardOption =
    isVMR && whiteboardFeatureEnabled && whiteboardFlagEnabled && isChromeLikeBrowser() && !!meetingUUID;
  const [whiteboardHash, setWhiteboardHash] = useState('');
  const [whiteboardIsProcessing, setWhiteboardIsProcessing] = useState(false);
  const whiteboardWindow = useRef<Window | null>(null);
  const hasVbbFeature = useFeatureFlag(featureFlagVbb);
  const showVbbOption = hasVbbFeature && callFullyConnected && isChromeLikeBrowser() && !isWindows8() && !isMobile();
  const { showPresentationRestrictionTooltip, isPresentationRestricted } = usePresentationRestriction();
  const { isMobileView } = useViewport();

  const notificationObject: NotificationObj | undefined = useSelector(
    (state: RootState) => state.tooltipNotification[tooltipNotificationIds.personalMessage]
  );
  const notification =
    props.isNarrowWidth && !!notificationObject && notificationObject.displayType === notificationDisplayType.tooltip
      ? notificationObject
      : undefined;
  const classes = useStyles();
  const tooltipClasses = useStylesNotification({ isNotification: !!notification });
  const tooltipPopperProps = !!notification
    ? { open: true } // forces the tooltip open when there is a notification
    : open || props.isHidden
    ? { open: false } // forces the tooltip closed when the SpeedDial is open or ButtonBar is idle (hidden)
    : {}; // behave normally if no exception condition is met
  const tooptipTitle = !!notification ? intl.get(notification.id, notification.values) : intl.get('moreOptions');

  const myHandIsRaised = hasRaisedHandFeature && !!isRaised && props.isNarrowWidth;
  const totalHandsRaisedCount = (hasRaisedHandFeature && props.isNarrowWidth && raisedHandsCount) || 0;

  const handleCleanupWhiteboard = () => {
    const whiteboard = whiteboardWindow.current;
    if (whiteboard && !whiteboard.closed) {
      whiteboard.close();
    }
  };

  useEffect(() => {
    // close the whiteboard if the window is closed
    window.addEventListener('beforeunload', handleCleanupWhiteboard);
    return () => {
      window.removeEventListener('beforeunload', handleCleanupWhiteboard);
      // close the whiteboard if the call ends
      handleCleanupWhiteboard();
    };
  }, []);

  const handleClose = (event?: React.MouseEvent<EventTarget>) => {
    if (event && speedDialRef.current && speedDialRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  enum toggleReason {
    blur = 'blur',
    focus = 'focus',
    mouseEnter = 'mouseEnter',
    mouseLeave = 'mouseLeave'
  }

  const handleOnClickRaiseHand = () => {
    if (isRaised) {
      signaling.lowerHand();
    } else {
      signaling.raiseHand();
    }
  };

  const handleOpenWhiteboard = (hash?: string) => {
    const hashString = hash || whiteboardHash;
    const whiteboardDomain = process.env.REACT_APP_WHITEBOARD_URL || '';
    const whiteboardUrl = `${whiteboardDomain}?code=${hashString}`;
    const features = 'menubar=0,location=false,toolbar=0,personalbar=0,titlebar=0,status=0';
    if (whiteboardDomain && hashString) {
      if (!whiteboardWindow.current || whiteboardWindow.current.closed) {
        whiteboardWindow.current = window.open(whiteboardUrl, '_blank', features);
      }
      // @ts-ignore
      whiteboardWindow.current.focus();
    }
  };

  // For every request (opening of the whiteboard window) you need a new hash
  const handleWhiteboardRequest = () => {
    if (whiteboardHash && whiteboardWindow.current && !whiteboardWindow.current.closed) {
      // if the whiteboard window is already open, don't make a new request, just give it focus
      handleOpenWhiteboard();
    } else {
      // otherwise, clear the hash in state and request a new hash (the hash can be used only once for security reasons)
      setWhiteboardIsProcessing(true);
      setWhiteboardHash('');
      requestWhiteboard(meetingUUID, handleWhiteboardResponse);
    }
  };

  const handleWhiteboardResponse = (response: { data?: string; error?: string | boolean }) => {
    setWhiteboardIsProcessing(false);
    if (response.data) {
      setWhiteboardHash(response.data);
      handleOpenWhiteboard(response.data);
    } else {
      dispatch(
        setWhiteboardError({
          error: intl.get(
            response.error === WHITEBOARD_ERROR_TYPES.NOT_SUPPORTED
              ? 'whiteboardErrorNotSupported'
              : 'whiteboardErrorServer'
          )
        })
      );
    }
  };

  const handleVbbButton = () => {
    dispatch(toggleVbbSettings());
  };

  const handleToggle = (event: object, reason: string) => {
    if (reason === toggleReason.mouseEnter || reason === toggleReason.mouseLeave || reason === toggleReason.focus)
      return; // open/close on click/tap only for mobile consistency
    if (reason === toggleReason.blur) {
      setOpen(false);
      return;
    }
    setOpen(!open);
  };

  const actions: SpeedDialActions[] = [
    {
      icon: <SvgIcon component={SettingsIcon} />,
      dataTestId: 'secondarySettings',
      label: intl.get('settings'),
      onClick: () => {
        dispatch(toggleSettings());
        handleClose();
      }
    },
    {
      icon: <SvgIcon component={HelpOutlineIcon} />,
      dataTestId: 'secondaryHelp',
      label: intl.get('helpDocuments'),
      onClick: () => {
        openNewTab(getHelpLink());
        handleClose();
      }
    }
  ];

  if (isPresentationEnabled() && props.isNarrowWidth) {
    if (media.remotePresentationStream || !callConnected || isPresentationRestricted) {
      actions.unshift({
        dataTestId: 'secondaryShare',
        icon: <SvgIcon color={'disabled'} component={PresentIcon} />,
        label: showPresentationRestrictionTooltip
          ? getPresentationTooltipText(false, showPresentationRestrictionTooltip)
          : intl.get('nameIsPresenting', { name: getRemotePresenter(participants).name })
      });
    } else {
      actions.unshift({
        icon: <SvgIcon component={PresentIcon} />,
        label: getPresentationTooltipText(isPresenting, false),
        class: isPresenting ? classes.stopPresentation : '',
        dataTestId: 'secondaryShare',
        onClick: async () => {
          await handlePresentation();
          handleClose();
        }
      });
    }
  }

  const selfViewIcon = selfView.on ? Pip : PipOff;
  if (isMobileView) {
    actions.unshift({
      icon: <SvgIcon component={selfViewIcon} />,
      label: getPipButtonToolTipText(selfView.on),
      dataTestId: 'secondarySelfViewButton',
      class: !selfView.on ? classes.pipOff : '',
      onClick: () => {
        dispatch(toggleSelfView());
        window.setTimeout(handleClose, 250);
      }
    });
  }

  if (hasRaisedHandFeature && props.isNarrowWidth) {
    actions.unshift({
      icon: <SvgIcon component={HandIcon} />,
      label: getRaiseHandToolTipText(handIsRaised), // don't use optimistic state for the action label
      dataTestId: 'secondaryRaiseHand',
      onClick: () => {
        if (callFullyConnected && !handIsProcessing) {
          handleOnClickRaiseHand();
          window.setTimeout(handleClose, 250);
        }
      }
    });
  }

  if (props.isNarrowWidth) {
    actions.unshift({
      icon: (
        <>
          <SvgIcon component={PeopleIcon} />
          {!!totalHandsRaisedCount && <Badge classes={classes.badge} label={totalHandsRaisedCount} />}
        </>
      ),
      label: intl.get('participantList'), // don't use optimistic state for the action label
      dataTestId: 'secondaryParticipantsList',
      onClick: () => {
        dispatch(toggleParticipants());
        handleClose();
      }
    });
  }

  if (showWhiteboardOption) {
    actions.unshift({
      icon: <SvgIcon component={WhiteboardIcon} />,
      label: (
        <div className={classes.label}>
          {intl.get('whiteboardButtonLabel')}
          <div className={classes.labelBadge}>{intl.get('betaVersionBadge')}</div>
        </div>
      ),
      dataTestId: 'secondaryWhiteboard',
      onClick: () => {
        if (callFullyConnected && !whiteboardIsProcessing) {
          handleWhiteboardRequest();
          handleClose();
        }
      }
    });
  }

  // Vbb only on Chrome for now, non-Win8
  if (showVbbOption) {
    actions.unshift({
      icon: <SvgIcon component={VbbIcon} />,
      label: (
        <div className={classes.label}>
          {/* {intl.get('vbbButtonLabel')} JEFFTODO: Int'l string!*/}
          Virtual Background & Blur
          <div className={classes.labelBadge}>{intl.get('betaVersionBadge')}</div>
        </div>
      ),
      dataTestId: 'secondaryVbb',
      onClick: () => {
        if (callFullyConnected) {
          handleVbbButton();
          handleClose();
        }
      }
    });
  }

  return (
    <Tooltip classes={tooltipClasses} title={tooptipTitle} PopperProps={tooltipPopperProps}>
      <Box display="flex" className={`${classes.container} lsCallControl`}>
        <ClickAwayListener onClickAway={handleClose}>
          <SpeedDial
            FabProps={{ id: 'secondaryActionsButton' }}
            ariaLabel="speed-dial-button"
            className={classes.speedDial}
            data-testid="speed-dial"
            direction="up"
            icon={<SvgIcon component={MoreIcon} viewBox="0 0 24 24" />}
            onClose={handleToggle}
            onOpen={handleToggle}
            open={open}
            ref={speedDialRef}
          >
            {actions.map((action, index) => (
              <SpeedDialAction
                className={action.class || ''}
                classes={{ staticTooltipLabel: classes.tooltip }}
                data-testid={action.dataTestId}
                icon={action.icon}
                key={`sda-${index}`}
                onClick={action.onClick}
                tooltipOpen
                tooltipTitle={action.label}
              />
            ))}
          </SpeedDial>
        </ClickAwayListener>
        {(myHandIsRaised || !!totalHandsRaisedCount) && (
          <Badge classes={classes.badgeLg} label={<SvgIcon component={HandIcon} />} />
        )}
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 60,
    position: 'relative',
    width: 60
  },
  speedDial: {
    bottom: 0,
    position: 'absolute',
    '&>button': {
      backgroundColor: fade(colors.black, 0.5),
      border: `2px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.contrastText,
      height: 60,
      width: 60,
      '&:hover': {
        backgroundColor: fade(colors.black, 0.25)
      },
      '&>.MuiFab-label svg': {
        height: 40,
        transform: 'rotate(90deg)',
        width: 'auto'
      }
    }
  },
  tooltip: {
    whiteSpace: 'nowrap'
  },
  stopPresentation: {
    color: colors.mint
  },
  pipOff: {
    color: theme.palette.error.main
  },
  badge: {
    position: 'absolute',
    right: -5,
    top: -5
  },
  badgeLg: {
    borderRadius: 15,
    height: 30,
    minWidth: 30,
    padding: 0,
    position: 'absolute',
    right: -5,
    top: -5,
    zIndex: 1050,

    '&>svg': {
      height: 30,
      width: 'auto'
    }
  },
  label: {
    alignItems: 'center',
    display: 'flex'
  },
  labelBadge: {
    backgroundColor: colors.teal,
    borderRadius: 2,
    color: colors.white,
    fontSize: 10,
    fontWeight: 600,
    marginLeft: 8,
    padding: '2px 10px',
    textTransform: 'uppercase'
  }
}));

export default SecondaryCallActions;
