import get from 'lodash/get';
import * as C from 'constants/constants';
import { rpc } from '@lifesize/clients.sdk';

export const PLUGIN = 'lifesize.plugin';
export const PLUGIN_CONTROL_URI = 'control';

export interface InputParams {
  meetingUUID: string;
}

export const WHITEBOARD_ERROR_TYPES = {
  NOT_SUPPORTED: 'NOT_SUPPORTED',
  SERVER: 'SERVER'
};

const fetchPluginHashCode = async (inputParams: InputParams) => {
  try {
    const params = {
      procedure: PLUGIN,
      args: [
        JSON.stringify({
          uriSuffix: PLUGIN_CONTROL_URI,
          httpMethod: C.HTTP_POST,
          inputParams
        })
      ]
    };
    const rawResponse = await rpc.send(params);
    const parsedResponse = JSON.parse(rawResponse as string);
    if (!parsedResponse.statusCode || parsedResponse.statusCode >= 400) {
      throw parsedResponse;
    }
    return get(parsedResponse, 'hashCode');
  } catch (error) {
    throw error;
  }
};

const requestWhiteboard = async (meetingUUID: string, cb: Function) => {
  try {
    const hashCode = await fetchPluginHashCode({ meetingUUID });
    if (hashCode) {
      cb({ data: hashCode });
    } else {
      cb({ error: WHITEBOARD_ERROR_TYPES.NOT_SUPPORTED }); // meeting ext not found, etc.
    }
  } catch (err) {
    cb({ error: WHITEBOARD_ERROR_TYPES.SERVER }); // server error
  }
};

export { requestWhiteboard };
