import Logger from 'js-logger';
import { getExtensionData, getPSTNData } from 'utils/urlUtils';
import { AppDispatch } from './store';
import { setValidity, setLoading, setHasChecked, setGroup } from 'redux/extensionSlice';
import { setPstnNumbers } from 'redux/pstnNumbersSlice';

export const fetchExtensionData = (extension: string) => {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(setLoading({ loading: true }));
      dispatch(setHasChecked({ hasChecked: false }));
      const { isValid, groupUUID } = await getExtensionData(extension);
      dispatch(setValidity({ valid: isValid }));
      dispatch(setGroup({ groupUUID }));
    } catch (error) {
      Logger.error('Error fetching extension data', error);
    } finally {
      dispatch(setLoading({ loading: false }));
      dispatch(setHasChecked({ hasChecked: true }));
    }
  };
};

export const fetchPSTNData = (groupUUID: string) => {
  return async function (dispatch: AppDispatch) {
    try {
      const { pstnNumbers } = await getPSTNData(groupUUID);
      dispatch(setPstnNumbers(pstnNumbers));
    } catch (error) {
      Logger.error('Error fetching pstn numbers', error);
    }
  };
};
