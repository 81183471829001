import React, { Suspense, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import support from 'support';
import intl from 'react-intl-universal';
import Logger from 'js-logger';
import { colors } from '@lifesize/clients.mui-components';
import { Box, makeStyles, Typography } from '@material-ui/core';
import LoadingAnimation from 'components/Loading';
import JoinButton from 'components/PreJoin/JoinButton';
import JoinFormGreeting from 'components/PreJoin/JoinFormGreeting';
import TermsOfService from 'components/PreJoin/TOS';
import NameInput from 'components/PreJoin/NameInput';
import EmailInput from 'components/PreJoin/EmailInput';
import useForm from 'hooks/useForm';
import { setAttempted } from 'redux/callSlice';
import { RootState } from 'redux/rootReducer';
import { toggleOpen } from 'redux/settingsDrawerSlice';
import { setValidatingExtensionSnackbarOpen } from 'redux/snackbarsSlice';
import { set } from 'redux/userInformationSlice';
import analytics, { GainsightEvents } from 'utils/analytics';
import { startCall } from 'utils/call';
import { validateName, validateEmail } from 'utils/validation';
import { writeToLocalStorage } from 'utils/localStorage/userInformation';

export type UserFormState = {
  name: string;
  email: string;
  returningUser: boolean;
  error?: Map<string, string>;
};

const AudioVideoSettings = React.lazy(() => import('components/AudioVideoSettings/AudioVideoSettings'));

const UserInformationForm = () => {
  const classes = userFieldStyles();
  const dispatch = useDispatch();
  const { tos } = useSelector((state: RootState) => state.compliance);
  const [hasClickedJoinMeeting, setHasClickedJoinMeeting] = useState(false);

  const extensionState = useSelector((state: RootState) => state.extension);
  const userInfo = useSelector((state: RootState) => state.userInformation);
  const isValidatingExtension = useSelector((state: RootState) => state.extension).loading;
  const userFormInfoHook = useForm<UserFormState>({ ...userInfo });
  const state = userFormInfoHook.state;
  const errorMap = userFormInfoHook.validationError;
  const handler = userFormInfoHook.handler;
  const setUserForm = userFormInfoHook.setState;

  const handleJoinMeeting = useCallback(async () => {
    if (errorMap.size || !tos) return;

    setHasClickedJoinMeeting(true);
    const { email, name } = state;
    writeToLocalStorage(name, email);
    support.identify(name, email);
    dispatch(set({ ...state, returningUser: true }));
    setHasClickedJoinMeeting(true);
    await startCall(extensionState.value, { username: state.name, email: state.email });
    dispatch(setAttempted(true));
    analytics.track(GainsightEvents.Call, { extension: extensionState.value });
    Logger.info('Call started');
  }, [dispatch, errorMap.size, tos, state, extensionState.value]);

  function handleSettingsClicked() {
    dispatch(toggleOpen());
  }

  useEffect(() => {
    setHasClickedJoinMeeting(false);
  }, []);

  useEffect(() => {
    dispatch(setValidatingExtensionSnackbarOpen({ open: isValidatingExtension }));
  }, [isValidatingExtension, dispatch]);

  return (
    <>
      <Box className={classes.userDetails}>
        <Box width={'100%'}>
          <JoinFormGreeting
            clickHandler={() => setUserForm({ name: '', email: '', returningUser: false })}
            isReturnUser={state.returningUser}
            name={state.name}
          />
        </Box>
        <>
          <NameInput
            name={state.name}
            error={errorMap.get('name')}
            onChange={handler('name', validateName)}
            handleJoinMeeting={handleJoinMeeting}
          />
          <EmailInput
            email={state.email}
            error={errorMap.get('email')}
            onChange={handler('email', validateEmail)}
            handleJoinMeeting={handleJoinMeeting}
          />
        </>
        <Box mt={2} width={'100%'}>
          <Typography
            className={classes.avSettingsLink}
            component="span"
            onClick={handleSettingsClicked}
            data-testid="avSettings"
          >
            {`${intl.get('audioVideoSettings')} >`}
          </Typography>
          <Suspense fallback={<LoadingAnimation show={true} />}>
            <AudioVideoSettings />
          </Suspense>
        </Box>
        <TermsOfService />
      </Box>
      <JoinButton isCalling={hasClickedJoinMeeting} hasError={!!errorMap.size} handleJoinMeeting={handleJoinMeeting} />
    </>
  );
};

const userFieldStyles = makeStyles(() => ({
  userDetails: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100%',
    width: '500px'
  },
  formField: {
    maxWidth: '500px',
    width: '100%'
  },
  avSettingsLink: {
    color: colors.lifesize,
    cursor: 'pointer'
  },
  validating: {
    minHeight: '28px'
  }
}));

export default UserInformationForm;
