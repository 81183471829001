import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';

import { RootState } from 'redux/rootReducer';
import { setValidatingExtensionSnackbarOpen } from 'redux/snackbarsSlice';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';

export default function ValidatingExtension() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.snackbars.validatingExtensionSnackbarOpen);
  return (
    <Snackbar
      {...defaultProps}
      open={open}
      onClose={() => dispatch(setValidatingExtensionSnackbarOpen({ open: false }))}
    >
      <SnackbarContent
        data-testid="validatingExtension"
        className={classes.message}
        aria-describedby="client-snackbar"
        message={intl.get('validatingExtension')}
      />
    </Snackbar>
  );
}
