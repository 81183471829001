import React, { ReactElement } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import AudioButtonTooltip from 'components/CallControls/AudioButtonTooltip';
import VideoButton from 'components/CallControls/VideoButton';
import PermissionsSnackbar from 'components/Snackbars/Permissions';
import { theme } from 'theme';

const MicAndVideoPreview = (): ReactElement<any, any> => {
  const classes = getStyles({});

  return (
    <Box className={classes.buttonBar}>
      <Box className={classes.microphoneContainer}>
        <AudioButtonTooltip isPreCall={true} />
      </Box>
      <Box>
        <VideoButton />
      </Box>
      <PermissionsSnackbar />
    </Box>
  );
};

const getStyles = makeStyles(() => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  buttonBar: {
    alignItems: 'center',
    animationDelay: '1s',
    animationDuration: '2s',
    animationFillMode: 'forwards',
    animationName: '$fadeIn',
    bottom: 20,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    opacity: 0,
    position: 'absolute',
    right: 0
  },
  microphoneContainer: {
    display: 'flex',
    marginRight: theme.spacing(3)
  }
}));

export default MicAndVideoPreview;
