import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { defaultProps } from './DefaultProps';
import { useStyles } from './Styles';
import { hooks, ObservableEvent } from '@lifesize/clients.sdk';

export const ModeratorEndPresentation = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  hooks.useObservableEvent(ObservableEvent.ModeratorStoppedPresentation, () => setOpen(true));
  return (
    <Snackbar {...defaultProps} open={open} onClose={() => setOpen(false)}>
      <SnackbarContent
        data-testid="moderatorEndPresentation"
        className={classes.message}
        aria-describedby="client-snackbar"
        message={intl.get('moderatorEndPresentation')}
      />
    </Snackbar>
  );
};
