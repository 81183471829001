import { lsGet, LsKey } from './localStorageUtil';
import Logger from 'js-logger';
import { isMobile } from './browserDetection';

export const getReceiveStreams = () => {
  const receiveStreamOverride = lsGet(LsKey.ReceiveStreams);
  if (receiveStreamOverride) {
    Logger.info(`receiveStreams override:${receiveStreamOverride}`);
    return parseInt(receiveStreamOverride);
  }

  if (isMobile()) return 1;

  if (navigator.hardwareConcurrency <= 4) {
    return 1;
  }

  return 9;
};
