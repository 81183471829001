import { getAfterCallLink } from './localeUtil';
import { LsKey, lsGet } from './localStorageUtil';

const navigateToTrialPage = () => {
  return new Promise((resolve) => {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      // Guarantee the browser unload by removing the returnValue property of the event
      delete e['returnValue'];
      resolve();
    };
    if (lsGet(LsKey.SkipTrialNavigationForTest)) window.location.assign(window.location.href);
    else window.location.assign(getAfterCallLink());
  });
};

export { navigateToTrialPage };
