import React from 'react';
import intl from 'react-intl-universal';
import clsx from 'clsx';
import { hooks } from '@lifesize/clients.sdk';
import { colors } from '@lifesize/clients.mui-components';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { callReasons, callState } from 'constants/constants';

const getDisplayText = (state: string, reason: string) => {
  if (callState.calling.includes(state)) return intl.get('callStateCalling');
  if (callState.disconnected.includes(state)) {
    if (callReasons.busy.includes(reason)) return intl.get('callReasonBusy');
    if (callReasons.unreachable.includes(reason)) return intl.get('callReasonUnreachable');
    return intl.get('callStateDisconnected');
  }
  return null;
};

const CallStatus = () => {
  const call = hooks.useCallState();
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div id="myNav" className={classes.overlay}>
      <div data-testid="callStatus" className={clsx(classes.overlayContent, classes.status)}>
        {getDisplayText(call.state, call.reason)}
      </div>
      <div data-testid="callerName" className={classes.overlayContent}>
        {call.dialString}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  overlay: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    'z-index': 888,
    left: 0,
    top: 0,
    'background-color': 'rgba(0,0,0, 0.45)',
    'overflow-x': 'hidden'
  },
  overlayContent: {
    fontFamily: theme.typography.fontFamily,
    color: colors.nimbusCloud,
    position: 'relative',
    width: '100%',
    'text-align': 'center',
    marginTop: '4px'
  },
  status: {
    fontSize: '1.5rem',
    marginTop: '24px'
  }
}));

export default CallStatus;
