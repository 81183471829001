import { RootState } from 'redux/rootReducer';
import { useSelector } from 'react-redux';

const useFeatureFlag = (featureFlag: string): boolean => {
  const featureFlagState = useSelector((state: RootState) => state.features);
  if (featureFlagState?.featureFlagObject) {
    const foundFlag = featureFlagState.featureFlagObject.find((flag: any) => flag.name === featureFlag);
    return foundFlag?.value || false;
  }
  return false;
};

export default useFeatureFlag;
