import React from 'react';
import { Input as TextInput } from '@lifesize/clients.mui-components';
import { Box, makeStyles } from '@material-ui/core';
import intl from 'react-intl-universal';

interface Props {
  handleJoinMeeting: Function;
  name: string;
  error: string | undefined;
  onChange: any;
}

const NameInput = ({ handleJoinMeeting, name, error, onChange }: Props) => {
  const classes = userFieldStyles();

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      handleJoinMeeting();
    }
  };

  return (
    <Box mt={2} width={'100%'} data-testid="name">
      <TextInput
        error={!!error}
        autoFocus
        helperText={error}
        autoComplete={'name'}
        className={classes.formField}
        onKeyDown={onKeyDown}
        placeholder={intl.get('name')}
        variant="outlined"
        value={name}
        onChange={onChange}
        onBlur={onChange}
      />
    </Box>
  );
};

const userFieldStyles = makeStyles(() => ({
  formField: {
    maxWidth: '500px',
    width: '100%'
  }
}));

export default NameInput;
