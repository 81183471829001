import { createMuiTheme, Theme } from '@material-ui/core';
import { colors, muiTheme } from '@lifesize/ux-assets';
import _merge from 'lodash/merge';

// override for consistency with previous theme just so we don't have to restyle the whole app
muiTheme.typography = _merge(muiTheme.typography, {
  htmlFontSize: 10,
  fontSize: 14,
  body1: {
    fontSize: '1.4rem'
  },
  body2: {
    fontSize: '1.4rem'
  },
  button: {
    fontSize: '1.6rem',
    textTransform: 'none'
  },
  h1: {
    fontSize: '3.2rem'
  },
  h2: {
    fontSize: '2.4rem'
  },
  h3: {
    fontSize: '2.0rem'
  },
  h4: {
    fontSize: '1.8rem'
  },
  h5: {
    fontSize: '1.6rem'
  },
  h6: {
    fontSize: '1.4rem'
  },
  subtitle1: {
    color: colors.slate,
    fontSize: '1.6rem'
  }
});

muiTheme.overrides = _merge(muiTheme.overrides, {
  MuiTooltip: {
    tooltip: {
      fontSize: '1.2rem'
    }
  }
});

export const theme: Theme = createMuiTheme(muiTheme);
