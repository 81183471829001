import React, { useRef, useEffect } from 'react';
import intl from 'react-intl-universal';
import { makeStyles, useTheme } from '@material-ui/core';
import ChatItem from './ChatItem';
import ChatInput from './ChatInput';

interface Props {
  autoScrollIsPaused: boolean;
  bookmark: number;
  chatRoomUUID: string | null;
  chats: any[];
  open: boolean;
  previouslyOpen: boolean;
  setAutoScrollIsPaused: Function;
  setBookmark: Function;
  setLastReadMessage: Function;
  unreadMsgCount: number;
}

const ChatWindow = (props: Props) => {
  const {
    autoScrollIsPaused,
    bookmark,
    chatRoomUUID,
    chats,
    open,
    previouslyOpen,
    setAutoScrollIsPaused,
    setBookmark,
    setLastReadMessage
  } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const messagesEndRef = useRef<any>(null);
  const chatContainer = useRef<any>(null);

  // when you submit a chat message, enable autoscrolling so that it will automatically scroll into view
  const handleSubmit = () => {
    setAutoScrollIsPaused(false);
  };

  // when the chat window is open and a new message is received, scroll it into view
  useEffect(() => {
    if (!autoScrollIsPaused) {
      messagesEndRef?.current?.scrollIntoView();
      setLastReadMessage(chats.length ? chats[0].createdAt : 0);
    }
  }, [autoScrollIsPaused, chats, messagesEndRef, setLastReadMessage]);

  // when the chat window is open and...
  // - you scroll to the bottom, acknowledge that all messages have been read / viewed
  // - you scroll up, disable autoscrolling to the bottom
  useEffect(() => {
    const chatWindow = chatContainer.current;

    const handleScroll = () => {
      setBookmark(chatWindow.scrollTop);
      // handles a user scroll, not a scroll triggered by js
      // scrollHeight:  the height of the inner scrollable contents (the height of chatList, even though part of it may be hidden)
      // scrollTop: amount scrolled from the top
      // clientHeight: scrollable window height (the height of chatlistHolder)
      const marginOfError = 20; // the number of pixes from the bottom considered to be close enough to the bottom
      const isScrolledToBottom =
        chatWindow.scrollTop + chatWindow.clientHeight + marginOfError >= chatWindow.scrollHeight;
      setAutoScrollIsPaused(!isScrolledToBottom);
      if (isScrolledToBottom) setLastReadMessage(chats.length ? chats[0].createdAt : 0);
    };

    chatWindow?.addEventListener('scroll', handleScroll);
    return () => {
      chatWindow?.removeEventListener('scroll', handleScroll);
    };
  }, [chatContainer, chats, open, setAutoScrollIsPaused, setBookmark, setLastReadMessage]);

  useEffect(() => {
    if (open && !previouslyOpen) {
      if (autoScrollIsPaused) {
        // if you open the chat window and autoScroll is paused, scroll to the last bookmark
        chatContainer.current.scrollTop = bookmark;
      } else {
        // if you open the chat window and autoScroll is not paused, scroll to the last message
        setLastReadMessage(chats.length ? chats[0].createdAt : 0);
        messagesEndRef?.current?.scrollIntoView();
      }
    }
  }, [autoScrollIsPaused, bookmark, chats, open, previouslyOpen, setLastReadMessage]);

  return (
    <div className={classes.container}>
      <h2 className={classes.header} data-testid="chatTitle">
        {intl.get('chat')}
      </h2>
      <div className={classes.chatlistHolder} ref={chatContainer}>
        <div className={classes.chatlist}>
          <div ref={messagesEndRef} />
          {chats.map((c: any, i: any, allChats: any) => (
            <ChatItem key={`chat-${i}`} chat={c} nextChat={allChats[i + 1]} />
          ))}
        </div>
      </div>
      <ChatInput chatRoomUUID={chatRoomUUID} onSubmit={handleSubmit} />
    </div>
  );
};

const useStyles = makeStyles<any, any>((theme) => ({
  container: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.2rem',
    height: '100%',
    margin: `0 ${theme.spacing(3)}px`,
    maxWidth: '280px',
    minWidth: '280px',
    textAlign: 'left'
  },
  chatlist: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end'
  },
  chatlistHolder: {
    flex: '1 1 auto',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    overflowAnchor: 'none', // important for Chrome to keep the scrollbar (and content) from moving unexpectedly
    scrollBehavior: 'smooth'
  },
  header: {
    flex: '0 0 auto',
    fontWeight: 500
  }
}));

export default ChatWindow;
