import autobahn from 'autobahn';
import { sdk, connection } from '@lifesize/clients.sdk';
import { lsGet, LsKey } from './localStorageUtil';
import { getLegacyLanguageId } from './localeUtil';
import { getConstraints } from './devicesUtils';
import support from 'support';
import { getEndpointBrowserString, isIosMobile } from './browserDetection';
import { getReceiveStreams } from './capabilities';
import _get from 'lodash/get';
import { constants as c, signaling } from '@lifesize/clients.sdk';
import { selectors } from '@lifesize/clients.sdk';

// set mobile viewport height to account for url bar
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', setVh);
setVh();

const getLogging = () => {
  return lsGet(LsKey.EnableLogging) === 'true';
};

export const init = (store: any) => {
  sdk.initialize();
  sdk.enableConnect(autobahn, {});
  connection.setAutoRetry(true);
  sdk.enableSignaling({
    audioInPresentation: true,
    locale: getLegacyLanguageId(),
    enableLogging: getLogging(),
    endpoint: {
      info: `Guest Web App (${support.version}) ${getEndpointBrowserString()}`
    },
    receiveStreams: getReceiveStreams(),
    sdpOptions: {
      preferH264: true,
      preferredProfile: '42e01f'
    }
  });

  const tryEndCall = async () => {
    const callState = selectors.callState();
    if (callState === c.CALL_STATE_CONNECTED) {
      await signaling.endCall();
    }
  };

  const onPageHideHandler = async (e: any) => {
    const pageIsBeingDiscarded = !_get(e, 'persisted', false);
    // This makes sure that the user isn't just backgrounding the guest flow
    if (pageIsBeingDiscarded) {
      // the page is being discarded, end the call.
      await tryEndCall();
    }
    return true;
  };

  const onBeforeUnloadHandler = async () => {
    // the page is being discarded, end the call.
    await tryEndCall();
    return true;
  };

  // Attempting to end the call if the user clicks the close button on the window.
  // If on iOS use pagehide as a substitue for beforeunload
  if (isIosMobile()) {
    window.addEventListener('pagehide', onPageHideHandler);
  }
  window.addEventListener('beforeunload', onBeforeUnloadHandler);

  const constraints = getConstraints(store.getState().mediaSettings);
  sdk.enableMedia({
    mediaDevicePreferences: {
      pollForNewDevices: true,
      autoCaptureNewDevices: true,
      pollPeriod: 2000
    },
    mutePreferences: {
      videoMuteSlide: null,
      useVideoMuteSlide: true,
      stopVideoTrackOnMute: true,
      useAudioTrackEnabled: true
    },
    defaultConstraints: constraints
  });
};
