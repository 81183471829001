import { createSlice } from '@reduxjs/toolkit';

export interface SnackbarInterface {
  connectionLostOpen: boolean;
  connectionRestoredOpen: boolean;
  permissionsSnackbarOpen: boolean;
  presentationErrorSnackbarOpen: boolean;
  validatingExtensionSnackbarOpen: boolean;
  presentationErrorSnackbarMessage: string;
}

const initialState: SnackbarInterface = {
  connectionLostOpen: false,
  connectionRestoredOpen: false,
  permissionsSnackbarOpen: false,
  presentationErrorSnackbarOpen: false,
  validatingExtensionSnackbarOpen: false,
  presentationErrorSnackbarMessage: ''
};

const snackbarsSlice = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    setConnectionLostSnackbarOpen(state, action) {
      state.connectionLostOpen = action.payload.open;
    },
    setConnectionRestoredSnackbarOpen(state, action) {
      state.connectionRestoredOpen = action.payload.open;
    },
    setPermissionsSnackbarOpen(state, action) {
      state.permissionsSnackbarOpen = action.payload.open;
    },
    setPresentationErrorSnackbarOpen(state, action) {
      state.presentationErrorSnackbarOpen = action.payload.open;
    },
    setValidatingExtensionSnackbarOpen(state, action) {
      state.validatingExtensionSnackbarOpen = action.payload.open;
    }
  }
});

export const {
  setConnectionLostSnackbarOpen,
  setConnectionRestoredSnackbarOpen,
  setPermissionsSnackbarOpen,
  setPresentationErrorSnackbarOpen,
  setValidatingExtensionSnackbarOpen
} = snackbarsSlice.actions;

export default snackbarsSlice.reducer;
