export const getDuration = (startTime: number) => {
  const seconds = Math.round((Date.now() - startTime) / 1000);
  const minutes = parseInt((seconds / 60).toString(), 10);
  const hours = parseInt((seconds / 3600).toString(), 10);

  const strMin = String(`00${minutes % 60}`).slice(-2);
  const strSec = String(`00${seconds % 60}`).slice(-2);
  if (hours) {
    return `${hours}:${strMin}:${strSec}`;
  }
  return `${strMin}:${strSec}`;
};
