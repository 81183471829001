import Logger from 'js-logger';
import React, { useEffect, useRef } from 'react';
import _get from 'lodash/get';
import busySignal from '@lifesize/ux-assets/audio/busy-signal.mp3';
import outgoingRing from '@lifesize/ux-assets/audio/outgoing-ring.mp3';

// use outgoing to test the audio out (speaker) selection
// there is no incomingRing in the guest flow

export enum RingType {
  busy = 'busy',
  outgoing = 'outgoing'
}

interface Props {
  deviceId?: string;
  ringType?: RingType;
}

export default function RingTone(props: Props) {
  const AudioEl = useRef<any>(null);
  const ringType = props.ringType || RingType.outgoing;
  const ringTone = ringType === RingType.outgoing ? outgoingRing : busySignal;

  useEffect(() => {
    const AudioElement = AudioEl.current;
    if (AudioElement) {
      const playPromise = AudioElement.play();
      if (playPromise) {
        playPromise.catch((error: any) => Logger.error('Error occurred while playing Ringtone', error));
      }
      AudioElement.src = ringTone;

      if (props.deviceId && _get(AudioElement, 'setSinkId')) {
        AudioElement.setSinkId(props.deviceId);
      }
    }

    return () => {
      if (AudioElement) {
        AudioElement.pause();
        AudioElement.src = '';
      }
    };
  }, [AudioEl, ringTone, props.deviceId]);

  return <audio data-testid="audioRingTone" autoPlay loop preload="auto" ref={AudioEl} />;
}
