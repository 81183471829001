import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Box } from '@material-ui/core';
import { hooks } from '@lifesize/clients.sdk';
import { RootState } from 'redux/rootReducer';
import { getDevicesByKind, MediaConfig } from 'utils/devicesUtils';

import { defaultResolutionLabel, resolutionMap } from 'utils/videoResolution';
import SettingDropdown from './SettingDropdown';

interface Props {
  handleConfigChange: (key: keyof MediaConfig, value: string) => void;
}

const ComputerVideoSettings = (props: Props) => {
  const resolutionLabels = new Map([
    ['default', intl.get('resolutionDefault', { resolution: defaultResolutionLabel })],
    ['2160p', intl.get('resolution4k')],
    ['1080p', intl.get('resolutionFullHighDef')],
    ['720p', intl.get('resolutionHighDef')],
    ['360p', intl.get('resolution360p')]
  ]);
  const devices = hooks.useDevices();

  const videoInputDevices = useMemo(() => getDevicesByKind(devices, 'videoinput'), [devices]);
  const resolutions = useMemo(
    () => Object.values(resolutionMap).map((r) => ({ label: resolutionLabels.get(r.value) || '', value: r.value })),
    [resolutionLabels]
  );
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);
  const { videoDevice } = hooks.useMedia();

  const getDeviceValue = useCallback(() => {
    if (videoInputDevices && videoDevice?.deviceId) {
      return videoDevice.deviceId;
    }
    return 'NA';
  }, [videoInputDevices, videoDevice]);

  useCallback(() => {
    props.handleConfigChange('camera', getDeviceValue());
  }, [props, getDeviceValue]);

  return (
    <>
      <Box mt={3} data-testid="cameraSettings">
        <SettingDropdown
          handleChange={(value) => props.handleConfigChange('camera', value)}
          items={videoInputDevices}
          label={intl.get('camera')}
          value={getDeviceValue()}
        />
      </Box>
      <Box mt={3} data-testid="resolutionSettings">
        <SettingDropdown
          handleChange={(value) => props.handleConfigChange('resolution', value)}
          items={resolutions}
          label={intl.get('resolution')}
          value={mediaSettings.resolution}
        />
      </Box>
    </>
  );
};

export default ComputerVideoSettings;
