import React from 'react';
import ModeratorMutedAllSnackbar from 'components/Snackbars/ModeratorMutedAll';
import ModeratorUnmutedAllLocalMuteSnackbar from 'components/Snackbars/ModeratorUnmutedAllLocalMute';
import ModeratorUnmutedAllSnackbar from 'components/Snackbars/ModeratorUnmutedAll';
import ModeratorMuteParticipantSnackbar from 'components/Snackbars/ModeratorMuteParticipant';
import ModeratorUnmuteParticipantLocalMuteSnackbar from 'components/Snackbars/ModeratorUnmuteParticipantLocalMute';
import ModeratorUnmuteParticipantSnackbar from 'components/Snackbars/ModeratorUnmuteParticipant';
import PermissionsSnackbar from 'components/Snackbars/Permissions';
import PresentationErrorSnackbar from 'components/Snackbars/PresentationError';
import RaiseHandsSnackbar from 'components/Snackbars/RaiseHands';
import VbbSnackbar from 'components/Snackbars/Vbb';
import WhiteboardSnackbar from 'components/Snackbars/Whiteboard';
import useHasRaisedHandFeature from 'hooks/useHasRaisedHandFeature';
import { ModeratorEndPresentation } from 'components/Snackbars/ModeratorEndPresentation';

export default function InCallSnackbars() {
  const hasRaisedHandFeature = useHasRaisedHandFeature();

  return (
    <>
      <ModeratorMutedAllSnackbar />
      <ModeratorUnmutedAllSnackbar />
      <ModeratorUnmutedAllLocalMuteSnackbar />
      <ModeratorMuteParticipantSnackbar />
      <ModeratorUnmuteParticipantSnackbar />
      <ModeratorUnmuteParticipantLocalMuteSnackbar />
      <ModeratorEndPresentation />
      <PermissionsSnackbar />
      <PresentationErrorSnackbar />
      {hasRaisedHandFeature && <RaiseHandsSnackbar />}
      <VbbSnackbar />
      <WhiteboardSnackbar />
    </>
  );
}
