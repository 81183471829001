import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { makeStyles, Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { colors } from '@lifesize/clients.mui-components';
import { PSTNObject } from 'utils/pstn';

interface Props {
  extension: string;
  pstnNumbers: PSTNObject;
}

const PSTNSettings = (props: Props) => {
  const classes = useStyles({});
  const [showAdditionalNumbers, setShowAdditionalNumbers] = useState(false);
  return (
    <>
      <Box mt={3} data-testid="pstnSettings">
        <List
          subheader={
            <Typography className={classes.pstnExt} component="span" data-testid="pstnSubHeader">
              {intl.get('pstnNumberSelectorLabel')}
              <br />
              {intl.get('enterExtensionPrompt', { extension: props.extension })}
            </Typography>
          }
        >
          {props?.pstnNumbers?.customNumbers?.map((item: any, index: number) => (
            <ListItem key={`listItem${index}`} data-testid="pstnNumbers">
              <ListItemIcon>
                <Box
                  className={classes.flagIcon}
                  mr={1}
                  style={{ backgroundImage: `url(${item.icon})` }}
                  data-testid="flag"
                />
              </ListItemIcon>
              <ListItemText id={`listItem${index}`} primary={item.label} data-testid="pstnNumber" />
            </ListItem>
          ))}
          {!showAdditionalNumbers && props?.pstnNumbers?.allNumbers?.length ? (
            <Box
              className={classes.additionalNumbers}
              onClick={() => setShowAdditionalNumbers(!showAdditionalNumbers)}
              ml={2}
            >
              <Typography data-testid="showAdditionalNumbers">
                {intl.get('showAdditionalNumbers').d('Show Additional Numbers')}
              </Typography>
            </Box>
          ) : null}
          {showAdditionalNumbers &&
            props.pstnNumbers.allNumbers.map((item: any, index: number) => (
              <ListItem key={`listItem${index}`} data-testid="pstnNumbers">
                <ListItemIcon>
                  <Box
                    className={classes.flagIcon}
                    mr={1}
                    style={{ backgroundImage: `url(${item.icon})` }}
                    data-testid="flag"
                  />
                </ListItemIcon>
                <ListItemText id={`listItem${index}`} primary={item.label} data-testid="pstnNumber" />
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  pstnExt: {
    color: colors.charcoal,
    fontSize: '1.4rem'
  },
  flagIcon: {
    backgroundSize: 'contain',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    height: '1em',
    verticalAlign: 'top',
    width: `${4 / 3}em`
  },
  additionalNumbers: {
    display: 'flex',
    flexShrink: 0,
    '& p': {
      color: colors.lifesize,
      fontSize: '1.6rem'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default PSTNSettings;
