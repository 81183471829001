import { lsGet, LsKey, lsSet } from 'utils/localStorageUtil';

export function readFromLocalStorage() {
  const cookies = lsGet(LsKey.CookiesAccepted);
  const tos = lsGet(LsKey.TosAccepted);
  return {
    cookies: cookies || '',
    tos: tos || ''
  };
}

// If the Cookie policy or TOS ever changes, it may be usefult to know when the user last accepted the terms

export function storeCookieAcceptance() {
  const timeStamp = Date.now().toString();
  lsSet(LsKey.CookiesAccepted, timeStamp);
  return timeStamp;
}

export function storeTosAcceptance(isAccepted: boolean) {
  const timeStamp = isAccepted ? Date.now().toString() : '';
  lsSet(LsKey.TosAccepted, timeStamp);
  return timeStamp;
}
