import { VirtualBackgroundRenderer } from '@lifesize/virtual-background';
import Logger from 'js-logger';
import { setEnabled, setEnabling, setBackground, bgSelectionType } from 'redux/vbbSettingsSlice';
import { media } from '@lifesize/clients.sdk';
import { getConstraints } from './devicesUtils';

// NOTE: below are the render options used for @lifesize/virtual-background@0.2.6
// If you revert the package to that version, it's recommended to use the below settings.
// const defaultRenderOptions = {
//   minBodypixFramePeriod: 500,
//   minFramePeriod: 0,
//   backgroundColor: '#444',
//   backgroundMode: 'contain' // can also be 'fill' and 'cover'
// };

class VbbManager {
  private static _instance: VbbManager;

  #vbRenderer?: VirtualBackgroundRenderer;
  #initialized = false;

  private constructor() {}

  public static getInstance(): VbbManager {
    if (!this._instance) {
      this._instance = new VbbManager();
    }

    return this._instance;
  }

  private async setBackgroundImage(bgSelection: bgSelectionType) {
    const src = bgSelection.path || bgSelection.dataUrl;

    if (this.#vbRenderer === undefined) {
      Logger.error('VbRenderer is undefined when trying to assign background!');
      return;
    }

    // JEFFTODO: constants, or a better way to do this.
    if (bgSelection.id === 'blur') {
      await this.#vbRenderer.setBackground(5);
      return;
    }

    // convert src (path or dataUrl) to ImageData (there will be a src for all except 'none' && 'blur')
    // note: unfortunately we cannot store ImageData in the redux state (serialization error), so we have to fetch it each time
    if (src) {
      const img = new Image();

      img.onload = () => {
        const canvas = new OffscreenCanvas(img.width, img.height);
        const context = canvas.getContext('2d');

        if (context === null) {
          Logger.error('Could not acquire canvas to set bg image!');
          return;
        }

        // TODO: if necessary (if the size of the uploaded images becomes problematic), this would be a good place to crop and/or resize
        context.drawImage(img, 0, 0); // currently, we are drawing the full image (not cropping or resizing), so the width and height are not necessary
        this.#vbRenderer?.setBackground(context.getImageData(0, 0, img.width, img.height));
      };

      img.src = src;
    }
  }

  public async handleVideoMuteUnmute(
    dispatch: any,
    videoMuted: boolean,
    vbbEnabled: boolean,
    bgSelection: bgSelectionType,
    mediaSettings: any
  ) {
    if (vbbEnabled) {
      // If the video is muted, stop vbb but don't disable the feature.
      // Don't reacquire the camera, since that would show video.
      if (videoMuted) {
        await this.pauseVbb(mediaSettings, false);
      } else {
        Logger.debug('Enabling vbb on video unmute...', bgSelection.id);
        await this.enableVbb(dispatch, mediaSettings, bgSelection);
      }
      // JEFFTODO: think about how to handle if vbbEnabling...? Race condition?
    }
  }

  public async handleCameraChange(
    dispatch: any,
    videoMuted: boolean,
    vbbEnabled: boolean,
    bgSelection: bgSelectionType,
    mediaSettings: any
  ) {
    // If video muted, we don't need to do anything
    if (!vbbEnabled || videoMuted) return;

    // Otherwise, video unmuted and vbb enabled - let's reacquire camera.
    Logger.debug('Enabling vbb on video unmute...', bgSelection.id);
    await this.enableVbb(dispatch, mediaSettings, bgSelection);
  }

  // This is to be called by the client component after they choose one of the image options
  public async chooseBackgroundSetting(
    dispatch: any,
    videoMuted: boolean,
    vbbEnabled: boolean,
    bgSelection: bgSelectionType,
    mediaSettings: any
  ) {
    //
    await dispatch(setBackground(bgSelection));

    // If video is currently muted, then we don't need to do anything more right now.
    if (videoMuted) {
      Logger.debug('Video currently muted, setting value and returning.');
      dispatch(setEnabled(bgSelection.id !== 'none')); // JEFFTODO: clean up that check
      return;
    }

    if (bgSelection.id === 'none') {
      if (vbbEnabled) {
        Logger.debug('Disabling VBB...');
        await this.disableVbb(dispatch, mediaSettings);
      } else {
        Logger.debug('Vbb not enabled, nothing to do.');
      }
      return;
    }

    if (!vbbEnabled) {
      Logger.debug('VBB not yet enabled, enabling!');
      await this.enableVbb(dispatch, mediaSettings, bgSelection);
    } else {
      Logger.debug('VBB already enabled, updating background image.');
      await this.updateVbb(bgSelection);
    }
  }

  public async initializeVbb() {
    if (!this.#initialized) {
      Logger.debug('Initializing...');
      await VirtualBackgroundRenderer.setup({
        modelUrl: `${process.env.NODE_ENV === 'production' ? process.env.PUBLIC_URL : window.location.origin}/model.147b9847.json`
      });
      this.#initialized = true;
      Logger.debug('Init done.');
    }
  }

  public async enableVbb(dispatch: any, mediaSettings: any, bgSelection: bgSelectionType, renderOptions?: any) {
    try {
      dispatch(setEnabling(true));

      await this.initializeVbb();

      // Reaquire at a lower resolution
      await this.reAcquireCamera({ ...mediaSettings, resolution: '360p' });
      const localPrimaryStream = media.getPrimaryStream();

      // Create the new stream
      Logger.debug('Getting video tracks.');
      const videoTrack = localPrimaryStream.getVideoTracks()[0];

      // Create renderer
      this.#vbRenderer = new VirtualBackgroundRenderer(videoTrack);
      // issues can happen if you don't get the track right after making the vbRenderer
      // NOTE: This new video track /will have a different device label than the original one!/
      //       That means anything that tries to match devies by the label will fail, e.g. camera picker.
      const newVideo = await this.#vbRenderer.getTrack();
      Logger.debug('tracks retrieved', videoTrack, this.#vbRenderer);

      // Use any new VBB Render options if provided.
      if (renderOptions) {
        this.#vbRenderer.setOptions(renderOptions);
      }

      // Set the new stream
      Logger.debug('removing tracks and setting new');
      localPrimaryStream.removeTrack(videoTrack);

      // Set bg
      await this.setBackgroundImage(bgSelection);

      Logger.debug('gonna add track', newVideo);
      localPrimaryStream.addTrack(newVideo);

      // Notify clients.sdk
      Logger.debug('notifying of stream change');
      await media.setStream(localPrimaryStream);

      dispatch(setEnabled(true));
      dispatch(setEnabling(false));
    } catch (err) {
      Logger.error('Error encountered, reverting', err);
      dispatch(setEnabled(false));
      dispatch(setEnabling(false));
    }
  }

  // Will update the existing background selection live
  public async updateVbb(bgSelection: bgSelectionType) {
    Logger.debug('Setting background image...', bgSelection.id);
    await this.setBackgroundImage(bgSelection);
    Logger.debug('Background now set.');
  }

  public async disableVbb(dispatch: any, mediaSettings: any, reInitCamera = true) {
    try {
      await this.pauseVbb(mediaSettings, reInitCamera);
    } catch (pauseErr) {
      Logger.error('pausing VBB failed', pauseErr);
    }
    dispatch(setEnabled(false));
  }

  public async pauseVbb(mediaSettings: any, reInitCamera = true) {
    // Do nothing if never enabled
    if (!this.#initialized || !this.#vbRenderer) return;

    if (reInitCamera) {
      await this.reAcquireCamera(mediaSettings);
    }

    Logger.debug('destroying existing renderer');
    return await this.#vbRenderer.destroy();
  }

  public async teardownVbb(mediaSettings: any, reInitCamera = true) {
    // Do nothing if never enabled
    if (!this.#initialized) return;

    Logger.debug('Tearing down VbbManager.');
    await VirtualBackgroundRenderer.teardown();

    if (reInitCamera) {
      await this.reAcquireCamera(mediaSettings);
    }

    this.#initialized = false;
  }

  private async reAcquireCamera(mediaSettings: any) {
    Logger.debug('reacquiring camera');
    await media.acquireWebcam(getConstraints(mediaSettings));
  }
}

const vbbInstance = VbbManager.getInstance();

export { vbbInstance as vbbManager };
