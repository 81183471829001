import React, { useCallback, useEffect, useMemo, useState, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import { hooks, media } from '@lifesize/clients.sdk';
import { colors } from '@lifesize/clients.mui-components';
import useAudioMonitor from 'hooks/useAudioMonitor';
import { RootState } from 'redux/rootReducer';
import { set as updateSettings } from 'redux/mediaSettingsSlice';
import { setPermissionsSnackbarOpen } from 'redux/snackbarsSlice';
import { getAudioTooltipText } from 'utils/tooltipUtils';
import { getDevicesByKind } from 'utils/devicesUtils';
import { isSpeaking } from 'utils/audioMonitorUtils';
import SpeakingWhileMutedTooltip from 'components/Tooltips/SpeakingWhileMutedTooltip';
import usePrevious from 'hooks/usePrevious';
import AudioButton from './AudioButton';
import useCurrentUserMuteState from 'hooks/useCurrentUserMuteState';

interface Props {
  disabled?: boolean;
  isHidden?: boolean;
  onForceVisible?: (flag: boolean) => void;
  isPreCall?: boolean;
}

interface StyleConfig {
  isNotification: boolean;
}

let notificationTimeout: ReturnType<typeof setTimeout>;
const notificationDuration = 4000;

const AudioButtonTooltip = ({ disabled, isHidden, onForceVisible, isPreCall }: Props): ReactElement<any, any> => {
  const dispatch = useDispatch();

  const [isSpeakingNow, setIsSpeakingNow] = useState(false);
  const [hasDissmissedMutedWarning, setHasDissmissedMutedWarning] = useState(false);
  const [isSpeakingWhileMuted, setIsSpeakingWhileMuted] = useState(false);

  const devices = hooks.useDevices();
  const audioInputDevices = useMemo(() => getDevicesByKind(devices, 'audioinput'), [devices]);

  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);
  const usePhoneAudio = mediaSettings.audio === 'phone'; // pstn call
  const serverMuteState = useCurrentUserMuteState();

  const { localAudioMuted } = hooks.useMedia();
  const previousLocalAudioMuted = usePrevious(localAudioMuted);

  const tooltipPopperProps =
    isSpeakingWhileMuted && !disabled
      ? { open: true } // forces the tooltip open when there is a notification
      : isHidden || disabled
      ? { open: false } // forces the tooltip closed when the ButtonBar is idle (hidden) or the button is disabled (call is connecting)
      : {}; // behave normally if no exception condition is met

  const sampleData = useAudioMonitor();

  const tooltipClasses = useStylesTooltip({ isNotification: isSpeakingWhileMuted });

  const micBtnDisabled = serverMuteState || !!disabled;
  const micBtnMuted = serverMuteState || localAudioMuted || !audioInputDevices.length;

  // Handler for when a user clicks this button to mute/unmute themselves
  const handleClick = useCallback(() => {
    if (micBtnDisabled) return;
    if (!audioInputDevices.length) {
      dispatch(setPermissionsSnackbarOpen({ open: true }));
    } else {
      if (usePhoneAudio) {
        // skip 'writeToLocalStorage()': there is no need to persist the value change since 'audio' is not persisted
        // skip 'StreamManager.configure()': the 'audio' value has no effect on the configuration
        // set the global state
        dispatch(updateSettings({ audio: 'computer', localAudioMuted: false }));
        media.setAudioMute(false);
      } else {
        dispatch(updateSettings({ localAudioMuted: !localAudioMuted }));
        media.setAudioMute(!localAudioMuted);
      }
    }
  }, [audioInputDevices.length, dispatch, usePhoneAudio, localAudioMuted, micBtnDisabled]);

  // Handler for when a user clicks dismiss on the muted while speaking tooltip
  const handleDismissTooltip = useCallback(() => {
    clearTimeout(notificationTimeout);
    if (onForceVisible) {
      onForceVisible(false); // fake activity to unhide the ButtonBar
    }
    setIsSpeakingWhileMuted(false);
    setHasDissmissedMutedWarning(true);
  }, [onForceVisible]);

  // Handler for when we detect a user is speaking while muted
  const handleSpeakingWhileMuted = useCallback(() => {
    clearTimeout(notificationTimeout);
    if (onForceVisible) {
      onForceVisible(true); // unhide the ButtonBar
    }
    setIsSpeakingWhileMuted(true);
    notificationTimeout = setTimeout(() => {
      if (onForceVisible) {
        onForceVisible(false);
      }
      setIsSpeakingWhileMuted(false);
    }, notificationDuration);
  }, [onForceVisible]);

  const shouldStartTrackingIsSpeaking = useCallback(() => {
    return localAudioMuted && !hasDissmissedMutedWarning && !disabled && !isPreCall;
  }, [localAudioMuted, hasDissmissedMutedWarning, disabled, isPreCall]);

  const shouldResetShowingMutedWarning = useCallback(() => {
    return localAudioMuted && !previousLocalAudioMuted;
  }, [localAudioMuted, previousLocalAudioMuted]);

  const shouldShowIsSpeakingWhileMutedWarning = useCallback(() => {
    return isSpeakingNow && localAudioMuted && !serverMuteState;
  }, [localAudioMuted, isSpeakingNow, serverMuteState]);

  useEffect(() => {
    if (shouldResetShowingMutedWarning()) {
      setHasDissmissedMutedWarning(false);
    }
    if (shouldStartTrackingIsSpeaking()) {
      isSpeaking(sampleData, setIsSpeakingNow);
    }
    if (shouldShowIsSpeakingWhileMutedWarning()) {
      handleSpeakingWhileMuted();
    }
  }, [
    handleSpeakingWhileMuted,
    sampleData,
    shouldResetShowingMutedWarning,
    shouldStartTrackingIsSpeaking,
    shouldShowIsSpeakingWhileMutedWarning
  ]);

  const tooltipContent = isSpeakingWhileMuted ? (
    <SpeakingWhileMutedTooltip onClick={handleClick} onDismissTooltip={handleDismissTooltip} />
  ) : (
    getAudioTooltipText(serverMuteState, localAudioMuted, usePhoneAudio)
  );

  return (
    <Tooltip
      arrow={isSpeakingWhileMuted}
      classes={tooltipClasses}
      title={tooltipContent || false}
      PopperProps={tooltipPopperProps}
    >
      <AudioButton onClick={handleClick} disabled={micBtnDisabled} muted={micBtnMuted} />
    </Tooltip>
  );
};

const useStylesTooltip = makeStyles((theme: Theme) => ({
  tooltip: (config: StyleConfig) =>
    config.isNotification
      ? {
          backgroundColor: theme.palette.background.default,
          color: colors.black,
          maxWidth: 'none',
          minWidth: 220,
          padding: theme.spacing(2),
          textAlign: 'left',
          width: 'min-content'
        }
      : {},
  arrow: (config: StyleConfig) =>
    config.isNotification
      ? {
          color: theme.palette.background.default
        }
      : {},
  popper: (config: StyleConfig) =>
    config.isNotification
      ? {
          pointerEvents: 'auto',
          zIndex: 1200
        }
      : {}
}));

export default AudioButtonTooltip;
