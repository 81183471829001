import React from 'react';
import { makeStyles, Box, Link } from '@material-ui/core';
import { isChrome, isSafari } from 'utils/browserDetection';
import { ReactComponent as AppStoreIcon } from '@lifesize/ux-assets/vectors/iosAppStore.svg';

const MobileDownloadLinks = () => {
  const classes = desktopStyles();

  return (
    <Box className={classes.container}>
      {isChrome() && (
        <Link href="https://play.google.com/store/apps/details?id=com.mirial.lifesizecloud" className={classes.link}>
          <img
            className={classes.badge}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </Link>
      )}
      {isSafari() && (
        <Link className={classes.link} href="https://apps.apple.com/us/app/lifesize-video-conferencing/id854663434">
          <AppStoreIcon className={classes.appStore} />
        </Link>
      )}
    </Box>
  );
};

const desktopStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    padding: 0,
    justifyContent: 'center'
  },
  link: {
    width: '40%'
  },
  appStore: {
    height: 'auto',
    width: '100%'
  },
  badge: {
    maxWidth: '100%'
  }
}));
export default MobileDownloadLinks;
