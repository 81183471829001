import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logger from 'js-logger';
import intl from 'react-intl-universal';
import { media, hooks } from '@lifesize/clients.sdk';
import { CancelButton, RetryButton } from '@lifesize/clients.mui-components';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { RootState } from 'redux/rootReducer';
import { setAttempted } from 'redux/callSlice';
import analytics, { GainsightEvents } from 'utils/analytics';
import { startCall } from 'utils/call';
import { getConstraints } from 'utils/devicesUtils';
import { signaling } from '@lifesize/clients.sdk';

export default function ButtonBarRetry(): React.ComponentElement<any, any> {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOnline } = hooks.useConnectivity();
  const extension = useSelector((state: RootState) => state.extension.value);
  const userInfo = useSelector((state: RootState) => state.userInformation);
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);

  async function onCallRetry() {
    // important: you must reacquire the the webcam on redial
    try {
      await media.acquireWebcam(getConstraints(mediaSettings));
    } catch (e) {
      Logger.error('Error occurred while acquiring webcam for re-dial', e);
    }
    try {
      if (mediaSettings.localAudioMuted) {
        await media.setAudioMute(true);
      }
      if (mediaSettings.localVideoMuted) {
        await media.setVideoMute(true);
      }
    } catch (e) {
      Logger.error('Error occurred while re-applying audio/video mute settings for re-dial', e);
    }
    try {
      await startCall(extension, { username: userInfo.name, email: userInfo.email, isOnline });
      analytics.track(GainsightEvents.Call, { extension });
      Logger.info('Call started');
    } catch (e) {
      Logger.error('Error occurred while re-dialing call', e);
    }
  }

  const onCallCancel = async () => {
    if (mediaSettings.localAudioMuted) {
      await media.setAudioMute(true);
    }
    if (mediaSettings.localVideoMuted) {
      await media.setVideoMute(true);
    }
    dispatch(setAttempted(false));
    await signaling.endCall();
  };

  return (
    <Box className={classes.buttonBar}>
      <Box className={classes.callControls}>
        <Tooltip title={intl.get('cancel')}>
          <CancelButton className={classes.videoButton} onClick={onCallCancel} data-testid={'call-cancel-button'} />
        </Tooltip>
        <Tooltip title={intl.get('callAgain')}>
          <RetryButton className={classes.videoButton} onClick={onCallRetry} data-testid={'call-retry-button'} />
        </Tooltip>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  buttonBar: {
    alignItems: 'center',
    animationDelay: '1s',
    animationDuration: '2s',
    animationFillMode: 'forwards',
    animationName: '$fadeIn',
    bottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    opacity: 0,
    position: 'absolute',
    right: 0,
    'z-index': 999
  },
  callControls: {
    display: 'flex',
    justifyContent: 'space-evenly',
    maxWidth: 475,
    minWidth: 280,
    width: '100%'
  },
  videoButton: {
    '& svg': {
      fontSize: '4rem'
    }
  }
}));
