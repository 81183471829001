import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  message: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    width: '100%',
    flexDirection: 'column'
  }
}));
