import React from 'react';
import intl from 'react-intl-universal';
import { makeStyles, Box, Button, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { desktopLauncherUrl } from 'constants/constants';

const DesktopDownloadLinks = () => {
  const classes = desktopStyles();
  const extensionState = useSelector((state: RootState) => state.extension);

  const url = extensionState.value
    ? `${desktopLauncherUrl}?ext=${extensionState.value}&forceDownloadOnFail=true`
    : `${desktopLauncherUrl}?forceDownloadOnFail=true`;

  return (
    <Box className={classes.container}>
      <Link href={url}>
        <Button color="primary" variant="contained">
          {intl.get('downloadApp')}
        </Button>
      </Link>
    </Box>
  );
};

const desktopStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    padding: 0,
    justifyContent: 'center'
  }
}));
export default DesktopDownloadLinks;
