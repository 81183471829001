import React, { useState, ReactElement, useEffect } from 'react';
import clsx from 'clsx';
import IdleTimer from 'react-idle-timer';
import { hooks, constants as c } from '@lifesize/clients.sdk';
import { Box, makeStyles } from '@material-ui/core';
import AudioButtonTooltip from 'components/CallControls/AudioButtonTooltip';
import CallDuration from 'components/CallControls/CallDuration';
import ChatDrawer from 'components/Chat/ChatDrawer';
import EndCall from 'components/CallControls/EndCall';
import SecondaryCallActions from 'components/CallControls/SecondaryCallActions';
import VideoButton from 'components/CallControls/VideoButton';
import LayoutSwitch from 'components/CallControls/LayoutSwitch';
import ParticipantsDrawer from 'components/ParticipantsDrawer/ParticipantsDrawer';
import Presentation from 'components/Presentation/Presentation';
import Pip from 'components/CallControls/Pip';
import RaiseHandButton from 'components/CallControls/RaiseHandButton';
import { featureFlagRaiseHand } from 'constants/constants';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useViewport from 'hooks/useViewport';
import { useSelector } from 'react-redux';
import { isMobile, isPresentationEnabled } from 'utils/browserDetection';
import { RootState } from '../../redux/rootReducer';

interface StyleProps {
  isFooterCompact: boolean;
}

export default function ButtonBar(): ReactElement<any, any> {
  const call = hooks.useCallState();
  const { layout, presentationLayout, remotePresentationStream } = hooks.useMedia();
  const callConnected = call.state === 'connected';
  const userInfo = hooks.useUserInfo();
  const hasChatEnabled = userInfo?.settings?.chatEnabled;
  const hasRaisedHandFeature = useFeatureFlag(featureFlagRaiseHand);
  const [isIdle, setIsIdle] = useState(false);
  const [forceVisible, setForceVisible] = useState(false);
  const unreadMessageCount = useSelector((state: RootState) => state.chat.unreadMessageCount);
  const { isFooterCompact, isFooterFull, isMobileView } = useViewport();
  const showMobileView = isMobileView || isMobile();
  const isSfuMultiStream = false; //getMaximumReceiveStreams() > 1 && !!sfuSettings?.sfuMultiStream;
  const isMultiStreamLayout = isSfuMultiStream && !isMobile();
  const compositeLayoutSwitchEnabled = true; // useFeatureFlag(FLAG_SFU_COMPOSITE_SWITCH);
  const presentationInLayout = !!remotePresentationStream && presentationLayout !== c.SFU_PRES_LAYOUT_POPPEDOUT;
  const showLayoutSwitch = !showMobileView && (isMultiStreamLayout || (compositeLayoutSwitchEnabled && !isMobile()));
  const isLayoutSwitchDisabled = presentationInLayout && isSfuMultiStream;
  const classes = useStyles({ isFooterCompact });

  const _onAction = () => {
    setIsIdle(false);
  };

  const _onActive = () => {
    setIsIdle(false);
  };

  const _onIdle = () => {
    setIsIdle(true);
  };

  useEffect(() => {
    unreadMessageCount ? _onActive() : _onIdle();
  }, [unreadMessageCount]);

  return (
    <Box
      className={clsx(classes.buttonBar, isIdle && !forceVisible ? classes.fadeOut : classes.fadeIn)}
      data-testid="buttonBar"
    >
      {callConnected && <CallDuration />}
      <Box className={classes.allControls}>
        {!isFooterCompact && (
          <Box className={classes.sidePanel}>
            <Pip height={60} />
          </Box>
        )}
        <Box className={classes.callControls}>
          <IdleTimer
            element={document}
            onActive={_onActive}
            onIdle={_onIdle}
            onAction={_onAction}
            debounce={250}
            timeout={3500}
          />
          {showLayoutSwitch && (
            <LayoutSwitch
              className="lsCallControl"
              disabled={isLayoutSwitchDisabled}
              enableLegacy={!isMultiStreamLayout}
              value={layout}
            />
          )}
          <AudioButtonTooltip isHidden={isIdle} disabled={!callConnected} onForceVisible={setForceVisible} />
          <VideoButton isHidden={isIdle} disabled={!callConnected} />
          <EndCall isHidden={isIdle} />
          <ParticipantsDrawer isHidden={isIdle} showButton={isFooterFull} />
          {hasChatEnabled && <ChatDrawer isHidden={isIdle} />}
          {isPresentationEnabled() && isFooterFull && <Presentation isHidden={isIdle} />}
          {hasRaisedHandFeature && isFooterFull && <RaiseHandButton isHidden={isIdle} />}
          <SecondaryCallActions isHidden={isIdle} isNarrowWidth={!isFooterFull} />
        </Box>
        {!isFooterCompact && <Box className={classes.sidePanel} />}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  '@keyframes fadeOut': {
    from: { opacity: 1 },
    to: { opacity: 0 }
  },
  fadeIn: {
    animationDuration: '.75s',
    animationFillMode: 'forwards',
    animationName: '$fadeIn'
  },
  fadeOut: {
    animationDuration: '1s',
    animationFillMode: 'backwards',
    animationName: '$fadeOut'
  },
  buttonBar: {
    alignItems: 'center',
    bottom: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    opacity: 0,
    position: 'absolute',
    right: 0,
    'z-index': 999
  },
  allControls: {
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 60,
    width: '100%'
  },
  sidePanel: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 0px',
    justifyContent: 'flex-start',
    position: 'relative'
  },
  callControls: {
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 auto',
    maxHeight: 60,
    '& .lsCallControl': {
      margin: (styleProps: StyleProps) => `0 ${styleProps.isFooterCompact ? 2 : 8}px`
    }
  }
}));
