import { useState, ChangeEvent } from 'react';
import Logger from 'js-logger';

export type EventType = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
type validatorFunction = (value: string) => string;

export default function useForm<T>(initialState: T) {
  const [state, setState] = useState(initialState);
  const [validationError, setValidationError] = useState(new Map<keyof T, string>());

  const updateValidationState = (k: keyof T, v: string) => {
    if (!!v) {
      setValidationError(validationError.set(k, v));
      return;
    }
    validationError.delete(k);
  };
  const [handlers] = useState(new Map<keyof T, (event: EventType) => void>());
  function handler(key: keyof T, validator: validatorFunction | undefined) {
    if (handlers.has(key)) return handlers.get(key);
    Logger.debug(`creating handler for ${key}`);
    const handlerFunction = (event: EventType) => {
      const { value } = event.target;
      updateValidationState(key, validator ? validator(value) : '');
      setState(prevState => ({ ...prevState, [key]: value }));
    };
    handlers.set(key, handlerFunction);
    return handlerFunction;
  }
  return { state, setState, handler, validationError };
}
