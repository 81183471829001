import React from 'react';
import intl from 'react-intl-universal';
import Linkify from 'react-linkify';
import { makeStyles, useTheme } from '@material-ui/core';
import { getCurrentLanguageId } from 'utils/localeUtil';
import clsx from 'clsx';

const shouldCombineChatMessages = (current: any, next: any) => {
  // combine if the consecutive messages are from the same person and sent within 30 seconds
  return (
    current.__typename !== 'ChatEvent' &&
    next?.__typename !== 'ChatEvent' &&
    current.member?.node?.uuid === next?.member?.node?.uuid &&
    Math.abs(current.createdAt - next?.createdAt) < 30000
  );
};

// const getActivity = (activityId: string) => {
//   switch (activityId.toLowerCase()) {
//     case 'joined':
//       return intl.get('chatJoinedTheCall');
//     default:
//       return activityId;
//   }
// };

const respectLineBreaks = (text: string) => {
  const regex = /(\n)/g;

  return text.split(regex).map((line, index) => {
    return !!line.match(regex) ? <br key={`break-${index}`} /> : <span key={`line-${index}`}>{line}</span>;
  });
};

const Message = (props: any) => {
  const shouldCombine = shouldCombineChatMessages(props.chat, props.nextChat);
  const { createdAt, member, message, uuid } = props.chat;
  const theme = useTheme();
  const classes = useStyles(theme);
  const date = typeof createdAt === 'number' ? createdAt : Date.parse(createdAt);
  const intlTime = new Intl.DateTimeFormat(getCurrentLanguageId(), { hour: 'numeric', minute: 'numeric' }).format(date);

  return (
    <div className={clsx(classes.container, shouldCombine && classes.combined)}>
      {!shouldCombine && (
        <div className={classes.name}>
          {member?.node?.displayName || intl.get('chatUnknownUser')}
          <span className={classes.time}>{intlTime}</span>
        </div>
      )}
      <p key={`message-uuid-${uuid}`} className={clsx(shouldCombine && classes.combined)}>
        {/* https://github.com/tasti/react-linkify/issues/96 */}
        <Linkify
          componentDecorator={(decoratedHref: any, decoratedText: any, key: any) => (
            <a target="_blank" href={decoratedHref} key={key} rel="noopener noreferrer">
              {decoratedText}
            </a>
          )}
        >
          {respectLineBreaks(message)}
        </Linkify>
      </p>
    </div>
  );
};

// NOTE: I'm keeping this here just in case there are future events we want to share
// const Event = (props: any) => {
//   const theme = useTheme();
//   const classes = useStyles(theme);
//   const { createdAt, member, activityType } = props.chat;
//   const intlTime = new Intl.DateTimeFormat(getCurrentLanguageId(), { hour: 'numeric', minute: 'numeric' }).format(
//     createdAt
//   );

//   return (
//     <div className={clsx(classes.container, classes.isEvent)}>
//       <span className={classes.event}>
//         <span className={classes.name}>{member?.node?.displayName || 'unknown'}</span>
//         &nbsp;{getActivity(activityType)}
//       </span>
//       <span className={classes.time}>{intlTime}</span>
//     </div>
//   );
// };

const ChatItem = (props: any) => {
  if (props.chat.__typename === 'ChatEvent') return null;

  return <Message {...props} />;
};

const useStyles = makeStyles<any, any>((theme) => ({
  container: {
    color: 'black',
    fontSize: '12px',
    marginTop: '1em',
    maxWidth: '300px',
    textAlign: 'left'
  },
  name: {
    fontWeight: 500
  },
  time: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    marginLeft: '1em'
  },
  event: {
    color: theme.palette.primary.main,
    fontStyle: 'italic'
  },
  combined: {
    marginTop: 0
  },
  isEvent: {
    marginBottom: '1em'
  }
}));

export default ChatItem;
