import React, { FunctionComponent } from 'react';
import Footer from 'components/PreJoin/Footer';
import Header from 'components/PreJoin/Header';
import { Container, makeStyles, Theme, Box } from '@material-ui/core';

const ErrorPage: FunctionComponent = props => {
  const classes = messageStyles();

  return (
    <Container className={classes.container}>
      <Header />
      <Box className={classes.mainWrapper}>{props.children}</Box>
      <Footer />
    </Container>
  );
};

export const messageStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    maxWidth: '100%',
    height: 'calc(var(--vh, 1vh) * 100)',
    overflow: 'hidden',
    padding: 0,
    zIndex: 99
  },
  mainWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%'
  }
}));

export default ErrorPage;
