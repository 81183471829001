import React from 'react';
import { useSelector } from 'react-redux';
import { hooks } from '@lifesize/clients.sdk';
import PreJoin from 'layouts/PreJoinLayout';
import { RootState } from 'redux/rootReducer';
import InCall from 'layouts/InCallLayout';
import { callReasons } from 'constants/constants';
import Unsupported from './Unsupported';

const CallLayout = () => {
  const call = hooks.useCallState();
  const callStatus = useSelector((state: RootState) => state.call);

  if (callReasons.notSupported.includes(call.reason)) {
    return <Unsupported />;
  } else {
    return callStatus.attempted ? <InCall /> : <PreJoin />;
  }
};
export default CallLayout;
