import React from 'react';
import { VideoStream } from '@lifesize/clients.mui-components';
import clsx from 'clsx';
import { hooks } from '@lifesize/clients.sdk';
import { ReactComponent as PersonIcon } from '@lifesize/ux-assets/vectors/svgIcons/person.svg';
import { makeStyles } from '@material-ui/core';
import { videoElementIds } from 'constants/constants';
import { theme } from 'theme';

export default function LocalVideoComponent() {
  const classes = useStyles(theme);
  const { localPrimaryStream, localPresentationStream } = hooks.useMedia();
  const mediaStream = localPresentationStream ? localPresentationStream : localPrimaryStream;
  const { localVideoMuted } = hooks.useMedia();

  return (
    <div className={classes.fullScreenContainer}>
      {!mediaStream || localVideoMuted ? (
        <div className={classes.frankContainer}>
          <PersonIcon className={classes.frank} />
        </div>
      ) : null}
      <VideoStream
        className={clsx(localVideoMuted && classes.hideVideo, !localPresentationStream && classes.mirrorVideo)}
        dataTestId="localVideo"
        id={videoElementIds.local}
        mediaStream={mediaStream}
        muted={true}
      />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  mirrorVideo: {
    transform: 'rotateY(180deg)'
  },
  fullScreenContainer: {
    height: '100%',
    position: 'absolute',
    right: '0%',
    top: '0%',
    width: '100%'
  },
  hideVideo: {
    display: 'none'
  },
  videoMuted: {
    background: '#666'
  },
  frankContainer: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%'
  },
  frank: {
    color: '#999',
    maxHeight: '100%',
    width: '40%'
  }
}));
