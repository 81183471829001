import { createSlice } from '@reduxjs/toolkit';

interface ChatState {
  unreadMessageCount: number;
}

const initialState: ChatState = {
  unreadMessageCount: 0
};

const chatDrawerSlice = createSlice({
  name: 'chatDrawer',
  initialState,
  reducers: {
    showButtonBar(state, action) {
      state.unreadMessageCount = action.payload;
    },
    resetChatMessageCount() {
      return initialState;
    }
  }
});

export const { showButtonBar, resetChatMessageCount } = chatDrawerSlice.actions;
export default chatDrawerSlice.reducer;
