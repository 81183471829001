import { colors } from '@lifesize/clients.mui-components';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  alert: {
    top: '16px',
    left: '16px',
    position: 'absolute',
    boxShadow: 'none',
    width: '36px',
    color: colors.neutralGray
  },
  textContent: {
    fontSize: '1.5rem',
    maxWidth: '520px',
    textAlign: 'center'
  }
}));

export default useStyles;
