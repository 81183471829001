import React from 'react';
import { LanguageSwitcher } from '@lifesize/clients.mui-components';
import { ReactComponent as LsHorizontalR } from '@lifesize/ux-assets/vectors/svgLogos/lsHorizontalR.svg';
import { Box, makeStyles, Theme, Typography, Link } from '@material-ui/core';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { getJoinUrl } from 'utils/urlUtils';
import { changeLanguage, getCurrentLanguageId, getLanguageOptions } from 'utils/localeUtil';

interface Props {
  hideAccountLink?: boolean;
}

const Header = ({ hideAccountLink }: Props) => {
  const classes = useHeaderStyles();
  const extension = useSelector((state: RootState) => state.extension.value);
  const joinUrl = getJoinUrl(extension);

  return (
    <Box className={classes.container}>
      <Box className={classes.logo} data-testid="lifesizeLogo">
        <LsHorizontalR />
      </Box>
      <Box className={classes.right}>
        {!hideAccountLink && (
          <Link data-testid="login" href={joinUrl}>
            <Typography>{intl.get('haveAnAccount')}</Typography>
          </Link>
        )}
        <LanguageSwitcher
          languageId={getCurrentLanguageId()}
          languageOptions={getLanguageOptions()}
          handleSelect={changeLanguage}
          data-testid="languageButton"
        />
      </Box>
    </Box>
  );
};

const useHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: `${theme.spacing(2)}px`
  },
  logo: {
    color: theme.palette.primary.main,
    display: 'flex',
    flex: '0 0 auto',
    '& svg': {
      height: 25
    }
  },
  right: {
    color: theme.palette.primary.main,
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    marginLeft: 16,
    textAlign: 'right',
    '& a': {
      paddingRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

export default Header;
