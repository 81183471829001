import { createSlice } from '@reduxjs/toolkit';

interface FeatureFlagState {
  featureFlagObject: any;
}

const initialState: FeatureFlagState = {
  featureFlagObject: []
};

const featureFlagSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setFeatureFlagObject(state, action) {
      state.featureFlagObject = action.payload;
    }
  }
});

export const { setFeatureFlagObject } = featureFlagSlice.actions;
export default featureFlagSlice.reducer;
