import React, { useState, useEffect, useRef, useCallback } from 'react';
import intl from 'react-intl-universal';
import { Badge, ChatButton, SwipeableDrawer } from '@lifesize/clients.mui-components';
import { Box, makeStyles, Tooltip, useTheme } from '@material-ui/core';
import usePrevious from 'hooks/usePrevious';
import { useDispatch } from 'react-redux';
import { isIosMobile } from 'utils/browserDetection';
import ChatWindow from './ChatWindow';
import { resetChatMessageCount, showButtonBar } from 'redux/chatDrawerSlice';
import useChat from 'hooks/useChat';

interface Props {
  isHidden?: boolean;
}

const ChatDrawer = (props: Props) => {
  const [open, setOpen] = useState(false);
  const previouslyOpen = usePrevious(open);
  const [autoScrollIsPaused, setAutoScrollIsPaused] = useState(false);
  const [bookmark, setBookmark] = useState(0);
  const [lastReadMessage, setLastReadMessage] = useState(0);
  const [chatBtnTooltip, setChatBtnTooltip] = useState('chat'); // the string key
  const dispatch = useDispatch();
  const timeOutIdRef = useRef({
    id: 0
  });
  const theme = useTheme();
  const classes = useStyles(theme);
  const { chats, chatRoomUUID, chatError } = useChat();

  useEffect(() => {
    if (chatError) {
      setChatBtnTooltip('unexpectedError');
      return;
    }
    setChatBtnTooltip('chat');
  }, [chatError]);

  const unreadMsgCount = useCallback(() => {
    return autoScrollIsPaused || !open ? chats.filter((chat: any) => chat.createdAt > lastReadMessage).length : 0;
  }, [chats, autoScrollIsPaused, open, lastReadMessage])();

  useEffect(() => {
    if (timeOutIdRef.current.id) clearTimeout(timeOutIdRef.current.id);
    if (unreadMsgCount) {
      dispatch(showButtonBar(unreadMsgCount));
      // hide buttonbar if user don't interact with it after 5 secs.
      timeOutIdRef.current.id = window.setTimeout(() => {
        dispatch(resetChatMessageCount());
      }, 5000);
    }
  }, [unreadMsgCount, dispatch]);

  const tooltipPopperProps = props.isHidden
    ? { open: false } // forces the tooltip closed when the ButtonBar is idle (hidden)
    : {}; // behave normally

  return (
    <React.Fragment>
      <Tooltip title={intl.get(chatBtnTooltip)} PopperProps={tooltipPopperProps}>
        <Box display="flex" className={`${classes.btnWrapper} lsCallControl`}>
          <ChatButton data-testid="showChat" disabled={!chatRoomUUID} onClick={() => setOpen(!open)} selected={false} />
          {!!unreadMsgCount && <Badge classes={classes.badge} label={unreadMsgCount} />}
        </Box>
      </Tooltip>
      <SwipeableDrawer
        anchor="right"
        closeButtonProps={{
          dataTestId: 'closeChat',
          label: intl.get('close'),
          closeDrawer: () => setOpen(false)
        }}
        isIos={isIosMobile()}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <ChatWindow
          autoScrollIsPaused={autoScrollIsPaused}
          bookmark={bookmark}
          chatRoomUUID={chatRoomUUID}
          chats={chats}
          open={open}
          previouslyOpen={!!previouslyOpen}
          setAutoScrollIsPaused={setAutoScrollIsPaused}
          setBookmark={setBookmark}
          setLastReadMessage={setLastReadMessage}
          unreadMsgCount={unreadMsgCount}
        />
      </SwipeableDrawer>
    </React.Fragment>
  );
};

const useStyles = makeStyles<any, any>((theme) => ({
  btnWrapper: {
    position: 'relative'
  },
  badge: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

export default ChatDrawer;
