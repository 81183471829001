import { SnackbarProps } from '@material-ui/core';

export const defaultProps: Partial<SnackbarProps> = {
  ContentProps: {
    'aria-describedby': 'message-id',
    role: 'alertdialog'
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  autoHideDuration: 5000,
  style: { bottom: 140 }
};
