import { lsGet, LsKey, lsSet } from 'utils/localStorageUtil';

export function readFromLocalStorage() {
  const name = lsGet(LsKey.Name);
  const email = lsGet(LsKey.Email);
  return {
    name: name || '',
    email: email || ''
  };
}

export function writeToLocalStorage(name: string, email: string) {
  lsSet(LsKey.Name, name);
  lsSet(LsKey.Email, email);
}
