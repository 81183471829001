import React, { useEffect, useCallback, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import CallLayout from './CallLayout';
import { setConnectionLostSnackbarOpen, setConnectionRestoredSnackbarOpen } from 'redux/snackbarsSlice';
import LoadingAnimation from 'components/Loading';
import ValidatingExtension from 'components/Snackbars/ValidatingExtension';
import CookieBanner from 'components/Snackbars/CookieBanner';
import NewVersionDialog from 'components/NewVersionDialog';

const ConnectionLostSnackbar = React.lazy(() => import('components/Snackbars/ConnectionLost'));
const ConnectionRestoredSnackbar = React.lazy(() => import('components/Snackbars/ConnectionRestored'));
const Ringer = React.lazy(() => import('components/Ringer/Ringer'));

const AppLayout = () => {
  const dispatch = useDispatch();

  const handleOnline = useCallback(() => {
    dispatch(setConnectionRestoredSnackbarOpen({ open: true }));
    dispatch(setConnectionLostSnackbarOpen({ open: false }));
  }, [dispatch]);

  const handleOffline = useCallback(() => {
    dispatch(setConnectionLostSnackbarOpen({ open: true }));
    dispatch(setConnectionRestoredSnackbarOpen({ open: false }));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [handleOffline, handleOnline]);

  return (
    <>
      <CallLayout />
      <CookieBanner />
      <Suspense fallback={<LoadingAnimation show={true} />}>
        <ConnectionLostSnackbar />
        <ConnectionRestoredSnackbar />
        <ValidatingExtension />
        <Ringer />
        <NewVersionDialog />
      </Suspense>
    </>
  );
};

export default AppLayout;
