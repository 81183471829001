import React, { useEffect } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Header from 'components/PreJoin/Header';
import JoinForm from 'components/PreJoin/JoinForm';
import MicAndVideoPreview from 'components/PreJoin/MicAndVideoPreview';
import LocalVideo from 'components/VideoStreams/LocalVideo';
import { getConstraints } from 'utils/devicesUtils';
import { media } from '@lifesize/clients.sdk';
import { RootState } from 'redux/rootReducer';
import Logger from 'js-logger';

const PreJoin = () => {
  const classes = desktopStyles();
  const mediaSettings = useSelector((state: RootState) => state.mediaSettings);

  useEffect(() => {
    const acquire = async () => {
      try {
        await media.acquireWebcam(constraints);
      } catch (ex) {
        Logger.warn('Could not capture webcam', ex);
      }
    };
    const constraints = getConstraints(mediaSettings);
    Logger.info('attempting to capture stream', constraints);
    acquire();
  }, [mediaSettings]);

  return (
    <Box className={classes.container}>
      <Header />
      <Box className={classes.content}>
        <Box className={classes.detailWrapper}>
          <JoinForm />
        </Box>
        <Box className={classes.settingsPreview} data-testid="previewDivision">
          <LocalVideo />
          <MicAndVideoPreview />
        </Box>
      </Box>
    </Box>
  );
};

const desktopStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxWidth: '100%',
    overflow: 'hidden',
    padding: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '@media (orientation: portrait)': {
      flexDirection: 'column-reverse'
    }
  },
  detailWrapper: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    overflow: 'auto',
    justifyContent: 'center',
    '@media (orientation: landscape)': {
      maxWidth: '50%'
    },
    '@media (orientation: portrait)': {
      width: '100%'
    }
  },
  settingsPreview: {
    backgroundColor: '#000000',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    height: 'calc(100vh - 51px)', // subtract the height of the header from the vh
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    '@media (orientation: portrait)': {
      maxWidth: '100%'
    }
  }
}));
export default PreJoin;
