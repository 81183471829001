import { useMediaQuery } from '@material-ui/core';

/* Footer Breakpoints
 * compact: (320px -> 680px) left: none; center: audio, video, end, chat, more
 * dense: (680px -> 920px) left: 1 pip; center: layout, audio, video, end, chat, more
 * full: (920px -> 1130px) left: 1 pip; center: layout, audio, video, end, participants, chat, present, hand, more
 * max: (1130px +) left: 1 or 2 pips; center: layout, audio, video, end, participants, chat, present, hand, more
 */

export default function useViewport() {
  const isFooterCompact = useMediaQuery(`(max-width:${680}px)`);
  const isFooterDense = useMediaQuery(`(min-width:${680}px)`);
  const isFooterFull = useMediaQuery(`(min-width:${920}px)`);
  const isFooterMax = useMediaQuery(`(min-width:${1130}px)`);
  const isPortrait = useMediaQuery(`(orientation: portrait)`);
  const isMobileHeight = useMediaQuery('(max-height:600px)');
  const isMobileView = isFooterCompact || isMobileHeight; // no layout switch && mobile layout from sdk

  return {
    isFooterCompact,
    isFooterDense,
    isFooterFull,
    isFooterMax,
    isMobileView,
    isPortrait
  };
}
